import React, { useState } from 'react';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';
import { TestCycle } from 'API';
import { Loader, TextField } from '@aws-amplify/ui-react';
import LexicalEditor from 'components/LexicalEditor';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateTestCycle } from 'services/graphql/TestCycle';
import { useRevalidator } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './SettingsModal.module.css';
import {
  cycleSettingsSchema,
  TCycleSettingsSchema,
} from './SettingsModal.schema';

interface ISettingsModal {
  cycle?: TestCycle;
  open: boolean;
  onClose: () => void;
}

const SettingsModal = ({ cycle, open, onClose }: ISettingsModal) => {
  const [updating, setUpdating] = useState(false);

  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<TCycleSettingsSchema>({
    resolver: zodResolver(cycleSettingsSchema),
    shouldUnregister: true,
  });

  const { revalidate } = useRevalidator();

  const handleOnValid = async (data: TCycleSettingsSchema) => {
    try {
      setUpdating(true);
      if (cycle) {
        await updateTestCycle({
          id: cycle.id,
          name: data.name,
          closedCycleMessage: data.closedCycleText,
        });

        onClose();

        revalidate();
      }
    } catch (error) {
      console.error('Error while updating cycle settings.');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal
      title={t(
        'pages.habitat.affiliate.cycles.cycle.components.settingsModal.title',
        {
          cycleName: cycle?.name,
        }
      )}
      open={open}
      onClickClose={onClose}
      width="768px"
    >
      <form className={`${styles.form}`} onSubmit={handleSubmit(handleOnValid)}>
        <TextField
          label={t(
            'pages.habitat.affiliate.cycles.cycle.components.settingsModal.name'
          )}
          {...register('name')}
          hasError={Boolean(errors.name)}
          errorMessage={errors.name?.message}
          defaultValue={cycle?.name || ''}
        />
        <div>
          <span className={`${styles.label}`}>
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.settingsModal.closedCycle'
            )}
          </span>
          <Controller
            control={control}
            name="closedCycleText"
            defaultValue={cycle?.closedCycleMessage}
            render={({ field: { onChange } }) => (
              <LexicalEditor
                onChangeHtml={(htmlString) => {
                  onChange(htmlString);
                }}
                htmlString={cycle?.closedCycleMessage}
                editable
                disableFiles
                disableImages
              />
            )}
          />
        </div>
        <div className={`${styles.buttons}`}>
          <CustomButton
            type="submit"
            icon={updating && <Loader />}
            disabled={updating}
          >
            {updating
              ? t(
                  'pages.habitat.affiliate.cycles.cycle.components.settingsModal.saving'
                )
              : t(
                  'pages.habitat.affiliate.cycles.cycle.components.settingsModal.save'
                )}
          </CustomButton>
          <CustomButton
            variation="secondary"
            disabled={updating}
            onClick={onClose}
          >
            {t(
              'pages.habitat.affiliate.cycles.cycle.components.settingsModal.cancel'
            )}
          </CustomButton>
        </div>
      </form>
    </Modal>
  );
};

export default SettingsModal;
