import { defer, LoaderFunction } from 'react-router-dom';
import { queryChat } from 'services/graphql/Chat';
import { queryAllUsers } from 'services/graphql/User';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getChatData = async (chatId: string) => {
  await untilAmplifyFinishConfiguration();

  const chat = await queryChat(chatId);

  if (!chat) {
    throw new Response('Chat not Found', { status: 404 });
  }

  const ownerUser = (
    await queryAllUsers({ filter: { owner: { eq: chat.owner } } })
  )[0];

  if (!ownerUser) {
    throw new Response('User not Found', { status: 404 });
  }

  return {
    chat,
    ownerUser,
  };
};

export type TChatData = Awaited<Promise<ReturnType<typeof getChatData>>>;

const chatLoader: LoaderFunction = async ({ params }) => {
  const { chatId } = params;

  if (!chatId) {
    throw new Response('Chat id is required.', { status: 400 });
  }

  return defer({ chatData: getChatData(chatId) });
};

export default chatLoader;
