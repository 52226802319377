import React, { useEffect, useState } from 'react';
import {
  Alert,
  PhoneNumberField,
  TextField,
  useAuthenticator,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { User } from 'API';
import { queryAllUsers, updateUser } from 'services/graphql/User';
import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
  updateUserAttribute,
} from 'aws-amplify/auth';
import libphonenumbers from 'google-libphonenumber';
import Skeleton from 'components/Skeleton';
import { useTranslation } from 'react-i18next';
import styles from './Profile.module.css';
import { profileSchema, TProfileSchema } from './Profile.schema';

const Profile = () => {
  const [loading, setLoading] = useState(0);

  const [success, setSuccess] = useState(false);

  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const [userData, setUserData] = useState<User>();

  const [userAttributes, setUserAttributes] =
    useState<FetchUserAttributesOutput>();

  const { t } = useTranslation();

  const {
    user: { username },
  } = useAuthenticator((context) => [context.user]);

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TProfileSchema>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: {
        countryCode: '',
        nationalNumber: '',
      },
    },
  });

  const handleOnValid = async (data: TProfileSchema) => {
    try {
      if (userData && userAttributes) {
        await updateUser({
          id: userData?.id,
          firstName: data.firstName,
          lastName: data.lastName,
        });

        await updateUserAttribute({
          userAttribute: {
            attributeKey: 'phone_number',
            value: `${data.phoneNumber.countryCode}${data.phoneNumber.nationalNumber}`,
          },
        });

        setTriggerUpdate((prevTriggerUpdate) => prevTriggerUpdate + 1);

        setSuccess(true);
      }
    } catch (error) {
      console.error('Error while updating user profile.');
    }
  };

  useEffect(() => {
    if (username) {
      const getUserData = async () => {
        try {
          setLoading((prevLoading) => prevLoading + 1);
          const attributes = await fetchUserAttributes();

          setUserAttributes(attributes);

          const users = await queryAllUsers({
            filter: {
              owner: { eq: username },
            },
          });

          if (users.length === 0) {
            throw new Error('User not found.');
          }

          setUserData(users[0]);
        } catch (error) {
          console.error('Error while fetching user data.');
        } finally {
          setLoading((prevLoading) => prevLoading - 1);
        }
      };

      getUserData();
    }
  }, [username, triggerUpdate]);

  useEffect(() => {
    if (userAttributes && userData) {
      const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();

      const tel = phoneUtil.parse(userAttributes.phone_number);

      reset({
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: {
          countryCode: `+${tel.getCountryCode()?.toString()}`,
          nationalNumber: tel.getNationalNumber()?.toString(),
        },
      });
    }
  }, [reset, userAttributes, userData]);

  return (
    <div className={`${styles.page}`}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        {t('components.profile.title')}
      </h3>
      {loading === 0 ? (
        <form
          onSubmit={handleSubmit(handleOnValid)}
          className={`${styles.form}`}
        >
          {success && (
            <Alert
              variation="success"
              isDismissible
              onDismiss={() => setSuccess(false)}
            >
              {t('components.profile.success')}
            </Alert>
          )}
          <TextField
            label={t('components.profile.firstName')}
            {...register('firstName')}
            hasError={Boolean(errors.firstName)}
            errorMessage={errors.firstName?.message?.toString() || ''}
          />
          <TextField
            label={t('components.profile.lastName')}
            {...register('lastName')}
            hasError={Boolean(errors.lastName)}
            errorMessage={errors.lastName?.message?.toString() || ''}
          />
          <PhoneNumberField
            label={t('components.profile.phoneNumber')}
            {...register('phoneNumber.nationalNumber')}
            hasError={Boolean(errors.phoneNumber)}
            errorMessage={errors.phoneNumber?.root?.message?.toString() || ''}
            dialCodeName={register('phoneNumber.countryCode').name}
            dialCodeRef={register('phoneNumber.countryCode').ref}
            onDialCodeChange={register('phoneNumber.countryCode').onChange}
          />
          <CustomButton className={`${styles.submitButton}`} type="submit">
            {t('components.profile.save')}
          </CustomButton>
        </form>
      ) : (
        <div className={`${styles.form}`}>
          <Skeleton className={`${styles.inputSkeleton}`} />
          <Skeleton className={`${styles.inputSkeleton}`} />
          <Skeleton className={`${styles.inputSkeleton}`} />
        </div>
      )}
    </div>
  );
};

export default Profile;
