import React from 'react';
import { TextField } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton';
import IconButton from 'components/IconButton';
import { MdOutlineAdd, MdOutlineDelete } from 'react-icons/md';
import { v4 } from 'uuid';
import FileInput from 'components/FileInput';
import { getS3ObjectNameFromURL } from 'utils/s3';
import { useTranslation } from 'react-i18next';
import styles from './GalleryField.module.css';
import { TGalleryItemWithFiles } from '../../AffiliateSettingsPage';

interface IGalleryField {
  gallery: TGalleryItemWithFiles[];
  onChange: (newGallery: TGalleryItemWithFiles[]) => void;
}

const GalleryField = ({ gallery, onChange }: IGalleryField) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container}`}>
      {(gallery || []).map((slide, index) => (
        <div className={`${styles.slide}`} key={slide.id}>
          <div className={`${styles.fields}`}>
            <div>
              <p>
                {t(
                  'pages.habitat.affiliate.settings.components.GalleryField.slide',
                  { number: index + 1 }
                )}
              </p>
              <TextField
                label={t(
                  'pages.habitat.affiliate.settings.components.GalleryField.title'
                )}
                value={slide.title || ''}
                onChange={(event) => {
                  const newGallery = [...(gallery || [])];

                  newGallery[index] = {
                    ...newGallery[index],
                    title: event.currentTarget.value,
                  };

                  onChange(newGallery);
                }}
              />
              <TextField
                label={t(
                  'pages.habitat.affiliate.settings.components.GalleryField.message'
                )}
                value={slide.message || ''}
                onChange={(event) => {
                  const newGallery = [...(gallery || [])];

                  newGallery[index] = {
                    ...newGallery[index],
                    message: event.currentTarget.value,
                  };

                  onChange(newGallery);
                }}
              />
              <br />
              <FileInput
                label={t(
                  'pages.habitat.affiliate.settings.components.GalleryField.image'
                )}
                files={
                  slide.image
                    ? [
                        slide.image && typeof slide.image !== 'string'
                          ? slide.image
                          : new File(
                              [],
                              getS3ObjectNameFromURL(slide.image || 'unknown')
                            ),
                      ]
                    : []
                }
                onChange={(newFiles) => {
                  const newGallery = [...(gallery || [])];

                  newGallery[index] = {
                    ...newGallery[index],
                    image: newFiles[0],
                  };

                  onChange(newGallery);
                }}
              />
            </div>
            <div>
              {(gallery || []).length > 1 && (
                <CustomButton
                  className={`${styles.delete}`}
                  icon={<MdOutlineDelete />}
                  onClick={() => {
                    const newGallery = (gallery || []).toSpliced(index, 1);

                    onChange(newGallery);
                  }}
                >
                  {t(
                    'pages.habitat.affiliate.settings.components.GalleryField.remove'
                  )}
                </CustomButton>
              )}
            </div>
          </div>
          <div className={`${styles.image}`}>
            <img
              src={
                slide.image instanceof File
                  ? URL.createObjectURL(slide.image)
                  : slide.image || ''
              }
              alt=""
            />
          </div>
        </div>
      ))}
      {(gallery || []).length < 3 && (
        <IconButton
          onClick={() =>
            onChange([
              ...(gallery || []),
              {
                __typename: 'GalleryItem',
                id: v4(),
                title: '',
                message: '',
                image: '',
              },
            ])
          }
        >
          <MdOutlineAdd />
        </IconButton>
      )}
    </div>
  );
};

export default GalleryField;
