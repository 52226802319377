import { useTranslation } from 'react-i18next';
import { Text } from '@aws-amplify/ui-react';
import DecisionCard from 'components/DecisionCard';
import useHabitat from 'hooks/utils/useHabitat';
import Skeleton from 'components/Skeleton';
import { Decision, ReviewStatus } from 'API';
import style from './DecisionsTab.module.css';

interface IProperties {
  decisions: Decision[];
  skeleton?: boolean;
}

const DecisionsTab = ({ decisions, skeleton }: IProperties) => {
  const { t } = useTranslation();
  const { habitat } = useHabitat();

  if (skeleton)
    return (
      <div className={style.container}>
        {[1, 2, 3, 4, 5].map((number) => (
          <Skeleton key={number} className={style.skeletonCard} />
        ))}
      </div>
    );

  return (
    <div className={style.container}>
      {decisions.length > 0 ? (
        decisions.map((decision) => (
          <DecisionCard
            key={decision.id}
            date={decision.createdAt || ''}
            habitat={habitat?.longName || ''}
            status={decision.status as ReviewStatus}
            editorState={decision.serializedEditorState}
            shouldRenderStatusChip
          />
        ))
      ) : (
        <Text textAlign="center" fontWeight="bold">
          {t('components.decisionsTab.noDecisions')}
        </Text>
      )}
    </div>
  );
};

export default DecisionsTab;
