import * as React from 'react';
import PropTypes from 'prop-types';
import './ImageNode.style.css';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { useRef, useCallback, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from 'lexical';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { $isImageNode } from './ImageNode';

const ImageComponent = ({ src, nodeKey, altText, s3key }) => {
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();

  const { t } = useTranslation();

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const imageRef = useRef(null);

  const onDelete = useCallback(
    (payload) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isImageNode(node)) {
          node.remove();
          return true;
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  const onClick = useCallback(
    (event) => {
      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }

      return false;
    },
    [isSelected, setSelected, clearSelection]
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      unregister();
    };
  }, [
    clearSelection,
    editor,
    isSelected,
    nodeKey,
    onClick,
    onDelete,
    setSelected,
  ]);

  if (s3key) {
    return (
      <div onClick={onClick} aria-hidden="true">
        {authStatus === 'authenticated' ? (
          <StorageImage
            className={`image-node ${editor.isEditable() ? 'editable' : ''} ${
              isSelected ? 'selected' : ''
            }`}
            path={s3key.startsWith('public/') ? s3key : `public/${s3key}`}
            alt={altText}
            itemRef={imageRef}
          />
        ) : (
          <div className="image-node-unauthenticated" ref={imageRef}>
            <span>{`${t(
              'components.lexicalEditor.nodes.ImageComponent.image'
            )} ${s3key.split('/').at(-1)}`}</span>
            {altText && (
              <>
                <br />
                <span>{`${t(
                  'components.lexicalEditor.nodes.ImageComponent.alt'
                )} ${altText}`}</span>
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div onClick={onClick} aria-hidden="true">
      <img
        className={`image-node ${editor.isEditable() ? 'editable' : ''} ${
          isSelected ? 'selected' : ''
        }`}
        src={src}
        alt={altText}
        ref={imageRef}
      />
    </div>
  );
};

ImageComponent.propTypes = {
  src: PropTypes.string,
  altText: PropTypes.string,
  nodeKey: PropTypes.string,
  s3key: PropTypes.string,
};

export default ImageComponent;
