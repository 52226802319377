import { Form, Wizard } from '@formio/react';
import { Options } from '@formio/react/lib/components/Form';
import { Decision, Habitat, Note, TestApplication } from 'API';
import LoadingOverlay from 'components/LoadingOverlay';
import { Suspense, useEffect, useState } from 'react';
import { Await, useLoaderData, useSearchParams } from 'react-router-dom';
import { TPrintData } from 'router/loaders/print';
import { convertTranlationsToObject } from 'utils/translations';
import { DEFAULT_REVIEW_STATUS } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { I18n } from 'aws-amplify/utils';
import GeneralSection from './components/GeneralSection';
import styles from './page.module.css';
import NotesSection from './components/NotesSection';
import DecisionSection from './components/DecisionsSection';

declare global {
  interface Window {
    hfhSetSubmission: (submission: unknown) => void;
    hfhIsSubmissionSet: () => boolean;
    hfhSetData: (data: IData) => void;
  }
}

type TSections = 'general' | 'submission' | 'notes' | 'decisions';

interface IData {
  applicantName: string;
  email: string;
  phone: string;
  application: TestApplication;
  decisions: Decision[];
  notes: Note[];
  habitat: Habitat;
}

const Print = () => {
  const { printData } = useLoaderData() as { printData: TPrintData };

  const [searchParams] = useSearchParams();

  const [data, setData] = useState<IData>();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    window.hfhSetData = setData;
  }, []);

  const language = searchParams.get('language') || 'en';

  const section =
    (searchParams.get('section') as TSections | undefined) || 'submission';

  useEffect(() => {
    i18n.changeLanguage(language);
    I18n.setLanguage(language);
    localStorage.setItem('lng', language);
  }, [i18n, language]);

  if (!data) return <LoadingOverlay />;

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Await resolve={printData}>
        {(printData: TPrintData) => {
          if (!printData) {
            return null;
          }

          const { formSchema, translations } = printData;

          return (
            <div>
              {section === 'general' && (
                <div className={styles.section}>
                  <h2 className={`theme-headline-medium ${styles.title}`}>
                    {t('pages.print.general')}
                  </h2>
                  <GeneralSection
                    applicantName={data.applicantName}
                    email={data.email}
                    phone={data.phone}
                    dateSubmitted={data.application.submittedDate}
                    reviewStatus={data.application.reviewStatus}
                    customStatus={
                      data.application.customStatus || DEFAULT_REVIEW_STATUS
                    }
                  />
                </div>
              )}
              {section === 'submission' && (
                <div className={styles.section}>
                  <h2 className={`theme-headline-medium ${styles.title}`}>
                    {t('pages.print.submission')}
                  </h2>
                  <Form
                    form={formSchema}
                    options={
                      {
                        readOnly: true,
                        renderMode: 'flat',
                        language: translations.length === 0 ? 'en' : language,
                        i18n: convertTranlationsToObject(translations),
                      } as Options
                    }
                    formReady={(form: typeof Wizard) => {
                      window.hfhSetSubmission = (submission: unknown) => {
                        form.submission = submission;
                      };
                      window.hfhIsSubmissionSet = () => form.submissionSet;
                    }}
                  />
                </div>
              )}
              {section === 'notes' && (
                <div className={styles.section}>
                  <h2 className={`theme-headline-medium ${styles.title}`}>
                    {t('pages.print.notes')}
                  </h2>
                  {data.notes.length === 0 ? (
                    <h3 className="theme-subtitle-s1">
                      {t('pages.print.noNotes')}
                    </h3>
                  ) : (
                    <NotesSection notes={data.notes} />
                  )}
                </div>
              )}
              {section === 'decisions' && (
                <div className={styles.section}>
                  <h2 className={`theme-headline-medium ${styles.title}`}>
                    {t('pages.print.decisions')}
                  </h2>
                  {data.decisions.length === 0 ? (
                    <h3 className="theme-subtitle-s1">
                      {t('pages.print.noDecisions')}
                    </h3>
                  ) : (
                    <DecisionSection
                      decisions={data.decisions}
                      habitat={data.habitat}
                    />
                  )}
                </div>
              )}
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Print;
