import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { Button, Flex, Loader, Text } from '@aws-amplify/ui-react';
import LexicalEditor from 'components/LexicalEditor';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import ExpandableCard from 'components/ExpandableCard';
import { queryAllUsers } from 'services/graphql/User';
import { User } from 'API';
import Skeleton from 'components/Skeleton';

interface INotePreviewProps {
  ownerID: string;
  createdAt: string;
  serializedEditorState: string;
  onDelete?: () => void;
  deleting?: boolean;
  alwaysExpanded?: boolean;
  disableShadow?: boolean;
}

const NotePreview = ({
  ownerID,
  createdAt,
  serializedEditorState,
  onDelete,
  deleting,
  alwaysExpanded,
  disableShadow,
}: INotePreviewProps) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<User>();

  const [confirmModal, setConfirmModal] = useState(false);

  const handleOpenCloseConfirmModal = () => {
    if (!deleting) {
      setConfirmModal((prevConfirmModal) => !prevConfirmModal);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const fetchedUser = await queryAllUsers({
          filter: {
            owner: { eq: ownerID },
          },
        });
        setUser(fetchedUser[0]);
      } catch (error) {
        console.log('Error fetching user data.');
      }
    };
    getUser();
  }, [ownerID]);

  return (
    <ExpandableCard
      alwaysExpanded={alwaysExpanded}
      disableShadow={disableShadow}
    >
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <Text>
            <b>
              {t(
                'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.by'
              )}
            </b>{' '}
            {user ? (
              `${user.firstName} ${user.lastName}`
            ) : (
              <Skeleton variation="text" numOfCharacters={10} />
            )}
          </Text>
          <Flex alignItems="center">
            <Text>{dayjs(createdAt || undefined).format('YYYY-MM-DD')}</Text>
            {onDelete && (
              <>
                <Modal
                  title={t(
                    'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.delete.title'
                  )}
                  onClickClose={handleOpenCloseConfirmModal}
                  width="25rem"
                  open={confirmModal}
                >
                  <Flex direction="column">
                    <Text>
                      {t(
                        'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.delete.warning'
                      )}
                    </Text>
                    <Flex justifyContent="end">
                      <Button
                        variation="primary"
                        onClick={onDelete}
                        isDisabled={deleting}
                      >
                        {deleting ? (
                          <Flex alignItems="center">
                            <Loader />
                            {t(
                              'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.delete.deleting'
                            )}
                          </Flex>
                        ) : (
                          t(
                            'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.delete.accept'
                          )
                        )}
                      </Button>

                      <Button
                        variation="warning"
                        onClick={handleOpenCloseConfirmModal}
                        isDisabled={deleting}
                      >
                        {t(
                          'pages.habitat.affiliate.cycles.cycle.application.components.notesTab.components.notePreview.delete.cancel'
                        )}
                      </Button>
                    </Flex>
                  </Flex>
                </Modal>
                <Button
                  variation="destructive"
                  padding="0.5rem"
                  onClick={handleOpenCloseConfirmModal}
                >
                  <MdDelete />
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <LexicalEditor serializedEditorState={serializedEditorState} />
      </>
    </ExpandableCard>
  );
};

export default NotePreview;
