import { View, Flex, Text } from '@aws-amplify/ui-react';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  LegacyRef,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton';
import { MdAttachFile, MdOutlineClose } from 'react-icons/md';
import styles from './FileInput.module.css';

interface IFileInputProps {
  maxFileCount?: number;
  label: string;
  onChange: (newFiles: File[]) => void;
  files: File[];
  multiple?: boolean;
  isRequired?: boolean;
  accept?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onInvalid?: FormEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const FileInput = ({
  maxFileCount = 1,
  label,
  onChange,
  files = [],
  multiple,
  isRequired,
  accept,
  isDisabled,
  hasError,
  errorMessage,
  onInvalid,
  onBlur,
}: IFileInputProps) => {
  const { t } = useTranslation();
  const filesInputRef = useRef<HTMLInputElement>();

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newFiles = [...(event.currentTarget.files || [])];
    const filteredFiles = newFiles.filter(
      (newFile) =>
        files.find((file) => file.name === newFile.name) === undefined
    );
    const mergedFiles = [...files, ...filteredFiles];
    onChange(
      maxFileCount !== undefined && mergedFiles.length > maxFileCount
        ? mergedFiles.slice(0, maxFileCount)
        : mergedFiles
    );
  };

  const handleOnRemove = (fileName: string) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    onChange(filteredFiles);
  };

  useEffect(() => {
    if (filesInputRef.current) {
      if (files) {
        const dataTransfer = new DataTransfer();
        files.forEach((file) => dataTransfer.items.add(file));
        filesInputRef.current.files = dataTransfer.files;
      }
    }
  }, [files]);

  const disableInputAndButton =
    isDisabled ||
    onChange === undefined ||
    (maxFileCount !== undefined && files && files.length >= maxFileCount);

  return (
    <View
      width="100%"
      border="thin"
      borderColor="lightgray"
      borderStyle="solid"
      padding="1rem"
      borderRadius="medium"
    >
      <Flex alignItems="center">
        <View flex={1}>
          <Text>{label}</Text>
          <View className={`theme-body-small ${styles.descriptiveText}`}>
            <Text variation="secondary">{`${t(
              'components.fileInput.addFiles'
            )}${!multiple ? ` ${t('components.fileInput.oneByOne')}` : ''} ${
              maxFileCount !== undefined
                ? `(${maxFileCount} ${t('components.fileInput.filesMax')}.)`
                : ''
            }`}</Text>
          </View>

          {hasError && (
            <View className="theme-body-small">
              <Text variation="error">{errorMessage}</Text>
            </View>
          )}
        </View>
        <input
          type="file"
          accept={accept}
          onChange={handleOnChange}
          ref={filesInputRef as LegacyRef<HTMLInputElement>}
          required={isRequired}
          multiple={multiple}
          disabled={disableInputAndButton}
          onInvalid={onInvalid}
          onBlur={onBlur}
          className={styles.fileInput}
        />
        <CustomButton
          onClick={() => filesInputRef.current && filesInputRef.current.click()}
          title={t('components.fileInput.openFileBrowser')}
          disabled={disableInputAndButton}
        >
          <MdAttachFile size={24} />
        </CustomButton>
      </Flex>

      {files && files.length > 0 && (
        <>
          <Text
            fontSize="0.875rem"
            fontStyle="italic"
            marginTop="0.5rem"
            marginBottom="8px"
            variation="secondary"
          >
            {t('components.fileInput.selectedFiles')}
          </Text>

          <Flex
            direction="column"
            gap="0.5rem"
            maxHeight="12.5rem"
            minHeight={0}
            overflow="auto"
          >
            {Array.from(files).map((file) => (
              <Flex key={file.name} className={styles.fileRow}>
                <Text grow={1} lineHeight={1.25}>
                  {file.name}
                </Text>
                <CustomButton
                  title="Remove file"
                  onClick={() => handleOnRemove(file.name)}
                  disabled={isDisabled}
                  className={styles.iconButton}
                >
                  <MdOutlineClose />
                </CustomButton>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </View>
  );
};

export default FileInput;
