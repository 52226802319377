import React, { useState } from 'react';
import { Loader, TextField } from '@aws-amplify/ui-react';
import { RootForm } from 'API';
import CustomButton from 'components/CustomButton';
import LexicalEditor from 'components/LexicalEditor';
import Modal from 'components/Modal';
import { MdOutlineSettings } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRevalidator } from 'react-router-dom';
import useHabitat from 'hooks/utils/useHabitat';
import { updateRootForm } from 'services/graphql/RootForm';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DECISION_EMAIL } from 'utils/constants';
import styles from './SettingsButton.module.css';
import {
  rootFormSettingsSchema,
  TRootFormSettingsSchema,
} from './SettingsButton.schema';

interface ISettingsButton {
  rootForm: RootForm;
}

const SettingsButton = ({ rootForm }: ISettingsButton) => {
  const { habitat } = useHabitat();

  const [updating, setUpdating] = useState(false);

  const [show, setShow] = useState(false);

  const { revalidate } = useRevalidator();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TRootFormSettingsSchema>({
    resolver: zodResolver(rootFormSettingsSchema),
    shouldUnregister: true,
    defaultValues: {
      name: rootForm.name || '',
      confirmSubmissionEmail:
        rootForm.confirmSubmissionText ||
        `<div><span>Hello,</span><br/><p>Your submission for your Habitat for Humanity application was uploaded succesfully.</p><span>Thanks,</span><br/><span>${habitat?.longName}</span></div>`,
    },
  });

  const handleClickSettings = () => setShow(true);

  const handleCloseSettings = () => setShow(false);

  const handleOnValid = async (data: TRootFormSettingsSchema) => {
    try {
      setUpdating(true);
      await updateRootForm({
        id: rootForm.id,
        name: data.name,
        confirmSubmissionText: data.confirmSubmissionEmail,
        decisionText: data.decisionText,
      });
      revalidate();
    } catch (error) {
      console.error('Error while updating RootForm.');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      <Modal
        title={t(
          'pages.habitat.affiliate.cycles.components.settingsButton.title',
          {
            formName: rootForm.name,
          }
        )}
        width="768px"
        open={show}
        onClickClose={handleCloseSettings}
      >
        <form
          className={`${styles.form}`}
          onSubmit={handleSubmit(handleOnValid)}
        >
          <TextField
            label={t(
              'pages.habitat.affiliate.cycles.components.settingsButton.name'
            )}
            {...register('name')}
            hasError={Boolean(errors.name)}
            errorMessage={errors.name?.message}
          />
          <div>
            <span className={`${styles.label}`}>
              {t(
                'pages.habitat.affiliate.cycles.components.settingsButton.confirmSubmissionEmail'
              )}
            </span>
            <Controller
              control={control}
              name="confirmSubmissionEmail"
              render={({ field: { onChange } }) => (
                <LexicalEditor
                  onChangeHtml={(htmlString) => onChange(htmlString)}
                  htmlString={rootForm.confirmSubmissionText}
                  editable
                  disableFiles
                  disableImages
                />
              )}
            />
          </div>
          <div>
            <span className={`${styles.label}`}>
              {t(
                'pages.habitat.affiliate.cycles.components.settingsButton.decisionText'
              )}
            </span>
            <span className={`theme-body-small ${styles.label} ${styles.note}`}>
              {t(
                'pages.habitat.affiliate.cycles.components.settingsButton.decisionTextNote'
              )}
            </span>
            <Controller
              control={control}
              name="decisionText"
              render={({ field: { onChange } }) => (
                <LexicalEditor
                  onChangeHtml={(htmlString) => onChange(htmlString)}
                  htmlString={rootForm.decisionText || DEFAULT_DECISION_EMAIL}
                  editable
                  disableFiles
                  disableImages
                />
              )}
            />
          </div>
          <div className={`${styles.buttons}`}>
            <CustomButton
              type="submit"
              disabled={updating}
              icon={updating && <Loader />}
            >
              {updating
                ? t(
                    'pages.habitat.affiliate.cycles.components.settingsButton.saving'
                  )
                : t(
                    'pages.habitat.affiliate.cycles.components.settingsButton.save'
                  )}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={handleCloseSettings}
              disabled={updating}
            >
              {t(
                'pages.habitat.affiliate.cycles.components.settingsButton.cancel'
              )}
            </CustomButton>
          </div>
        </form>
      </Modal>
      <CustomButton icon={<MdOutlineSettings />} onClick={handleClickSettings}>
        {t('pages.habitat.affiliate.cycles.components.settingsButton.settings')}
      </CustomButton>
    </>
  );
};

export default SettingsButton;
