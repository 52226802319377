import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { EditorState } from 'lexical';

interface IIsEmptyPlugin {
  onEmptyChange: (isEmpty: boolean) => void;
}

const IsEmptyPlugin = ({ onEmptyChange }: IIsEmptyPlugin) => {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const [editorState, setEditorState] = useState<EditorState>();

  useEffect(() => {
    if (isEmpty && editorState) {
      const currentSerializedEditorState = JSON.stringify(editorState.toJSON());

      onEmptyChange(
        isEmpty &&
          !currentSerializedEditorState.includes(`"type":"file"`) &&
          !currentSerializedEditorState.includes(`"type":"image"`)
      );
    } else {
      onEmptyChange(isEmpty);
    }
  }, [editor, editorState, isEmpty, onEmptyChange]);

  return <OnChangePlugin onChange={setEditorState} ignoreSelectionChange />;
};

export default IsEmptyPlugin;
