/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEditCommentInput = {
  id?: string | null,
  rootformID: string,
  content: string,
  screenshots: Array< string >,
  status: EditCommentStatus,
};

export enum EditCommentStatus {
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
}


export type ModelEditCommentConditionInput = {
  rootformID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  screenshots?: ModelStringInput | null,
  status?: ModelEditCommentStatusInput | null,
  and?: Array< ModelEditCommentConditionInput | null > | null,
  or?: Array< ModelEditCommentConditionInput | null > | null,
  not?: ModelEditCommentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelEditCommentStatusInput = {
  eq?: EditCommentStatus | null,
  ne?: EditCommentStatus | null,
};

export type EditComment = {
  __typename: "EditComment",
  id: string,
  rootformID: string,
  content: string,
  screenshots: Array< string >,
  status: EditCommentStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEditCommentInput = {
  id: string,
  rootformID?: string | null,
  content?: string | null,
  screenshots?: Array< string > | null,
  status?: EditCommentStatus | null,
};

export type DeleteEditCommentInput = {
  id: string,
};

export type CreateLastReadInput = {
  id?: string | null,
  chatID: string,
  lastMessage: string,
  owner: string,
};

export type ModelLastReadConditionInput = {
  chatID?: ModelIDInput | null,
  lastMessage?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelLastReadConditionInput | null > | null,
  or?: Array< ModelLastReadConditionInput | null > | null,
  not?: ModelLastReadConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type LastRead = {
  __typename: "LastRead",
  id: string,
  chatID: string,
  lastMessage: string,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLastReadInput = {
  id: string,
  chatID?: string | null,
  lastMessage?: string | null,
  owner?: string | null,
};

export type DeleteLastReadInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  chatID: string,
  content: string,
  owner: string,
};

export type ModelMessageConditionInput = {
  chatID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  chatID: string,
  content: string,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMessageInput = {
  id: string,
  chatID?: string | null,
  content?: string | null,
  owner?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateChatInput = {
  id?: string | null,
  habitatID: string,
  owner: string,
  lastMessage?: string | null,
};

export type ModelChatConditionInput = {
  habitatID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  lastMessage?: ModelIDInput | null,
  and?: Array< ModelChatConditionInput | null > | null,
  or?: Array< ModelChatConditionInput | null > | null,
  not?: ModelChatConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Chat = {
  __typename: "Chat",
  id: string,
  Messages?: ModelMessageConnection | null,
  habitatID: string,
  owner: string,
  lastMessage?: string | null,
  LastReads?: ModelLastReadConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelLastReadConnection = {
  __typename: "ModelLastReadConnection",
  items:  Array<LastRead | null >,
  nextToken?: string | null,
};

export type UpdateChatInput = {
  id: string,
  habitatID?: string | null,
  owner?: string | null,
  lastMessage?: string | null,
};

export type DeleteChatInput = {
  id: string,
};

export type CreateFormTranslationInput = {
  id?: string | null,
  autoTexts: string,
  manualTexts: string,
  formschemaID: string,
  lang: Language,
};

export enum Language {
  EN = "EN",
  ES = "ES",
}


export type ModelFormTranslationConditionInput = {
  autoTexts?: ModelStringInput | null,
  manualTexts?: ModelStringInput | null,
  formschemaID?: ModelIDInput | null,
  lang?: ModelLanguageInput | null,
  and?: Array< ModelFormTranslationConditionInput | null > | null,
  or?: Array< ModelFormTranslationConditionInput | null > | null,
  not?: ModelFormTranslationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelLanguageInput = {
  eq?: Language | null,
  ne?: Language | null,
};

export type FormTranslation = {
  __typename: "FormTranslation",
  id: string,
  autoTexts: string,
  manualTexts: string,
  formschemaID: string,
  lang: Language,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFormTranslationInput = {
  id: string,
  autoTexts?: string | null,
  manualTexts?: string | null,
  formschemaID?: string | null,
  lang?: Language | null,
};

export type DeleteFormTranslationInput = {
  id: string,
};

export type CreateComponentSchemaInput = {
  id?: string | null,
  schema: string,
  formschemaID: string,
  index: number,
};

export type ModelComponentSchemaConditionInput = {
  schema?: ModelStringInput | null,
  formschemaID?: ModelIDInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelComponentSchemaConditionInput | null > | null,
  or?: Array< ModelComponentSchemaConditionInput | null > | null,
  not?: ModelComponentSchemaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ComponentSchema = {
  __typename: "ComponentSchema",
  id: string,
  schema: string,
  formschemaID: string,
  index: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateComponentSchemaInput = {
  id: string,
  schema?: string | null,
  formschemaID?: string | null,
  index?: number | null,
};

export type DeleteComponentSchemaInput = {
  id: string,
};

export type CreateFormSchemaInput = {
  id?: string | null,
  title: string,
  name: string,
  display: string,
  path: string,
};

export type ModelFormSchemaConditionInput = {
  title?: ModelStringInput | null,
  name?: ModelStringInput | null,
  display?: ModelStringInput | null,
  path?: ModelStringInput | null,
  and?: Array< ModelFormSchemaConditionInput | null > | null,
  or?: Array< ModelFormSchemaConditionInput | null > | null,
  not?: ModelFormSchemaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type FormSchema = {
  __typename: "FormSchema",
  id: string,
  title: string,
  name: string,
  display: string,
  path: string,
  ComponentsSchemas?: ModelComponentSchemaConnection | null,
  FormTranslations?: ModelFormTranslationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelComponentSchemaConnection = {
  __typename: "ModelComponentSchemaConnection",
  items:  Array<ComponentSchema | null >,
  nextToken?: string | null,
};

export type ModelFormTranslationConnection = {
  __typename: "ModelFormTranslationConnection",
  items:  Array<FormTranslation | null >,
  nextToken?: string | null,
};

export type UpdateFormSchemaInput = {
  id: string,
  title?: string | null,
  name?: string | null,
  display?: string | null,
  path?: string | null,
};

export type DeleteFormSchemaInput = {
  id: string,
};

export type CreateReportInput = {
  id?: string | null,
  content: string,
  testCycleId: string,
  title: string,
  status: ReportStatus,
  habitatID: string,
};

export enum ReportStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}


export type ModelReportConditionInput = {
  content?: ModelStringInput | null,
  testCycleId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelReportStatusInput | null,
  habitatID?: ModelIDInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelReportStatusInput = {
  eq?: ReportStatus | null,
  ne?: ReportStatus | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  content: string,
  testCycleId: string,
  title: string,
  status: ReportStatus,
  habitatID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReportInput = {
  id: string,
  content?: string | null,
  testCycleId?: string | null,
  title?: string | null,
  status?: ReportStatus | null,
  habitatID?: string | null,
};

export type DeleteReportInput = {
  id: string,
};

export type CreateMaintenanceInput = {
  id?: string | null,
  maintenance?: boolean | null,
};

export type ModelMaintenanceConditionInput = {
  maintenance?: ModelBooleanInput | null,
  and?: Array< ModelMaintenanceConditionInput | null > | null,
  or?: Array< ModelMaintenanceConditionInput | null > | null,
  not?: ModelMaintenanceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Maintenance = {
  __typename: "Maintenance",
  id: string,
  maintenance?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMaintenanceInput = {
  id: string,
  maintenance?: boolean | null,
};

export type DeleteMaintenanceInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  sex: Sexs,
  phoneNumber: string,
  affiliateProps?: AffiliatePropsInput | null,
  applicantProps?: ApplicantPropsInput | null,
  type: UserTypes,
  owner: string,
  verified?: boolean | null,
};

export enum Sexs {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}


export type AffiliatePropsInput = {
  titleAtHabitat: string,
  roleDescription: string,
  joinDate?: string | null,
  joinMonth?: string | null,
  joinYear?: string | null,
};

export type ApplicantPropsInput = {
  state: string,
  city: string,
  street: string,
  householdMembersNumber: number,
  householdAnnualIncome: number,
  currentlyUnemployed: string,
  currentWorkTitle?: string | null,
  nameOfEmployer?: string | null,
  howDidYouHearAbout: string,
  firstTimeApplying: string,
  whatAreYouInterestedIn: string,
};

export enum UserTypes {
  AFFILIATE = "AFFILIATE",
  APPLICANT = "APPLICANT",
  ADMIN = "ADMIN",
}


export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  sex?: ModelSexsInput | null,
  phoneNumber?: ModelStringInput | null,
  type?: ModelUserTypesInput | null,
  owner?: ModelIDInput | null,
  verified?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSexsInput = {
  eq?: Sexs | null,
  ne?: Sexs | null,
};

export type ModelUserTypesInput = {
  eq?: UserTypes | null,
  ne?: UserTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  sex: Sexs,
  phoneNumber: string,
  affiliateProps?: AffiliateProps | null,
  applicantProps?: ApplicantProps | null,
  type: UserTypes,
  owner: string,
  verified?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type AffiliateProps = {
  __typename: "AffiliateProps",
  titleAtHabitat: string,
  roleDescription: string,
  joinDate?: string | null,
  joinMonth?: string | null,
  joinYear?: string | null,
};

export type ApplicantProps = {
  __typename: "ApplicantProps",
  state: string,
  city: string,
  street: string,
  householdMembersNumber: number,
  householdAnnualIncome: number,
  currentlyUnemployed: string,
  currentWorkTitle?: string | null,
  nameOfEmployer?: string | null,
  howDidYouHearAbout: string,
  firstTimeApplying: string,
  whatAreYouInterestedIn: string,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  dateOfBirth?: string | null,
  sex?: Sexs | null,
  phoneNumber?: string | null,
  affiliateProps?: AffiliatePropsInput | null,
  applicantProps?: ApplicantPropsInput | null,
  type?: UserTypes | null,
  owner?: string | null,
  verified?: boolean | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateRootFormInput = {
  id?: string | null,
  name?: string | null,
  status?: RootFormStatusTypes | null,
  description?: string | null,
  files?: Array< string | null > | null,
  habitatID: string,
  formUrls: Array< string >,
  supportQuestion?: MultiLangTextInput | null,
  confirmSubmissionText?: string | null,
  decisionText?: string | null,
};

export enum RootFormStatusTypes {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
}


export type MultiLangTextInput = {
  en: string,
  es?: string | null,
};

export type ModelRootFormConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelRootFormStatusTypesInput | null,
  description?: ModelStringInput | null,
  files?: ModelStringInput | null,
  habitatID?: ModelIDInput | null,
  formUrls?: ModelStringInput | null,
  confirmSubmissionText?: ModelStringInput | null,
  decisionText?: ModelStringInput | null,
  and?: Array< ModelRootFormConditionInput | null > | null,
  or?: Array< ModelRootFormConditionInput | null > | null,
  not?: ModelRootFormConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelRootFormStatusTypesInput = {
  eq?: RootFormStatusTypes | null,
  ne?: RootFormStatusTypes | null,
};

export type RootForm = {
  __typename: "RootForm",
  id: string,
  name?: string | null,
  status?: RootFormStatusTypes | null,
  description?: string | null,
  files?: Array< string | null > | null,
  Cycles?: ModelTestCycleConnection | null,
  habitatID: string,
  formUrls: Array< string >,
  supportQuestion?: MultiLangText | null,
  confirmSubmissionText?: string | null,
  decisionText?: string | null,
  EditComments?: ModelEditCommentConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTestCycleConnection = {
  __typename: "ModelTestCycleConnection",
  items:  Array<TestCycle | null >,
  nextToken?: string | null,
};

export type TestCycle = {
  __typename: "TestCycle",
  id: string,
  startDate: string,
  endDate?: string | null,
  isOpen: boolean,
  props?: string | null,
  TestApplications?: ModelTestApplicationConnection | null,
  rootformID: string,
  name?: string | null,
  closedCycleMessage: string,
  formUrl: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelTestApplicationConnection = {
  __typename: "ModelTestApplicationConnection",
  items:  Array<TestApplication | null >,
  nextToken?: string | null,
};

export type TestApplication = {
  __typename: "TestApplication",
  id: string,
  ownerID?: string | null,
  lastSection?: string | null,
  submittedDate: string,
  reviewStatus: ReviewStatus,
  submissionStatus: SubmissionStatus,
  props?: string | null,
  type: ApplicationTypes,
  testcycleID: string,
  Notes?: ModelNoteConnection | null,
  FormAnswers?: ModelFormAnswerConnection | null,
  Decisions?: ModelDecisionConnection | null,
  customStatus?: string | null,
  lastPage?: number | null,
  hasRevision?: boolean | null,
  filtered?: boolean | null,
  version?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum ReviewStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  DENIED = "DENIED",
  RETURNED = "RETURNED",
}


export enum SubmissionStatus {
  INCOMPLETE = "INCOMPLETE",
  COMPLETED = "COMPLETED",
}


export enum ApplicationTypes {
  ONLINE = "ONLINE",
  PAPER = "PAPER",
}


export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
};

export type Note = {
  __typename: "Note",
  id: string,
  serializedEditorState: string,
  ownerID: string,
  testapplicationID: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelFormAnswerConnection = {
  __typename: "ModelFormAnswerConnection",
  items:  Array<FormAnswer | null >,
  nextToken?: string | null,
};

export type FormAnswer = {
  __typename: "FormAnswer",
  id: string,
  values?: string | null,
  page?: string | null,
  section?: string | null,
  testapplicationID: string,
  isCopy?: boolean | null,
  version?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDecisionConnection = {
  __typename: "ModelDecisionConnection",
  items:  Array<Decision | null >,
  nextToken?: string | null,
};

export type Decision = {
  __typename: "Decision",
  id: string,
  status: ReviewStatus,
  serializedEditorState: string,
  testapplicationID: string,
  createdAt: string,
  updatedAt: string,
};

export type MultiLangText = {
  __typename: "MultiLangText",
  en: string,
  es?: string | null,
};

export type ModelEditCommentConnection = {
  __typename: "ModelEditCommentConnection",
  items:  Array<EditComment | null >,
  nextToken?: string | null,
};

export type UpdateRootFormInput = {
  id: string,
  name?: string | null,
  status?: RootFormStatusTypes | null,
  description?: string | null,
  files?: Array< string | null > | null,
  habitatID?: string | null,
  formUrls?: Array< string > | null,
  supportQuestion?: MultiLangTextInput | null,
  confirmSubmissionText?: string | null,
  decisionText?: string | null,
};

export type DeleteRootFormInput = {
  id: string,
};

export type CreateDecisionInput = {
  id?: string | null,
  status: ReviewStatus,
  serializedEditorState: string,
  testapplicationID: string,
};

export type ModelDecisionConditionInput = {
  status?: ModelReviewStatusInput | null,
  serializedEditorState?: ModelStringInput | null,
  testapplicationID?: ModelIDInput | null,
  and?: Array< ModelDecisionConditionInput | null > | null,
  or?: Array< ModelDecisionConditionInput | null > | null,
  not?: ModelDecisionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelReviewStatusInput = {
  eq?: ReviewStatus | null,
  ne?: ReviewStatus | null,
};

export type UpdateDecisionInput = {
  id: string,
  status?: ReviewStatus | null,
  serializedEditorState?: string | null,
  testapplicationID?: string | null,
};

export type DeleteDecisionInput = {
  id: string,
};

export type CreateFormAnswerInput = {
  id?: string | null,
  values?: string | null,
  page?: string | null,
  section?: string | null,
  testapplicationID: string,
  isCopy?: boolean | null,
  version?: number | null,
};

export type ModelFormAnswerConditionInput = {
  values?: ModelStringInput | null,
  page?: ModelStringInput | null,
  section?: ModelStringInput | null,
  testapplicationID?: ModelIDInput | null,
  isCopy?: ModelBooleanInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelFormAnswerConditionInput | null > | null,
  or?: Array< ModelFormAnswerConditionInput | null > | null,
  not?: ModelFormAnswerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateFormAnswerInput = {
  id: string,
  values?: string | null,
  page?: string | null,
  section?: string | null,
  testapplicationID?: string | null,
  isCopy?: boolean | null,
  version?: number | null,
};

export type DeleteFormAnswerInput = {
  id: string,
};

export type CreateNoteInput = {
  id?: string | null,
  serializedEditorState: string,
  ownerID: string,
  testapplicationID: string,
};

export type ModelNoteConditionInput = {
  serializedEditorState?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  testapplicationID?: ModelIDInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateNoteInput = {
  id: string,
  serializedEditorState?: string | null,
  ownerID?: string | null,
  testapplicationID?: string | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreateTestCycleInput = {
  id?: string | null,
  startDate: string,
  endDate?: string | null,
  isOpen: boolean,
  props?: string | null,
  rootformID: string,
  name?: string | null,
  closedCycleMessage: string,
  formUrl: string,
};

export type ModelTestCycleConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isOpen?: ModelBooleanInput | null,
  props?: ModelStringInput | null,
  rootformID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  closedCycleMessage?: ModelStringInput | null,
  formUrl?: ModelStringInput | null,
  and?: Array< ModelTestCycleConditionInput | null > | null,
  or?: Array< ModelTestCycleConditionInput | null > | null,
  not?: ModelTestCycleConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTestCycleInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  isOpen?: boolean | null,
  props?: string | null,
  rootformID?: string | null,
  name?: string | null,
  closedCycleMessage?: string | null,
  formUrl?: string | null,
};

export type DeleteTestCycleInput = {
  id: string,
};

export type CreateTestApplicationInput = {
  id?: string | null,
  ownerID?: string | null,
  lastSection?: string | null,
  submittedDate: string,
  reviewStatus: ReviewStatus,
  submissionStatus: SubmissionStatus,
  props?: string | null,
  type: ApplicationTypes,
  testcycleID: string,
  customStatus?: string | null,
  lastPage?: number | null,
  hasRevision?: boolean | null,
  filtered?: boolean | null,
  version?: number | null,
};

export type ModelTestApplicationConditionInput = {
  ownerID?: ModelIDInput | null,
  lastSection?: ModelStringInput | null,
  submittedDate?: ModelStringInput | null,
  reviewStatus?: ModelReviewStatusInput | null,
  submissionStatus?: ModelSubmissionStatusInput | null,
  props?: ModelStringInput | null,
  type?: ModelApplicationTypesInput | null,
  testcycleID?: ModelIDInput | null,
  customStatus?: ModelStringInput | null,
  lastPage?: ModelIntInput | null,
  hasRevision?: ModelBooleanInput | null,
  filtered?: ModelBooleanInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelTestApplicationConditionInput | null > | null,
  or?: Array< ModelTestApplicationConditionInput | null > | null,
  not?: ModelTestApplicationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSubmissionStatusInput = {
  eq?: SubmissionStatus | null,
  ne?: SubmissionStatus | null,
};

export type ModelApplicationTypesInput = {
  eq?: ApplicationTypes | null,
  ne?: ApplicationTypes | null,
};

export type UpdateTestApplicationInput = {
  id: string,
  ownerID?: string | null,
  lastSection?: string | null,
  submittedDate?: string | null,
  reviewStatus?: ReviewStatus | null,
  submissionStatus?: SubmissionStatus | null,
  props?: string | null,
  type?: ApplicationTypes | null,
  testcycleID?: string | null,
  customStatus?: string | null,
  lastPage?: number | null,
  hasRevision?: boolean | null,
  filtered?: boolean | null,
  version?: number | null,
};

export type DeleteTestApplicationInput = {
  id: string,
};

export type CreateHabitatInput = {
  id?: string | null,
  name?: string | null,
  longName?: string | null,
  state?: string | null,
  city?: string | null,
  props: HabitatPropsInput,
  users?: Array< string | null > | null,
  authenticationHeader?: string | null,
  urlName?: string | null,
};

export type HabitatPropsInput = {
  customStatus?: Array< string > | null,
  gallery?: Array< GalleryItemInput > | null,
  sidebarName?: SidebarNameInput | null,
  closedCycleMessages: Array< string >,
  notifySubmissionsEmails?: Array< string > | null,
};

export type GalleryItemInput = {
  id?: string | null,
  image?: string | null,
  title?: string | null,
  message?: string | null,
};

export type SidebarNameInput = {
  name?: string | null,
  fontSize?: string | null,
};

export type ModelHabitatConditionInput = {
  name?: ModelStringInput | null,
  longName?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  users?: ModelIDInput | null,
  authenticationHeader?: ModelStringInput | null,
  urlName?: ModelStringInput | null,
  and?: Array< ModelHabitatConditionInput | null > | null,
  or?: Array< ModelHabitatConditionInput | null > | null,
  not?: ModelHabitatConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Habitat = {
  __typename: "Habitat",
  id: string,
  name?: string | null,
  longName?: string | null,
  state?: string | null,
  city?: string | null,
  props: HabitatProps,
  users?: Array< string | null > | null,
  authenticationHeader?: string | null,
  RootForms?: ModelRootFormConnection | null,
  urlName?: string | null,
  Reports?: ModelReportConnection | null,
  Chats?: ModelChatConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type HabitatProps = {
  __typename: "HabitatProps",
  customStatus?: Array< string > | null,
  gallery?:  Array<GalleryItem > | null,
  sidebarName?: SidebarName | null,
  closedCycleMessages: Array< string >,
  notifySubmissionsEmails?: Array< string > | null,
};

export type GalleryItem = {
  __typename: "GalleryItem",
  id?: string | null,
  image?: string | null,
  title?: string | null,
  message?: string | null,
};

export type SidebarName = {
  __typename: "SidebarName",
  name?: string | null,
  fontSize?: string | null,
};

export type ModelRootFormConnection = {
  __typename: "ModelRootFormConnection",
  items:  Array<RootForm | null >,
  nextToken?: string | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type ModelChatConnection = {
  __typename: "ModelChatConnection",
  items:  Array<Chat | null >,
  nextToken?: string | null,
};

export type UpdateHabitatInput = {
  id: string,
  name?: string | null,
  longName?: string | null,
  state?: string | null,
  city?: string | null,
  props?: HabitatPropsInput | null,
  users?: Array< string | null > | null,
  authenticationHeader?: string | null,
  urlName?: string | null,
};

export type DeleteHabitatInput = {
  id: string,
};

export type ModelEditCommentFilterInput = {
  id?: ModelIDInput | null,
  rootformID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  screenshots?: ModelStringInput | null,
  status?: ModelEditCommentStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEditCommentFilterInput | null > | null,
  or?: Array< ModelEditCommentFilterInput | null > | null,
  not?: ModelEditCommentFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelLastReadFilterInput = {
  id?: ModelIDInput | null,
  chatID?: ModelIDInput | null,
  lastMessage?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLastReadFilterInput | null > | null,
  or?: Array< ModelLastReadFilterInput | null > | null,
  not?: ModelLastReadFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  chatID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelChatFilterInput = {
  id?: ModelIDInput | null,
  habitatID?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  lastMessage?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatFilterInput | null > | null,
  or?: Array< ModelChatFilterInput | null > | null,
  not?: ModelChatFilterInput | null,
};

export type ModelFormTranslationFilterInput = {
  id?: ModelIDInput | null,
  autoTexts?: ModelStringInput | null,
  manualTexts?: ModelStringInput | null,
  formschemaID?: ModelIDInput | null,
  lang?: ModelLanguageInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormTranslationFilterInput | null > | null,
  or?: Array< ModelFormTranslationFilterInput | null > | null,
  not?: ModelFormTranslationFilterInput | null,
};

export type ModelComponentSchemaFilterInput = {
  id?: ModelIDInput | null,
  schema?: ModelStringInput | null,
  formschemaID?: ModelIDInput | null,
  index?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelComponentSchemaFilterInput | null > | null,
  or?: Array< ModelComponentSchemaFilterInput | null > | null,
  not?: ModelComponentSchemaFilterInput | null,
};

export type ModelFormSchemaFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  name?: ModelStringInput | null,
  display?: ModelStringInput | null,
  path?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormSchemaFilterInput | null > | null,
  or?: Array< ModelFormSchemaFilterInput | null > | null,
  not?: ModelFormSchemaFilterInput | null,
};

export type ModelFormSchemaConnection = {
  __typename: "ModelFormSchemaConnection",
  items:  Array<FormSchema | null >,
  nextToken?: string | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  testCycleId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelReportStatusInput | null,
  habitatID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelMaintenanceFilterInput = {
  id?: ModelIDInput | null,
  maintenance?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMaintenanceFilterInput | null > | null,
  or?: Array< ModelMaintenanceFilterInput | null > | null,
  not?: ModelMaintenanceFilterInput | null,
};

export type ModelMaintenanceConnection = {
  __typename: "ModelMaintenanceConnection",
  items:  Array<Maintenance | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  sex?: ModelSexsInput | null,
  phoneNumber?: ModelStringInput | null,
  type?: ModelUserTypesInput | null,
  owner?: ModelIDInput | null,
  verified?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelRootFormFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelRootFormStatusTypesInput | null,
  description?: ModelStringInput | null,
  files?: ModelStringInput | null,
  habitatID?: ModelIDInput | null,
  formUrls?: ModelStringInput | null,
  confirmSubmissionText?: ModelStringInput | null,
  decisionText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRootFormFilterInput | null > | null,
  or?: Array< ModelRootFormFilterInput | null > | null,
  not?: ModelRootFormFilterInput | null,
};

export type ModelDecisionFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelReviewStatusInput | null,
  serializedEditorState?: ModelStringInput | null,
  testapplicationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDecisionFilterInput | null > | null,
  or?: Array< ModelDecisionFilterInput | null > | null,
  not?: ModelDecisionFilterInput | null,
};

export type ModelFormAnswerFilterInput = {
  id?: ModelIDInput | null,
  values?: ModelStringInput | null,
  page?: ModelStringInput | null,
  section?: ModelStringInput | null,
  testapplicationID?: ModelIDInput | null,
  isCopy?: ModelBooleanInput | null,
  version?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormAnswerFilterInput | null > | null,
  or?: Array< ModelFormAnswerFilterInput | null > | null,
  not?: ModelFormAnswerFilterInput | null,
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null,
  serializedEditorState?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  testapplicationID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export type ModelTestCycleFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  isOpen?: ModelBooleanInput | null,
  props?: ModelStringInput | null,
  rootformID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  closedCycleMessage?: ModelStringInput | null,
  formUrl?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTestCycleFilterInput | null > | null,
  or?: Array< ModelTestCycleFilterInput | null > | null,
  not?: ModelTestCycleFilterInput | null,
};

export type ModelTestApplicationFilterInput = {
  id?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  lastSection?: ModelStringInput | null,
  submittedDate?: ModelStringInput | null,
  reviewStatus?: ModelReviewStatusInput | null,
  submissionStatus?: ModelSubmissionStatusInput | null,
  props?: ModelStringInput | null,
  type?: ModelApplicationTypesInput | null,
  testcycleID?: ModelIDInput | null,
  customStatus?: ModelStringInput | null,
  lastPage?: ModelIntInput | null,
  hasRevision?: ModelBooleanInput | null,
  filtered?: ModelBooleanInput | null,
  version?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTestApplicationFilterInput | null > | null,
  or?: Array< ModelTestApplicationFilterInput | null > | null,
  not?: ModelTestApplicationFilterInput | null,
};

export type ModelHabitatFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  longName?: ModelStringInput | null,
  state?: ModelStringInput | null,
  city?: ModelStringInput | null,
  users?: ModelIDInput | null,
  authenticationHeader?: ModelStringInput | null,
  urlName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHabitatFilterInput | null > | null,
  or?: Array< ModelHabitatFilterInput | null > | null,
  not?: ModelHabitatFilterInput | null,
};

export type ModelHabitatConnection = {
  __typename: "ModelHabitatConnection",
  items:  Array<Habitat | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionEditCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  rootformID?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  screenshots?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEditCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionEditCommentFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionLastReadFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  chatID?: ModelSubscriptionIDInput | null,
  lastMessage?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLastReadFilterInput | null > | null,
  or?: Array< ModelSubscriptionLastReadFilterInput | null > | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  chatID?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
};

export type ModelSubscriptionChatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  habitatID?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionIDInput | null,
  lastMessage?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatFilterInput | null > | null,
};

export type ModelSubscriptionFormTranslationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  autoTexts?: ModelSubscriptionStringInput | null,
  manualTexts?: ModelSubscriptionStringInput | null,
  formschemaID?: ModelSubscriptionIDInput | null,
  lang?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormTranslationFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormTranslationFilterInput | null > | null,
};

export type ModelSubscriptionComponentSchemaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schema?: ModelSubscriptionStringInput | null,
  formschemaID?: ModelSubscriptionIDInput | null,
  index?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionComponentSchemaFilterInput | null > | null,
  or?: Array< ModelSubscriptionComponentSchemaFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFormSchemaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  display?: ModelSubscriptionStringInput | null,
  path?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormSchemaFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormSchemaFilterInput | null > | null,
};

export type ModelSubscriptionReportFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  testCycleId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  habitatID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportFilterInput | null > | null,
};

export type ModelSubscriptionMaintenanceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  maintenance?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMaintenanceFilterInput | null > | null,
  or?: Array< ModelSubscriptionMaintenanceFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  dateOfBirth?: ModelSubscriptionStringInput | null,
  sex?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionIDInput | null,
  verified?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionRootFormFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  files?: ModelSubscriptionStringInput | null,
  habitatID?: ModelSubscriptionIDInput | null,
  formUrls?: ModelSubscriptionStringInput | null,
  confirmSubmissionText?: ModelSubscriptionStringInput | null,
  decisionText?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRootFormFilterInput | null > | null,
  or?: Array< ModelSubscriptionRootFormFilterInput | null > | null,
};

export type ModelSubscriptionDecisionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  serializedEditorState?: ModelSubscriptionStringInput | null,
  testapplicationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDecisionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDecisionFilterInput | null > | null,
};

export type ModelSubscriptionFormAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  values?: ModelSubscriptionStringInput | null,
  page?: ModelSubscriptionStringInput | null,
  section?: ModelSubscriptionStringInput | null,
  testapplicationID?: ModelSubscriptionIDInput | null,
  isCopy?: ModelSubscriptionBooleanInput | null,
  version?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormAnswerFilterInput | null > | null,
};

export type ModelSubscriptionNoteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  serializedEditorState?: ModelSubscriptionStringInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  testapplicationID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNoteFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoteFilterInput | null > | null,
};

export type ModelSubscriptionTestCycleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  isOpen?: ModelSubscriptionBooleanInput | null,
  props?: ModelSubscriptionStringInput | null,
  rootformID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  closedCycleMessage?: ModelSubscriptionStringInput | null,
  formUrl?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestCycleFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestCycleFilterInput | null > | null,
};

export type ModelSubscriptionTestApplicationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  lastSection?: ModelSubscriptionStringInput | null,
  submittedDate?: ModelSubscriptionStringInput | null,
  reviewStatus?: ModelSubscriptionStringInput | null,
  submissionStatus?: ModelSubscriptionStringInput | null,
  props?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  testcycleID?: ModelSubscriptionIDInput | null,
  customStatus?: ModelSubscriptionStringInput | null,
  lastPage?: ModelSubscriptionIntInput | null,
  hasRevision?: ModelSubscriptionBooleanInput | null,
  filtered?: ModelSubscriptionBooleanInput | null,
  version?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestApplicationFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestApplicationFilterInput | null > | null,
};

export type ModelSubscriptionHabitatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  longName?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  users?: ModelSubscriptionIDInput | null,
  authenticationHeader?: ModelSubscriptionStringInput | null,
  urlName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHabitatFilterInput | null > | null,
  or?: Array< ModelSubscriptionHabitatFilterInput | null > | null,
};

export type CreateEditCommentMutationVariables = {
  input: CreateEditCommentInput,
  condition?: ModelEditCommentConditionInput | null,
};

export type CreateEditCommentMutation = {
  createEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEditCommentMutationVariables = {
  input: UpdateEditCommentInput,
  condition?: ModelEditCommentConditionInput | null,
};

export type UpdateEditCommentMutation = {
  updateEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEditCommentMutationVariables = {
  input: DeleteEditCommentInput,
  condition?: ModelEditCommentConditionInput | null,
};

export type DeleteEditCommentMutation = {
  deleteEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLastReadMutationVariables = {
  input: CreateLastReadInput,
  condition?: ModelLastReadConditionInput | null,
};

export type CreateLastReadMutation = {
  createLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLastReadMutationVariables = {
  input: UpdateLastReadInput,
  condition?: ModelLastReadConditionInput | null,
};

export type UpdateLastReadMutation = {
  updateLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLastReadMutationVariables = {
  input: DeleteLastReadInput,
  condition?: ModelLastReadConditionInput | null,
};

export type DeleteLastReadMutation = {
  deleteLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChatMutationVariables = {
  input: CreateChatInput,
  condition?: ModelChatConditionInput | null,
};

export type CreateChatMutation = {
  createChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChatMutationVariables = {
  input: UpdateChatInput,
  condition?: ModelChatConditionInput | null,
};

export type UpdateChatMutation = {
  updateChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChatMutationVariables = {
  input: DeleteChatInput,
  condition?: ModelChatConditionInput | null,
};

export type DeleteChatMutation = {
  deleteChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFormTranslationMutationVariables = {
  input: CreateFormTranslationInput,
  condition?: ModelFormTranslationConditionInput | null,
};

export type CreateFormTranslationMutation = {
  createFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFormTranslationMutationVariables = {
  input: UpdateFormTranslationInput,
  condition?: ModelFormTranslationConditionInput | null,
};

export type UpdateFormTranslationMutation = {
  updateFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFormTranslationMutationVariables = {
  input: DeleteFormTranslationInput,
  condition?: ModelFormTranslationConditionInput | null,
};

export type DeleteFormTranslationMutation = {
  deleteFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateComponentSchemaMutationVariables = {
  input: CreateComponentSchemaInput,
  condition?: ModelComponentSchemaConditionInput | null,
};

export type CreateComponentSchemaMutation = {
  createComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateComponentSchemaMutationVariables = {
  input: UpdateComponentSchemaInput,
  condition?: ModelComponentSchemaConditionInput | null,
};

export type UpdateComponentSchemaMutation = {
  updateComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteComponentSchemaMutationVariables = {
  input: DeleteComponentSchemaInput,
  condition?: ModelComponentSchemaConditionInput | null,
};

export type DeleteComponentSchemaMutation = {
  deleteComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFormSchemaMutationVariables = {
  input: CreateFormSchemaInput,
  condition?: ModelFormSchemaConditionInput | null,
};

export type CreateFormSchemaMutation = {
  createFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFormSchemaMutationVariables = {
  input: UpdateFormSchemaInput,
  condition?: ModelFormSchemaConditionInput | null,
};

export type UpdateFormSchemaMutation = {
  updateFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFormSchemaMutationVariables = {
  input: DeleteFormSchemaInput,
  condition?: ModelFormSchemaConditionInput | null,
};

export type DeleteFormSchemaMutation = {
  deleteFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMaintenanceMutationVariables = {
  input: CreateMaintenanceInput,
  condition?: ModelMaintenanceConditionInput | null,
};

export type CreateMaintenanceMutation = {
  createMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMaintenanceMutationVariables = {
  input: UpdateMaintenanceInput,
  condition?: ModelMaintenanceConditionInput | null,
};

export type UpdateMaintenanceMutation = {
  updateMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMaintenanceMutationVariables = {
  input: DeleteMaintenanceInput,
  condition?: ModelMaintenanceConditionInput | null,
};

export type DeleteMaintenanceMutation = {
  deleteMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRootFormMutationVariables = {
  input: CreateRootFormInput,
  condition?: ModelRootFormConditionInput | null,
};

export type CreateRootFormMutation = {
  createRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRootFormMutationVariables = {
  input: UpdateRootFormInput,
  condition?: ModelRootFormConditionInput | null,
};

export type UpdateRootFormMutation = {
  updateRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRootFormMutationVariables = {
  input: DeleteRootFormInput,
  condition?: ModelRootFormConditionInput | null,
};

export type DeleteRootFormMutation = {
  deleteRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDecisionMutationVariables = {
  input: CreateDecisionInput,
  condition?: ModelDecisionConditionInput | null,
};

export type CreateDecisionMutation = {
  createDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDecisionMutationVariables = {
  input: UpdateDecisionInput,
  condition?: ModelDecisionConditionInput | null,
};

export type UpdateDecisionMutation = {
  updateDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDecisionMutationVariables = {
  input: DeleteDecisionInput,
  condition?: ModelDecisionConditionInput | null,
};

export type DeleteDecisionMutation = {
  deleteDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFormAnswerMutationVariables = {
  input: CreateFormAnswerInput,
  condition?: ModelFormAnswerConditionInput | null,
};

export type CreateFormAnswerMutation = {
  createFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFormAnswerMutationVariables = {
  input: UpdateFormAnswerInput,
  condition?: ModelFormAnswerConditionInput | null,
};

export type UpdateFormAnswerMutation = {
  updateFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFormAnswerMutationVariables = {
  input: DeleteFormAnswerInput,
  condition?: ModelFormAnswerConditionInput | null,
};

export type DeleteFormAnswerMutation = {
  deleteFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTestCycleMutationVariables = {
  input: CreateTestCycleInput,
  condition?: ModelTestCycleConditionInput | null,
};

export type CreateTestCycleMutation = {
  createTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestCycleMutationVariables = {
  input: UpdateTestCycleInput,
  condition?: ModelTestCycleConditionInput | null,
};

export type UpdateTestCycleMutation = {
  updateTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestCycleMutationVariables = {
  input: DeleteTestCycleInput,
  condition?: ModelTestCycleConditionInput | null,
};

export type DeleteTestCycleMutation = {
  deleteTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTestApplicationMutationVariables = {
  input: CreateTestApplicationInput,
  condition?: ModelTestApplicationConditionInput | null,
};

export type CreateTestApplicationMutation = {
  createTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestApplicationMutationVariables = {
  input: UpdateTestApplicationInput,
  condition?: ModelTestApplicationConditionInput | null,
};

export type UpdateTestApplicationMutation = {
  updateTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestApplicationMutationVariables = {
  input: DeleteTestApplicationInput,
  condition?: ModelTestApplicationConditionInput | null,
};

export type DeleteTestApplicationMutation = {
  deleteTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHabitatMutationVariables = {
  input: CreateHabitatInput,
  condition?: ModelHabitatConditionInput | null,
};

export type CreateHabitatMutation = {
  createHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHabitatMutationVariables = {
  input: UpdateHabitatInput,
  condition?: ModelHabitatConditionInput | null,
};

export type UpdateHabitatMutation = {
  updateHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHabitatMutationVariables = {
  input: DeleteHabitatInput,
  condition?: ModelHabitatConditionInput | null,
};

export type DeleteHabitatMutation = {
  deleteHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetEditCommentQueryVariables = {
  id: string,
};

export type GetEditCommentQuery = {
  getEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEditCommentsQueryVariables = {
  filter?: ModelEditCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEditCommentsQuery = {
  listEditComments?:  {
    __typename: "ModelEditCommentConnection",
    items:  Array< {
      __typename: "EditComment",
      id: string,
      rootformID: string,
      content: string,
      screenshots: Array< string >,
      status: EditCommentStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EditCommentsByRootformIDQueryVariables = {
  rootformID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEditCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EditCommentsByRootformIDQuery = {
  editCommentsByRootformID?:  {
    __typename: "ModelEditCommentConnection",
    items:  Array< {
      __typename: "EditComment",
      id: string,
      rootformID: string,
      content: string,
      screenshots: Array< string >,
      status: EditCommentStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLastReadQueryVariables = {
  id: string,
};

export type GetLastReadQuery = {
  getLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLastReadsQueryVariables = {
  filter?: ModelLastReadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLastReadsQuery = {
  listLastReads?:  {
    __typename: "ModelLastReadConnection",
    items:  Array< {
      __typename: "LastRead",
      id: string,
      chatID: string,
      lastMessage: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LastReadsByChatIDQueryVariables = {
  chatID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLastReadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LastReadsByChatIDQuery = {
  lastReadsByChatID?:  {
    __typename: "ModelLastReadConnection",
    items:  Array< {
      __typename: "LastRead",
      id: string,
      chatID: string,
      lastMessage: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      chatID: string,
      content: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByChatIDQueryVariables = {
  chatID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByChatIDQuery = {
  messagesByChatID?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      chatID: string,
      content: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatQueryVariables = {
  id: string,
};

export type GetChatQuery = {
  getChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChatsQueryVariables = {
  filter?: ModelChatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatsQuery = {
  listChats?:  {
    __typename: "ModelChatConnection",
    items:  Array< {
      __typename: "Chat",
      id: string,
      Messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      habitatID: string,
      owner: string,
      lastMessage?: string | null,
      LastReads?:  {
        __typename: "ModelLastReadConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatsByHabitatIDQueryVariables = {
  habitatID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatsByHabitatIDQuery = {
  chatsByHabitatID?:  {
    __typename: "ModelChatConnection",
    items:  Array< {
      __typename: "Chat",
      id: string,
      Messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      habitatID: string,
      owner: string,
      lastMessage?: string | null,
      LastReads?:  {
        __typename: "ModelLastReadConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFormTranslationQueryVariables = {
  id: string,
};

export type GetFormTranslationQuery = {
  getFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormTranslationsQueryVariables = {
  filter?: ModelFormTranslationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormTranslationsQuery = {
  listFormTranslations?:  {
    __typename: "ModelFormTranslationConnection",
    items:  Array< {
      __typename: "FormTranslation",
      id: string,
      autoTexts: string,
      manualTexts: string,
      formschemaID: string,
      lang: Language,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FormTranslationsByFormschemaIDQueryVariables = {
  formschemaID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormTranslationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormTranslationsByFormschemaIDQuery = {
  formTranslationsByFormschemaID?:  {
    __typename: "ModelFormTranslationConnection",
    items:  Array< {
      __typename: "FormTranslation",
      id: string,
      autoTexts: string,
      manualTexts: string,
      formschemaID: string,
      lang: Language,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetComponentSchemaQueryVariables = {
  id: string,
};

export type GetComponentSchemaQuery = {
  getComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListComponentSchemasQueryVariables = {
  filter?: ModelComponentSchemaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListComponentSchemasQuery = {
  listComponentSchemas?:  {
    __typename: "ModelComponentSchemaConnection",
    items:  Array< {
      __typename: "ComponentSchema",
      id: string,
      schema: string,
      formschemaID: string,
      index: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ComponentSchemasByFormschemaIDQueryVariables = {
  formschemaID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelComponentSchemaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ComponentSchemasByFormschemaIDQuery = {
  componentSchemasByFormschemaID?:  {
    __typename: "ModelComponentSchemaConnection",
    items:  Array< {
      __typename: "ComponentSchema",
      id: string,
      schema: string,
      formschemaID: string,
      index: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFormSchemaQueryVariables = {
  id: string,
};

export type GetFormSchemaQuery = {
  getFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormSchemasQueryVariables = {
  filter?: ModelFormSchemaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormSchemasQuery = {
  listFormSchemas?:  {
    __typename: "ModelFormSchemaConnection",
    items:  Array< {
      __typename: "FormSchema",
      id: string,
      title: string,
      name: string,
      display: string,
      path: string,
      ComponentsSchemas?:  {
        __typename: "ModelComponentSchemaConnection",
        nextToken?: string | null,
      } | null,
      FormTranslations?:  {
        __typename: "ModelFormTranslationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      content: string,
      testCycleId: string,
      title: string,
      status: ReportStatus,
      habitatID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportsByHabitatIDQueryVariables = {
  habitatID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportsByHabitatIDQuery = {
  reportsByHabitatID?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      content: string,
      testCycleId: string,
      title: string,
      status: ReportStatus,
      habitatID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMaintenanceQueryVariables = {
  id: string,
};

export type GetMaintenanceQuery = {
  getMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMaintenancesQueryVariables = {
  filter?: ModelMaintenanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMaintenancesQuery = {
  listMaintenances?:  {
    __typename: "ModelMaintenanceConnection",
    items:  Array< {
      __typename: "Maintenance",
      id: string,
      maintenance?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      dateOfBirth: string,
      sex: Sexs,
      phoneNumber: string,
      affiliateProps?:  {
        __typename: "AffiliateProps",
        titleAtHabitat: string,
        roleDescription: string,
        joinDate?: string | null,
        joinMonth?: string | null,
        joinYear?: string | null,
      } | null,
      applicantProps?:  {
        __typename: "ApplicantProps",
        state: string,
        city: string,
        street: string,
        householdMembersNumber: number,
        householdAnnualIncome: number,
        currentlyUnemployed: string,
        currentWorkTitle?: string | null,
        nameOfEmployer?: string | null,
        howDidYouHearAbout: string,
        firstTimeApplying: string,
        whatAreYouInterestedIn: string,
      } | null,
      type: UserTypes,
      owner: string,
      verified?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRootFormQueryVariables = {
  id: string,
};

export type GetRootFormQuery = {
  getRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRootFormsQueryVariables = {
  filter?: ModelRootFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRootFormsQuery = {
  listRootForms?:  {
    __typename: "ModelRootFormConnection",
    items:  Array< {
      __typename: "RootForm",
      id: string,
      name?: string | null,
      status?: RootFormStatusTypes | null,
      description?: string | null,
      files?: Array< string | null > | null,
      Cycles?:  {
        __typename: "ModelTestCycleConnection",
        nextToken?: string | null,
      } | null,
      habitatID: string,
      formUrls: Array< string >,
      supportQuestion?:  {
        __typename: "MultiLangText",
        en: string,
        es?: string | null,
      } | null,
      confirmSubmissionText?: string | null,
      decisionText?: string | null,
      EditComments?:  {
        __typename: "ModelEditCommentConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RootFormsByHabitatIDQueryVariables = {
  habitatID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRootFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RootFormsByHabitatIDQuery = {
  rootFormsByHabitatID?:  {
    __typename: "ModelRootFormConnection",
    items:  Array< {
      __typename: "RootForm",
      id: string,
      name?: string | null,
      status?: RootFormStatusTypes | null,
      description?: string | null,
      files?: Array< string | null > | null,
      Cycles?:  {
        __typename: "ModelTestCycleConnection",
        nextToken?: string | null,
      } | null,
      habitatID: string,
      formUrls: Array< string >,
      supportQuestion?:  {
        __typename: "MultiLangText",
        en: string,
        es?: string | null,
      } | null,
      confirmSubmissionText?: string | null,
      decisionText?: string | null,
      EditComments?:  {
        __typename: "ModelEditCommentConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDecisionQueryVariables = {
  id: string,
};

export type GetDecisionQuery = {
  getDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDecisionsQueryVariables = {
  filter?: ModelDecisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDecisionsQuery = {
  listDecisions?:  {
    __typename: "ModelDecisionConnection",
    items:  Array< {
      __typename: "Decision",
      id: string,
      status: ReviewStatus,
      serializedEditorState: string,
      testapplicationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DecisionsByTestapplicationIDQueryVariables = {
  testapplicationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDecisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DecisionsByTestapplicationIDQuery = {
  decisionsByTestapplicationID?:  {
    __typename: "ModelDecisionConnection",
    items:  Array< {
      __typename: "Decision",
      id: string,
      status: ReviewStatus,
      serializedEditorState: string,
      testapplicationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFormAnswerQueryVariables = {
  id: string,
};

export type GetFormAnswerQuery = {
  getFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormAnswersQueryVariables = {
  filter?: ModelFormAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormAnswersQuery = {
  listFormAnswers?:  {
    __typename: "ModelFormAnswerConnection",
    items:  Array< {
      __typename: "FormAnswer",
      id: string,
      values?: string | null,
      page?: string | null,
      section?: string | null,
      testapplicationID: string,
      isCopy?: boolean | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FormAnswersByTestapplicationIDQueryVariables = {
  testapplicationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormAnswersByTestapplicationIDQuery = {
  formAnswersByTestapplicationID?:  {
    __typename: "ModelFormAnswerConnection",
    items:  Array< {
      __typename: "FormAnswer",
      id: string,
      values?: string | null,
      page?: string | null,
      section?: string | null,
      testapplicationID: string,
      isCopy?: boolean | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNoteQueryVariables = {
  id: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotesQueryVariables = {
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      serializedEditorState: string,
      ownerID: string,
      testapplicationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotesByTestapplicationIDQueryVariables = {
  testapplicationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotesByTestapplicationIDQuery = {
  notesByTestapplicationID?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      serializedEditorState: string,
      ownerID: string,
      testapplicationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestCycleQueryVariables = {
  id: string,
};

export type GetTestCycleQuery = {
  getTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestCyclesQueryVariables = {
  filter?: ModelTestCycleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestCyclesQuery = {
  listTestCycles?:  {
    __typename: "ModelTestCycleConnection",
    items:  Array< {
      __typename: "TestCycle",
      id: string,
      startDate: string,
      endDate?: string | null,
      isOpen: boolean,
      props?: string | null,
      TestApplications?:  {
        __typename: "ModelTestApplicationConnection",
        nextToken?: string | null,
      } | null,
      rootformID: string,
      name?: string | null,
      closedCycleMessage: string,
      formUrl: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TestCyclesByRootformIDQueryVariables = {
  rootformID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTestCycleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TestCyclesByRootformIDQuery = {
  testCyclesByRootformID?:  {
    __typename: "ModelTestCycleConnection",
    items:  Array< {
      __typename: "TestCycle",
      id: string,
      startDate: string,
      endDate?: string | null,
      isOpen: boolean,
      props?: string | null,
      TestApplications?:  {
        __typename: "ModelTestApplicationConnection",
        nextToken?: string | null,
      } | null,
      rootformID: string,
      name?: string | null,
      closedCycleMessage: string,
      formUrl: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestApplicationQueryVariables = {
  id: string,
};

export type GetTestApplicationQuery = {
  getTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestApplicationsQueryVariables = {
  filter?: ModelTestApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestApplicationsQuery = {
  listTestApplications?:  {
    __typename: "ModelTestApplicationConnection",
    items:  Array< {
      __typename: "TestApplication",
      id: string,
      ownerID?: string | null,
      lastSection?: string | null,
      submittedDate: string,
      reviewStatus: ReviewStatus,
      submissionStatus: SubmissionStatus,
      props?: string | null,
      type: ApplicationTypes,
      testcycleID: string,
      Notes?:  {
        __typename: "ModelNoteConnection",
        nextToken?: string | null,
      } | null,
      FormAnswers?:  {
        __typename: "ModelFormAnswerConnection",
        nextToken?: string | null,
      } | null,
      Decisions?:  {
        __typename: "ModelDecisionConnection",
        nextToken?: string | null,
      } | null,
      customStatus?: string | null,
      lastPage?: number | null,
      hasRevision?: boolean | null,
      filtered?: boolean | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TestApplicationsByTestcycleIDQueryVariables = {
  testcycleID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTestApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TestApplicationsByTestcycleIDQuery = {
  testApplicationsByTestcycleID?:  {
    __typename: "ModelTestApplicationConnection",
    items:  Array< {
      __typename: "TestApplication",
      id: string,
      ownerID?: string | null,
      lastSection?: string | null,
      submittedDate: string,
      reviewStatus: ReviewStatus,
      submissionStatus: SubmissionStatus,
      props?: string | null,
      type: ApplicationTypes,
      testcycleID: string,
      Notes?:  {
        __typename: "ModelNoteConnection",
        nextToken?: string | null,
      } | null,
      FormAnswers?:  {
        __typename: "ModelFormAnswerConnection",
        nextToken?: string | null,
      } | null,
      Decisions?:  {
        __typename: "ModelDecisionConnection",
        nextToken?: string | null,
      } | null,
      customStatus?: string | null,
      lastPage?: number | null,
      hasRevision?: boolean | null,
      filtered?: boolean | null,
      version?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHabitatQueryVariables = {
  id: string,
};

export type GetHabitatQuery = {
  getHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHabitatsQueryVariables = {
  filter?: ModelHabitatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHabitatsQuery = {
  listHabitats?:  {
    __typename: "ModelHabitatConnection",
    items:  Array< {
      __typename: "Habitat",
      id: string,
      name?: string | null,
      longName?: string | null,
      state?: string | null,
      city?: string | null,
      props:  {
        __typename: "HabitatProps",
        customStatus?: Array< string > | null,
        closedCycleMessages: Array< string >,
        notifySubmissionsEmails?: Array< string > | null,
      },
      users?: Array< string | null > | null,
      authenticationHeader?: string | null,
      RootForms?:  {
        __typename: "ModelRootFormConnection",
        nextToken?: string | null,
      } | null,
      urlName?: string | null,
      Reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      Chats?:  {
        __typename: "ModelChatConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateEditCommentSubscriptionVariables = {
  filter?: ModelSubscriptionEditCommentFilterInput | null,
};

export type OnCreateEditCommentSubscription = {
  onCreateEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEditCommentSubscriptionVariables = {
  filter?: ModelSubscriptionEditCommentFilterInput | null,
};

export type OnUpdateEditCommentSubscription = {
  onUpdateEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEditCommentSubscriptionVariables = {
  filter?: ModelSubscriptionEditCommentFilterInput | null,
};

export type OnDeleteEditCommentSubscription = {
  onDeleteEditComment?:  {
    __typename: "EditComment",
    id: string,
    rootformID: string,
    content: string,
    screenshots: Array< string >,
    status: EditCommentStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLastReadSubscriptionVariables = {
  filter?: ModelSubscriptionLastReadFilterInput | null,
};

export type OnCreateLastReadSubscription = {
  onCreateLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLastReadSubscriptionVariables = {
  filter?: ModelSubscriptionLastReadFilterInput | null,
};

export type OnUpdateLastReadSubscription = {
  onUpdateLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLastReadSubscriptionVariables = {
  filter?: ModelSubscriptionLastReadFilterInput | null,
};

export type OnDeleteLastReadSubscription = {
  onDeleteLastRead?:  {
    __typename: "LastRead",
    id: string,
    chatID: string,
    lastMessage: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    chatID: string,
    content: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnCreateChatSubscription = {
  onCreateChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnUpdateChatSubscription = {
  onUpdateChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnDeleteChatSubscription = {
  onDeleteChat?:  {
    __typename: "Chat",
    id: string,
    Messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        chatID: string,
        content: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    owner: string,
    lastMessage?: string | null,
    LastReads?:  {
      __typename: "ModelLastReadConnection",
      items:  Array< {
        __typename: "LastRead",
        id: string,
        chatID: string,
        lastMessage: string,
        owner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFormTranslationSubscriptionVariables = {
  filter?: ModelSubscriptionFormTranslationFilterInput | null,
};

export type OnCreateFormTranslationSubscription = {
  onCreateFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFormTranslationSubscriptionVariables = {
  filter?: ModelSubscriptionFormTranslationFilterInput | null,
};

export type OnUpdateFormTranslationSubscription = {
  onUpdateFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFormTranslationSubscriptionVariables = {
  filter?: ModelSubscriptionFormTranslationFilterInput | null,
};

export type OnDeleteFormTranslationSubscription = {
  onDeleteFormTranslation?:  {
    __typename: "FormTranslation",
    id: string,
    autoTexts: string,
    manualTexts: string,
    formschemaID: string,
    lang: Language,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateComponentSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionComponentSchemaFilterInput | null,
};

export type OnCreateComponentSchemaSubscription = {
  onCreateComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateComponentSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionComponentSchemaFilterInput | null,
};

export type OnUpdateComponentSchemaSubscription = {
  onUpdateComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteComponentSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionComponentSchemaFilterInput | null,
};

export type OnDeleteComponentSchemaSubscription = {
  onDeleteComponentSchema?:  {
    __typename: "ComponentSchema",
    id: string,
    schema: string,
    formschemaID: string,
    index: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFormSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionFormSchemaFilterInput | null,
};

export type OnCreateFormSchemaSubscription = {
  onCreateFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFormSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionFormSchemaFilterInput | null,
};

export type OnUpdateFormSchemaSubscription = {
  onUpdateFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFormSchemaSubscriptionVariables = {
  filter?: ModelSubscriptionFormSchemaFilterInput | null,
};

export type OnDeleteFormSchemaSubscription = {
  onDeleteFormSchema?:  {
    __typename: "FormSchema",
    id: string,
    title: string,
    name: string,
    display: string,
    path: string,
    ComponentsSchemas?:  {
      __typename: "ModelComponentSchemaConnection",
      items:  Array< {
        __typename: "ComponentSchema",
        id: string,
        schema: string,
        formschemaID: string,
        index: number,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormTranslations?:  {
      __typename: "ModelFormTranslationConnection",
      items:  Array< {
        __typename: "FormTranslation",
        id: string,
        autoTexts: string,
        manualTexts: string,
        formschemaID: string,
        lang: Language,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    content: string,
    testCycleId: string,
    title: string,
    status: ReportStatus,
    habitatID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMaintenanceSubscriptionVariables = {
  filter?: ModelSubscriptionMaintenanceFilterInput | null,
};

export type OnCreateMaintenanceSubscription = {
  onCreateMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMaintenanceSubscriptionVariables = {
  filter?: ModelSubscriptionMaintenanceFilterInput | null,
};

export type OnUpdateMaintenanceSubscription = {
  onUpdateMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMaintenanceSubscriptionVariables = {
  filter?: ModelSubscriptionMaintenanceFilterInput | null,
};

export type OnDeleteMaintenanceSubscription = {
  onDeleteMaintenance?:  {
    __typename: "Maintenance",
    id: string,
    maintenance?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    sex: Sexs,
    phoneNumber: string,
    affiliateProps?:  {
      __typename: "AffiliateProps",
      titleAtHabitat: string,
      roleDescription: string,
      joinDate?: string | null,
      joinMonth?: string | null,
      joinYear?: string | null,
    } | null,
    applicantProps?:  {
      __typename: "ApplicantProps",
      state: string,
      city: string,
      street: string,
      householdMembersNumber: number,
      householdAnnualIncome: number,
      currentlyUnemployed: string,
      currentWorkTitle?: string | null,
      nameOfEmployer?: string | null,
      howDidYouHearAbout: string,
      firstTimeApplying: string,
      whatAreYouInterestedIn: string,
    } | null,
    type: UserTypes,
    owner: string,
    verified?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRootFormSubscriptionVariables = {
  filter?: ModelSubscriptionRootFormFilterInput | null,
};

export type OnCreateRootFormSubscription = {
  onCreateRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRootFormSubscriptionVariables = {
  filter?: ModelSubscriptionRootFormFilterInput | null,
};

export type OnUpdateRootFormSubscription = {
  onUpdateRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRootFormSubscriptionVariables = {
  filter?: ModelSubscriptionRootFormFilterInput | null,
};

export type OnDeleteRootFormSubscription = {
  onDeleteRootForm?:  {
    __typename: "RootForm",
    id: string,
    name?: string | null,
    status?: RootFormStatusTypes | null,
    description?: string | null,
    files?: Array< string | null > | null,
    Cycles?:  {
      __typename: "ModelTestCycleConnection",
      items:  Array< {
        __typename: "TestCycle",
        id: string,
        startDate: string,
        endDate?: string | null,
        isOpen: boolean,
        props?: string | null,
        rootformID: string,
        name?: string | null,
        closedCycleMessage: string,
        formUrl: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    habitatID: string,
    formUrls: Array< string >,
    supportQuestion?:  {
      __typename: "MultiLangText",
      en: string,
      es?: string | null,
    } | null,
    confirmSubmissionText?: string | null,
    decisionText?: string | null,
    EditComments?:  {
      __typename: "ModelEditCommentConnection",
      items:  Array< {
        __typename: "EditComment",
        id: string,
        rootformID: string,
        content: string,
        screenshots: Array< string >,
        status: EditCommentStatus,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDecisionSubscriptionVariables = {
  filter?: ModelSubscriptionDecisionFilterInput | null,
};

export type OnCreateDecisionSubscription = {
  onCreateDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDecisionSubscriptionVariables = {
  filter?: ModelSubscriptionDecisionFilterInput | null,
};

export type OnUpdateDecisionSubscription = {
  onUpdateDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDecisionSubscriptionVariables = {
  filter?: ModelSubscriptionDecisionFilterInput | null,
};

export type OnDeleteDecisionSubscription = {
  onDeleteDecision?:  {
    __typename: "Decision",
    id: string,
    status: ReviewStatus,
    serializedEditorState: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFormAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionFormAnswerFilterInput | null,
};

export type OnCreateFormAnswerSubscription = {
  onCreateFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFormAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionFormAnswerFilterInput | null,
};

export type OnUpdateFormAnswerSubscription = {
  onUpdateFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFormAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionFormAnswerFilterInput | null,
};

export type OnDeleteFormAnswerSubscription = {
  onDeleteFormAnswer?:  {
    __typename: "FormAnswer",
    id: string,
    values?: string | null,
    page?: string | null,
    section?: string | null,
    testapplicationID: string,
    isCopy?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnCreateNoteSubscription = {
  onCreateNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnUpdateNoteSubscription = {
  onUpdateNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNoteSubscriptionVariables = {
  filter?: ModelSubscriptionNoteFilterInput | null,
};

export type OnDeleteNoteSubscription = {
  onDeleteNote?:  {
    __typename: "Note",
    id: string,
    serializedEditorState: string,
    ownerID: string,
    testapplicationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTestCycleSubscriptionVariables = {
  filter?: ModelSubscriptionTestCycleFilterInput | null,
};

export type OnCreateTestCycleSubscription = {
  onCreateTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestCycleSubscriptionVariables = {
  filter?: ModelSubscriptionTestCycleFilterInput | null,
};

export type OnUpdateTestCycleSubscription = {
  onUpdateTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestCycleSubscriptionVariables = {
  filter?: ModelSubscriptionTestCycleFilterInput | null,
};

export type OnDeleteTestCycleSubscription = {
  onDeleteTestCycle?:  {
    __typename: "TestCycle",
    id: string,
    startDate: string,
    endDate?: string | null,
    isOpen: boolean,
    props?: string | null,
    TestApplications?:  {
      __typename: "ModelTestApplicationConnection",
      items:  Array< {
        __typename: "TestApplication",
        id: string,
        ownerID?: string | null,
        lastSection?: string | null,
        submittedDate: string,
        reviewStatus: ReviewStatus,
        submissionStatus: SubmissionStatus,
        props?: string | null,
        type: ApplicationTypes,
        testcycleID: string,
        customStatus?: string | null,
        lastPage?: number | null,
        hasRevision?: boolean | null,
        filtered?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rootformID: string,
    name?: string | null,
    closedCycleMessage: string,
    formUrl: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTestApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionTestApplicationFilterInput | null,
};

export type OnCreateTestApplicationSubscription = {
  onCreateTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionTestApplicationFilterInput | null,
};

export type OnUpdateTestApplicationSubscription = {
  onUpdateTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestApplicationSubscriptionVariables = {
  filter?: ModelSubscriptionTestApplicationFilterInput | null,
};

export type OnDeleteTestApplicationSubscription = {
  onDeleteTestApplication?:  {
    __typename: "TestApplication",
    id: string,
    ownerID?: string | null,
    lastSection?: string | null,
    submittedDate: string,
    reviewStatus: ReviewStatus,
    submissionStatus: SubmissionStatus,
    props?: string | null,
    type: ApplicationTypes,
    testcycleID: string,
    Notes?:  {
      __typename: "ModelNoteConnection",
      items:  Array< {
        __typename: "Note",
        id: string,
        serializedEditorState: string,
        ownerID: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    FormAnswers?:  {
      __typename: "ModelFormAnswerConnection",
      items:  Array< {
        __typename: "FormAnswer",
        id: string,
        values?: string | null,
        page?: string | null,
        section?: string | null,
        testapplicationID: string,
        isCopy?: boolean | null,
        version?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Decisions?:  {
      __typename: "ModelDecisionConnection",
      items:  Array< {
        __typename: "Decision",
        id: string,
        status: ReviewStatus,
        serializedEditorState: string,
        testapplicationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    customStatus?: string | null,
    lastPage?: number | null,
    hasRevision?: boolean | null,
    filtered?: boolean | null,
    version?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateHabitatSubscriptionVariables = {
  filter?: ModelSubscriptionHabitatFilterInput | null,
};

export type OnCreateHabitatSubscription = {
  onCreateHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHabitatSubscriptionVariables = {
  filter?: ModelSubscriptionHabitatFilterInput | null,
};

export type OnUpdateHabitatSubscription = {
  onUpdateHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHabitatSubscriptionVariables = {
  filter?: ModelSubscriptionHabitatFilterInput | null,
};

export type OnDeleteHabitatSubscription = {
  onDeleteHabitat?:  {
    __typename: "Habitat",
    id: string,
    name?: string | null,
    longName?: string | null,
    state?: string | null,
    city?: string | null,
    props:  {
      __typename: "HabitatProps",
      customStatus?: Array< string > | null,
      gallery?:  Array< {
        __typename: "GalleryItem",
        id?: string | null,
        image?: string | null,
        title?: string | null,
        message?: string | null,
      } > | null,
      sidebarName?:  {
        __typename: "SidebarName",
        name?: string | null,
        fontSize?: string | null,
      } | null,
      closedCycleMessages: Array< string >,
      notifySubmissionsEmails?: Array< string > | null,
    },
    users?: Array< string | null > | null,
    authenticationHeader?: string | null,
    RootForms?:  {
      __typename: "ModelRootFormConnection",
      items:  Array< {
        __typename: "RootForm",
        id: string,
        name?: string | null,
        status?: RootFormStatusTypes | null,
        description?: string | null,
        files?: Array< string | null > | null,
        habitatID: string,
        formUrls: Array< string >,
        confirmSubmissionText?: string | null,
        decisionText?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    urlName?: string | null,
    Reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        content: string,
        testCycleId: string,
        title: string,
        status: ReportStatus,
        habitatID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    Chats?:  {
      __typename: "ModelChatConnection",
      items:  Array< {
        __typename: "Chat",
        id: string,
        habitatID: string,
        owner: string,
        lastMessage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
