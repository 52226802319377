import { defer, LoaderFunction } from 'react-router-dom';
import { queryEditCommentsByRootForm } from 'services/graphql/EditComment';
import { queryAllFormSchemas } from 'services/graphql/FormSchema';
import { queryFormTranslationsByFormSchema } from 'services/graphql/FormTranslations';
import { queryRootForm } from 'services/graphql/RootForm';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';
import { generateComponents } from 'utils/formio';

const getCommentsData = async (formId: string | undefined) => {
  if (!formId) {
    throw new Response('Form not specified.', { status: 400 });
  }

  await untilAmplifyFinishConfiguration();

  const rootForm = await queryRootForm(formId);

  if (!rootForm) {
    throw new Response('Root form not found.', { status: 404 });
  }

  const latestForm = await queryAllFormSchemas({
    filter: {
      path: {
        eq: rootForm.formUrls.at(-1) as string,
      },
    },
  });

  if (latestForm.length === 0) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const originalFormSchema = latestForm[0];

  const components = await generateComponents(originalFormSchema.id);

  const formSchema = {
    title: originalFormSchema.title,
    path: originalFormSchema.path,
    name: originalFormSchema.name,
    display: originalFormSchema.display,
    components,
  };

  const translations = await queryFormTranslationsByFormSchema(
    originalFormSchema.id
  );

  const comments = await queryEditCommentsByRootForm({
    rootformID: rootForm.id,
  });

  return { rootForm, formSchema, translations, comments };
};

export type TCommentsData = Awaited<
  Promise<ReturnType<typeof getCommentsData>>
>;

const commentsLoader: LoaderFunction = async ({ params }) => {
  const { formId } = params;

  return defer({ commentsData: getCommentsData(formId) });
};

export default commentsLoader;
