import React, { ReactNode } from 'react';
import styles from './InfiniteCarousel.module.css';

interface IInfiniteCarouselProps {
  items: ReactNode[];
  speed?: number;
}

const InfiniteCarousel = ({ items, speed = 1 }: IInfiniteCarouselProps) => (
  <div className={`${styles.carousel}`}>
    <div className={`${styles.container} ${styles.first}`}>{items}</div>
    <div className={`${styles.container} ${styles.second}`}>{items}</div>
  </div>
);

export default InfiniteCarousel;
