import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@aws-amplify/ui-react';
import { ReviewStatus } from 'API';
import { stringToHumanReadable } from 'utils/strings';
import styles from './GeneralSection.module.css';

interface IGeneralSectionProps {
  applicantName: string;
  email: string;
  phone: string;
  dateSubmitted: string;
  reviewStatus: ReviewStatus;
  customStatus: string;
}

const GeneralSection = ({
  applicantName,
  email,
  phone,
  dateSubmitted,
  reviewStatus,
  customStatus,
}: IGeneralSectionProps) => (
  <div>
    <Table variation="bordered" className={styles.table}>
      <TableBody>
        <TableRow>
          <TableCell as="th" width={250}>
            Applicant
          </TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>
            {applicantName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">Email</TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>{email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">Phone</TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>{phone}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">Date Submitted</TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>
            {dateSubmitted}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">Review Status</TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>
            {stringToHumanReadable(reviewStatus)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th">Custom Status</TableCell>
          <TableCell style={{ wordBreak: 'break-word' }}>
            {customStatus}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

export default GeneralSection;
