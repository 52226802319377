import React, { HTMLAttributes } from 'react';
import styles from './Skeleton.module.css';

interface ISkeletonDivProps extends HTMLAttributes<HTMLDivElement> {
  variation?: 'div';
  numOfCharacters?: number;
}

interface ISkeletonTextProps extends HTMLAttributes<HTMLSpanElement> {
  variation?: 'text';
  numOfCharacters?: number;
}

type TSkeletonProps = ISkeletonDivProps | ISkeletonTextProps;

const Skeleton = ({
  variation = 'div',
  className,
  ...otherProps
}: TSkeletonProps) => {
  switch (variation) {
    case 'text': {
      const { numOfCharacters, ...otherSpanProps } = otherProps;
      return (
        <span
          className={`${styles.skeleton} ${styles.text} ${className} hfh-skeleton`}
          {...otherSpanProps}
        >
          {'A'.repeat(numOfCharacters || 1)}
        </span>
      );
    }
    case 'div':
    default:
      return (
        <div className={`${styles.skeleton} ${className}`} {...otherProps} />
      );
  }
};

export default Skeleton;
