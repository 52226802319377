import { createBrowserRouter, Navigate } from 'react-router-dom';
import CheckMaintenance from 'layouts/Maintenance/CheckMaintenance';
import Landing from 'pages/landing';
import { ROUTES } from 'utils/constants';
import HabitatLayout from 'layouts/HabitatLayout';
import ApplicantLayout from 'layouts/ApplicantLayout';
import ApplicantApplicationsPage from 'pages/[habitat]/applicant/applications';
import ApplicantDecisionsPage from 'pages/[habitat]/applicant/decisions/ApplicantDecisionsPage';
import ApplicantCyclePage from 'pages/[habitat]/applicant/[cycleId]';
import AffiliateLayout from 'layouts/AffiliateLayout';
import AffiliateFormsPage from 'pages/[habitat]/affiliate/forms';
import AffiliateHomePage from 'pages/[habitat]/affiliate/home';
import AffiliateReportsPage from 'pages/[habitat]/affiliate/reports';
import AffiliateUsersPage from 'pages/[habitat]/affiliate/users';
import CyclesPage from 'pages/[habitat]/affiliate/cycles';
import AffiliateCycleApplications from 'pages/[habitat]/affiliate/cycles/[cycleId]/AffiliateCycleApplications';
import AffiliateApplicationDetailsPage from 'pages/[habitat]/affiliate/cycles/[cycleId]/[applicationId]';
import MaintenancePage from 'pages/maintenance/Maintenance';
import Print from 'pages/print/page';
import HabitatError from 'pages/[habitat]/HabitatError';
import RootFormError from 'pages/[habitat]/affiliate/cycles/page.error';
import CycleLoaderError from 'components/CycleLoaderError';
import AffiliateApplicationDetailsPageError from 'pages/[habitat]/affiliate/cycles/[cycleId]/[applicationId]/AffiliateApplicationDetailsPage.error';
import { Authenticator } from '@aws-amplify/ui-react';
import AppError from 'pages/landing/Landing.error';
import AffiliateTestApplication from 'pages/[habitat]/affiliate/cycles/[cycleId]/test-application/AffiliateApplicationTest';
import AffiliateUserDetails from 'pages/[habitat]/affiliate/users/[userId]/AffiliateUserDetails';
import AffiliateUserDetailsError from 'pages/[habitat]/affiliate/users/[userId]/AffiliateUserDetails.error';
import AdminLayout from 'layouts/AdminLayout';
import AdminHomePage from 'pages/admin/AdminHomePage';
import AdminFormsPage from 'pages/admin/forms/AdminFormsPage';
import AdminNewFormPage from 'pages/admin/forms/new/AdminNewFormPage';
import AdminEditFormPage from 'pages/admin/forms/[formId]/AdminEditFormPage';
import AdminEditFormError from 'pages/admin/forms/[formId]/AdminEditFormPage.error';
import AffiliateSettingsPage from 'pages/[habitat]/affiliate/settings/AffiliateSettingsPage';
import AffiliateProfilePage from 'pages/[habitat]/affiliate/profile/AffiliateProfilePage';
import ApplicantProfilePage from 'pages/[habitat]/applicant/profile/ApplicantProfilePage';
import AdminProfilePage from 'pages/admin/profile/AdminProfilePage';
import ApplicantChatPage from 'pages/[habitat]/applicant/chat/ApplicantChatPage';
import AffiliateChatsPage from 'pages/[habitat]/affiliate/chats/AffiliateChatsPage';
import AffiliateChatPage from 'pages/[habitat]/affiliate/chats/[chatId]/AffiliateChatPage';
import AffiliateCommentsPage from 'pages/[habitat]/affiliate/forms/[formId]/comments/AffiliateCommentsPage';
import habitatLoader from './loaders/habitat';
import rootFormLoader from './loaders/rootForm';
import cycleLoader, { cycleWithRootFormLoader } from './loaders/cycle';
import applicationLoader from './loaders/application';
import homeLoader from './loaders/home';
import reportsLoader from './loaders/reports';
import usersLoader from './loaders/users';
import userLoader from './loaders/user';
import formsLoader, { formLoader, newFormLoader } from './loaders/forms';
import printLoader from './loaders/print';
import chatsLoader from './loaders/chats';
import chatLoader from './loaders/chat';
import commentsLoader from './loaders/comments';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <CheckMaintenance>
        <div style={{ height: 'auto', minHeight: '100vh', width: '100%' }}>
          <Landing />
        </div>
      </CheckMaintenance>
    ),
    errorElement: <AppError />,
  },
  {
    path: ROUTES.ADMIN,
    element: (
      <Authenticator.Provider>
        <AdminLayout />
      </Authenticator.Provider>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.ADMIN_HOME} />,
      },
      {
        path: ROUTES.ADMIN_HOME,
        element: <AdminHomePage />,
      },
      {
        path: ROUTES.ADMIN_FORMS,
        children: [
          {
            index: true,
            element: <AdminFormsPage />,
            loader: formsLoader,
          },
          {
            path: ROUTES.ADMIN_FORMS_NEW,
            element: <AdminNewFormPage />,
            loader: newFormLoader,
          },
          {
            path: ROUTES.ADMIN_FORMS_EDIT,
            element: <AdminEditFormPage />,
            loader: formLoader,
            errorElement: <AdminEditFormError />,
          },
        ],
      },
      {
        path: ROUTES.ADMIN_PROFILE,
        element: <AdminProfilePage />,
      },
    ],
  },
  {
    path: ROUTES.HABITAT,
    element: (
      <Authenticator.Provider>
        <HabitatLayout />
      </Authenticator.Provider>
    ),
    loader: habitatLoader,
    shouldRevalidate: () => false,
    errorElement: <HabitatError />,
    children: [
      {
        path: ROUTES.HABITAT_APPLICANT,
        element: <ApplicantLayout />,
        children: [
          {
            path: ROUTES.HABITAT_APPLICANT_APPLICATIONS,
            element: <ApplicantApplicationsPage />,
          },
          {
            path: ROUTES.HABITAT_APPLICANT_DECISIONS,
            element: <ApplicantDecisionsPage />,
          },
          {
            path: ROUTES.HABITAT_APPLICANT_CYCLE,
            loader: cycleWithRootFormLoader,
            errorElement: <CycleLoaderError />,
            element: <ApplicantCyclePage />,
          },
          {
            path: ROUTES.HABITAT_APPLICANT_PROFILE,
            element: <ApplicantProfilePage />,
          },
          {
            path: ROUTES.HABITAT_APPLICANT_CHAT,
            element: <ApplicantChatPage />,
          },
        ],
      },
      {
        path: ROUTES.HABITAT_AFFILIATE,
        element: <AffiliateLayout />,
        children: [
          {
            path: ROUTES.HABITAT_AFFILIATE_HOME,
            loader: homeLoader,
            element: <AffiliateHomePage />,
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_FORMS,
            element: <AffiliateFormsPage />,
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_REPORTS,
            element: <AffiliateReportsPage />,
            loader: reportsLoader,
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_USERS,
            children: [
              {
                index: true,
                loader: usersLoader,
                element: <AffiliateUsersPage />,
              },
              {
                loader: userLoader,
                path: ROUTES.HABITAT_AFFILIATE_USERS_USER,
                element: <AffiliateUserDetails />,
                errorElement: <AffiliateUserDetailsError />,
              },
            ],
          },
          {
            path: ':formId',
            loader: rootFormLoader,
            errorElement: <RootFormError />,
            id: 'rootForm',
            children: [
              {
                index: true,
                element: <CyclesPage />,
              },
              {
                element: <AffiliateCommentsPage />,
                path: ROUTES.HABITAT_AFFILIATE_FORM_COMMENTS,
                loader: commentsLoader,
              },
              {
                path: ROUTES.HABITAT_AFFILIATE_CYCLES_CYCLE,
                id: 'cycle',
                loader: cycleLoader,
                errorElement: <CycleLoaderError />,
                children: [
                  {
                    index: true,
                    element: <AffiliateCycleApplications />,
                  },
                  {
                    path: ROUTES.HABITAT_AFFILIATE_CYCLES_CYCLE_TEST_APPLICATION,
                    element: <AffiliateTestApplication />,
                  },
                  {
                    path: ROUTES.HABITAT_AFFILIATE_CYCLES_CYCLE_APPLICATION,
                    loader: applicationLoader,
                    errorElement: <AffiliateApplicationDetailsPageError />,
                    element: <AffiliateApplicationDetailsPage />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_SETTINGS,
            element: <AffiliateSettingsPage />,
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_PROFILE,
            element: <AffiliateProfilePage />,
          },
          {
            path: ROUTES.HABITAT_AFFILIATE_CHATS,
            children: [
              {
                index: true,
                loader: chatsLoader,
                element: <AffiliateChatsPage />,
              },
              {
                path: ROUTES.HABITAT_AFFILIATE_CHAT,
                loader: chatLoader,
                element: <AffiliateChatPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.maintenance,
    element: <MaintenancePage />,
  },
  {
    path: ROUTES.PRINT,
    element: (
      <Authenticator.Provider>
        <Print />
      </Authenticator.Provider>
    ),
    loader: printLoader,
  },
]);

export default router;
