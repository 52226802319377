import React, { useEffect, useRef, useState } from 'react';
import {
  Loader,
  useAuthenticator,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import useHabitat from 'hooks/utils/useHabitat';
import { newChat, queryAllChats, queryChat } from 'services/graphql/Chat';
import { Chat } from 'API';
import ChatComponent from 'components/Chat';
import { useTranslation } from 'react-i18next';
import styles from './ApplicantChatPage.module.css';

const ApplicantChatPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { habitat } = useHabitat();
  const [chat, setChat] = useState<Chat>();
  const creatingNewChat = useRef(false);
  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });
  const { t } = useTranslation();

  useEffect(() => {
    const getChat = async () => {
      if (user && habitat && !creatingNewChat.current) {
        creatingNewChat.current = true;
        const chats = await queryAllChats({
          filter: {
            and: [
              { habitatID: { eq: habitat?.id } },
              { owner: { eq: user.username } },
            ],
          },
        });

        if (chats.length === 0) {
          const chat = await newChat({
            habitatID: habitat.id,
            owner: user.username,
          });

          setChat(chat);
        } else {
          const chat = await queryChat(chats[0].id);
          if (chat) {
            setChat(chat);
          }
        }
      }
    };

    getChat();
  }, [habitat, user]);

  return (
    <div className={styles.page}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        {t('pages.habitat.applicant.chat.title')}
      </h3>
      <div className={`${styles.chat}`}>
        {habitat && user && chat ? (
          <ChatComponent
            habitat={habitat}
            chat={chat}
            currentUser={user.username}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ApplicantChatPage;
