/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const customCreateEditComment =
  /* GraphQL */ `mutation CreateEditComment(
  $input: CreateEditCommentInput!
  $condition: ModelEditCommentConditionInput
) {
  createEditComment(input: $input, condition: $condition) {
    id
    rootformID
    content
    screenshots
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEditCommentMutationVariables,
    APITypes.CreateEditCommentMutation
  >;
export const customUpdateEditComment =
  /* GraphQL */ `mutation UpdateEditComment(
  $input: UpdateEditCommentInput!
  $condition: ModelEditCommentConditionInput
) {
  updateEditComment(input: $input, condition: $condition) {
    id
    rootformID
    content
    screenshots
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEditCommentMutationVariables,
    APITypes.UpdateEditCommentMutation
  >;
export const customDeleteEditComment =
  /* GraphQL */ `mutation DeleteEditComment(
  $input: DeleteEditCommentInput!
  $condition: ModelEditCommentConditionInput
) {
  deleteEditComment(input: $input, condition: $condition) {
    id
    rootformID
    content
    screenshots
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEditCommentMutationVariables,
    APITypes.DeleteEditCommentMutation
  >;
export const customCreateLastRead = /* GraphQL */ `mutation CreateLastRead(
  $input: CreateLastReadInput!
  $condition: ModelLastReadConditionInput
) {
  createLastRead(input: $input, condition: $condition) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLastReadMutationVariables,
  APITypes.CreateLastReadMutation
>;
export const customUpdateLastRead = /* GraphQL */ `mutation UpdateLastRead(
  $input: UpdateLastReadInput!
  $condition: ModelLastReadConditionInput
) {
  updateLastRead(input: $input, condition: $condition) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLastReadMutationVariables,
  APITypes.UpdateLastReadMutation
>;
export const customDeleteLastRead = /* GraphQL */ `mutation DeleteLastRead(
  $input: DeleteLastReadInput!
  $condition: ModelLastReadConditionInput
) {
  deleteLastRead(input: $input, condition: $condition) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLastReadMutationVariables,
  APITypes.DeleteLastReadMutation
>;
export const customCreateMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const customUpdateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const customDeleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const customCreateChat = /* GraphQL */ `mutation CreateChat(
  $input: CreateChatInput!
  $condition: ModelChatConditionInput
) {
  createChat(input: $input, condition: $condition) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatMutationVariables,
  APITypes.CreateChatMutation
>;
export const customUpdateChat = /* GraphQL */ `mutation UpdateChat(
  $input: UpdateChatInput!
  $condition: ModelChatConditionInput
) {
  updateChat(input: $input, condition: $condition) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatMutationVariables,
  APITypes.UpdateChatMutation
>;
export const customDeleteChat = /* GraphQL */ `mutation DeleteChat(
  $input: DeleteChatInput!
  $condition: ModelChatConditionInput
) {
  deleteChat(input: $input, condition: $condition) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatMutationVariables,
  APITypes.DeleteChatMutation
>;
export const customCreateFormTranslation =
  /* GraphQL */ `mutation CreateFormTranslation(
  $input: CreateFormTranslationInput!
  $condition: ModelFormTranslationConditionInput
) {
  createFormTranslation(input: $input, condition: $condition) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateFormTranslationMutationVariables,
    APITypes.CreateFormTranslationMutation
  >;
export const customUpdateFormTranslation =
  /* GraphQL */ `mutation UpdateFormTranslation(
  $input: UpdateFormTranslationInput!
  $condition: ModelFormTranslationConditionInput
) {
  updateFormTranslation(input: $input, condition: $condition) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateFormTranslationMutationVariables,
    APITypes.UpdateFormTranslationMutation
  >;
export const customDeleteFormTranslation =
  /* GraphQL */ `mutation DeleteFormTranslation(
  $input: DeleteFormTranslationInput!
  $condition: ModelFormTranslationConditionInput
) {
  deleteFormTranslation(input: $input, condition: $condition) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteFormTranslationMutationVariables,
    APITypes.DeleteFormTranslationMutation
  >;
export const customCreateComponentSchema =
  /* GraphQL */ `mutation CreateComponentSchema(
  $input: CreateComponentSchemaInput!
  $condition: ModelComponentSchemaConditionInput
) {
  createComponentSchema(input: $input, condition: $condition) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateComponentSchemaMutationVariables,
    APITypes.CreateComponentSchemaMutation
  >;
export const customUpdateComponentSchema =
  /* GraphQL */ `mutation UpdateComponentSchema(
  $input: UpdateComponentSchemaInput!
  $condition: ModelComponentSchemaConditionInput
) {
  updateComponentSchema(input: $input, condition: $condition) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateComponentSchemaMutationVariables,
    APITypes.UpdateComponentSchemaMutation
  >;
export const customDeleteComponentSchema =
  /* GraphQL */ `mutation DeleteComponentSchema(
  $input: DeleteComponentSchemaInput!
  $condition: ModelComponentSchemaConditionInput
) {
  deleteComponentSchema(input: $input, condition: $condition) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteComponentSchemaMutationVariables,
    APITypes.DeleteComponentSchemaMutation
  >;
export const customCreateFormSchema = /* GraphQL */ `mutation CreateFormSchema(
  $input: CreateFormSchemaInput!
  $condition: ModelFormSchemaConditionInput
) {
  createFormSchema(input: $input, condition: $condition) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormSchemaMutationVariables,
  APITypes.CreateFormSchemaMutation
>;
export const customUpdateFormSchema = /* GraphQL */ `mutation UpdateFormSchema(
  $input: UpdateFormSchemaInput!
  $condition: ModelFormSchemaConditionInput
) {
  updateFormSchema(input: $input, condition: $condition) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormSchemaMutationVariables,
  APITypes.UpdateFormSchemaMutation
>;
export const customDeleteFormSchema = /* GraphQL */ `mutation DeleteFormSchema(
  $input: DeleteFormSchemaInput!
  $condition: ModelFormSchemaConditionInput
) {
  deleteFormSchema(input: $input, condition: $condition) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormSchemaMutationVariables,
  APITypes.DeleteFormSchemaMutation
>;
export const customCreateReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const customUpdateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const customDeleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const customCreateMaintenance =
  /* GraphQL */ `mutation CreateMaintenance(
  $input: CreateMaintenanceInput!
  $condition: ModelMaintenanceConditionInput
) {
  createMaintenance(input: $input, condition: $condition) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateMaintenanceMutationVariables,
    APITypes.CreateMaintenanceMutation
  >;
export const customUpdateMaintenance =
  /* GraphQL */ `mutation UpdateMaintenance(
  $input: UpdateMaintenanceInput!
  $condition: ModelMaintenanceConditionInput
) {
  updateMaintenance(input: $input, condition: $condition) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateMaintenanceMutationVariables,
    APITypes.UpdateMaintenanceMutation
  >;
export const customDeleteMaintenance =
  /* GraphQL */ `mutation DeleteMaintenance(
  $input: DeleteMaintenanceInput!
  $condition: ModelMaintenanceConditionInput
) {
  deleteMaintenance(input: $input, condition: $condition) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteMaintenanceMutationVariables,
    APITypes.DeleteMaintenanceMutation
  >;
export const customCreateUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const customUpdateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const customDeleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const customCreateRootForm = /* GraphQL */ `mutation CreateRootForm(
  $input: CreateRootFormInput!
  $condition: ModelRootFormConditionInput
) {
  createRootForm(input: $input, condition: $condition) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRootFormMutationVariables,
  APITypes.CreateRootFormMutation
>;
export const customUpdateRootForm = /* GraphQL */ `mutation UpdateRootForm(
  $input: UpdateRootFormInput!
  $condition: ModelRootFormConditionInput
) {
  updateRootForm(input: $input, condition: $condition) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRootFormMutationVariables,
  APITypes.UpdateRootFormMutation
>;
export const customDeleteRootForm = /* GraphQL */ `mutation DeleteRootForm(
  $input: DeleteRootFormInput!
  $condition: ModelRootFormConditionInput
) {
  deleteRootForm(input: $input, condition: $condition) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRootFormMutationVariables,
  APITypes.DeleteRootFormMutation
>;
export const customCreateDecision = /* GraphQL */ `mutation CreateDecision(
  $input: CreateDecisionInput!
  $condition: ModelDecisionConditionInput
) {
  createDecision(input: $input, condition: $condition) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDecisionMutationVariables,
  APITypes.CreateDecisionMutation
>;
export const customUpdateDecision = /* GraphQL */ `mutation UpdateDecision(
  $input: UpdateDecisionInput!
  $condition: ModelDecisionConditionInput
) {
  updateDecision(input: $input, condition: $condition) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDecisionMutationVariables,
  APITypes.UpdateDecisionMutation
>;
export const customDeleteDecision = /* GraphQL */ `mutation DeleteDecision(
  $input: DeleteDecisionInput!
  $condition: ModelDecisionConditionInput
) {
  deleteDecision(input: $input, condition: $condition) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDecisionMutationVariables,
  APITypes.DeleteDecisionMutation
>;
export const customCreateFormAnswer = /* GraphQL */ `mutation CreateFormAnswer(
  $input: CreateFormAnswerInput!
  $condition: ModelFormAnswerConditionInput
) {
  createFormAnswer(input: $input, condition: $condition) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormAnswerMutationVariables,
  APITypes.CreateFormAnswerMutation
>;
export const customUpdateFormAnswer = /* GraphQL */ `mutation UpdateFormAnswer(
  $input: UpdateFormAnswerInput!
  $condition: ModelFormAnswerConditionInput
) {
  updateFormAnswer(input: $input, condition: $condition) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormAnswerMutationVariables,
  APITypes.UpdateFormAnswerMutation
>;
export const customDeleteFormAnswer = /* GraphQL */ `mutation DeleteFormAnswer(
  $input: DeleteFormAnswerInput!
  $condition: ModelFormAnswerConditionInput
) {
  deleteFormAnswer(input: $input, condition: $condition) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormAnswerMutationVariables,
  APITypes.DeleteFormAnswerMutation
>;
export const customCreateNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const customUpdateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const customDeleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
export const customCreateTestCycle = /* GraphQL */ `mutation CreateTestCycle(
  $input: CreateTestCycleInput!
  $condition: ModelTestCycleConditionInput
) {
  createTestCycle(input: $input, condition: $condition) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestCycleMutationVariables,
  APITypes.CreateTestCycleMutation
>;
export const customUpdateTestCycle = /* GraphQL */ `mutation UpdateTestCycle(
  $input: UpdateTestCycleInput!
  $condition: ModelTestCycleConditionInput
) {
  updateTestCycle(input: $input, condition: $condition) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestCycleMutationVariables,
  APITypes.UpdateTestCycleMutation
>;
export const customDeleteTestCycle = /* GraphQL */ `mutation DeleteTestCycle(
  $input: DeleteTestCycleInput!
  $condition: ModelTestCycleConditionInput
) {
  deleteTestCycle(input: $input, condition: $condition) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestCycleMutationVariables,
  APITypes.DeleteTestCycleMutation
>;
export const customCreateTestApplication =
  /* GraphQL */ `mutation CreateTestApplication(
  $input: CreateTestApplicationInput!
  $condition: ModelTestApplicationConditionInput
) {
  createTestApplication(input: $input, condition: $condition) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTestApplicationMutationVariables,
    APITypes.CreateTestApplicationMutation
  >;
export const customUpdateTestApplication =
  /* GraphQL */ `mutation UpdateTestApplication(
  $input: UpdateTestApplicationInput!
  $condition: ModelTestApplicationConditionInput
) {
  updateTestApplication(input: $input, condition: $condition) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTestApplicationMutationVariables,
    APITypes.UpdateTestApplicationMutation
  >;
export const customDeleteTestApplication =
  /* GraphQL */ `mutation DeleteTestApplication(
  $input: DeleteTestApplicationInput!
  $condition: ModelTestApplicationConditionInput
) {
  deleteTestApplication(input: $input, condition: $condition) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTestApplicationMutationVariables,
    APITypes.DeleteTestApplicationMutation
  >;
export const customCreateHabitat = /* GraphQL */ `mutation CreateHabitat(
  $input: CreateHabitatInput!
  $condition: ModelHabitatConditionInput
) {
  createHabitat(input: $input, condition: $condition) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHabitatMutationVariables,
  APITypes.CreateHabitatMutation
>;
export const customUpdateHabitat = /* GraphQL */ `mutation UpdateHabitat(
  $input: UpdateHabitatInput!
  $condition: ModelHabitatConditionInput
) {
  updateHabitat(input: $input, condition: $condition) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHabitatMutationVariables,
  APITypes.UpdateHabitatMutation
>;
export const customDeleteHabitat = /* GraphQL */ `mutation DeleteHabitat(
  $input: DeleteHabitatInput!
  $condition: ModelHabitatConditionInput
) {
  deleteHabitat(input: $input, condition: $condition) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHabitatMutationVariables,
  APITypes.DeleteHabitatMutation
>;
