import { CreateMessageInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customCreateMessage } from 'myGraphql/myMutations';
import { customGetMessage, customMessagesByChatID } from 'myGraphql/myQueries';

/**
 * Query all the Messages of a Chat.
 * @param chatId The Chat id.
 * @returns An array of Messages.
 */
export const queryMessagesByChat = async (chatId: string) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customMessagesByChatID,
      variables: {
        chatID: chatId,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying messages by chat.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.messagesByChatID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a Message.
 * @param id The Message id.
 * @returns The Message found or null or undefined.
 */
export const queryMessage = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetMessage,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying message.',
      response.errors
    );
  }

  return response.data.getMessage;
};

/**
 * Persists a new Message.
 * @param message The Message to persist.
 * @returns The persisted Message.
 */
export const newMessage = async (message: CreateMessageInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateMessage,
    variables: {
      input: message,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new message.',
      response.errors
    );
  }

  return response.data.createMessage;
};
