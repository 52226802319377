import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { Components } from 'formiojs';
import { downloadWithUrl } from 'utils/files';
import { isElement } from 'utils/type';

class CustomFile extends Components.components.file {
  __updateComponentOptions() {
    try {
      const originalOptions = JSON.parse(this.component.options || '{}');
      cognitoUserPoolsTokenProvider.getTokens().then((tokens) => {
        const newOptions = {
          ...originalOptions,
          headers: {
            ...originalOptions.headers,
            HFHAuthentication: `${tokens?.idToken?.toString()}`,
            'Access-Control-Allow-Origin': '*',
          },
        };

        this.component.options = JSON.stringify(newOptions);
      });
    } catch (error) {
      console.error('Invalid options.');
    }
  }

  attach(element: unknown) {
    this.__updateComponentOptions();
    if (isElement(element)) {
      const anchors = element.querySelectorAll('a:not(.browse)');

      for (const anchor of anchors) {
        if (anchor instanceof HTMLAnchorElement) {
          const newAElement = document.createElement('a');
          newAElement.onclick = async (event) => {
            event.preventDefault();
            const originalOptions = JSON.parse(this.component.options || '{}');
            const response = await fetch(anchor.href, {
              ...originalOptions,
            });
            if (response.ok) {
              downloadWithUrl(response.url, 'file');
            }
          };
          newAElement.href = '#';
          newAElement.innerHTML = anchor.innerHTML;
          anchor.replaceWith(newAElement);
        }
      }
    }

    return super.attach(element);
  }

  browseFiles(attrs?: object): Promise<unknown> {
    this.__updateComponentOptions();
    return super.browseFiles(attrs);
  }
}

export default CustomFile;
