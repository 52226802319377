import { useCallback, useState } from 'react';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import IconButton from 'components/IconButton';
import { MdOutlineChatBubble } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';
import styles from './BaseLayout.module.css';
import BaseLayoutProps from './BaseLayout.types';

const BaseLayout = ({ variation, children, hideSideBar }: BaseLayoutProps) => {
  const [expand, setExpand] = useState(false);

  const { pathname } = useLocation();

  const base = useBreakpointValue({
    base: true,
    medium: false,
  }) as boolean;

  const mobile = base;

  const handleOnExpand = useCallback(() => {
    if (base) {
      setExpand((prevExpandSideBar) => !prevExpandSideBar);
    }
  }, [base]);

  return (
    <div className={styles.layout}>
      {!hideSideBar && (
        <SideBar
          mobile={mobile}
          expanded={expand}
          onExpand={handleOnExpand}
          variation={variation}
        />
      )}
      <div className={styles.rightSide}>
        <TopBar mobile={mobile} onExpand={handleOnExpand} />
        <div className={styles.content}>{children}</div>
      </div>
      {variation === 'applicant' && !pathname.endsWith('chat') && (
        <Link to={ROUTES.HABITAT_APPLICANT_CHAT}>
          <IconButton
            variation="not-outlined"
            className={`${styles.chatButton}`}
          >
            <MdOutlineChatBubble />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

export default BaseLayout;
