import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chat as ChatType, Habitat, LastRead, User } from 'API';
import { queryAllUsers } from 'services/graphql/User';
import LexicalEditor from 'components/LexicalEditor';
import IconButton from 'components/IconButton';
import { MdOutlineArrowDownward, MdOutlineSend } from 'react-icons/md';
import { EditorState } from 'lexical';
import { ICustomMessage } from 'types';
import { v4 } from 'uuid';
import { newMessage, queryMessagesByChat } from 'services/graphql/Message';
import { generateClient } from 'aws-amplify/api';
import { customOnCreateMessage } from 'myGraphql/mySubscriptions';
import { uploadData } from 'aws-amplify/storage';
import { getEditorStateWithFilesInBucket } from 'utils/lexicalEditor';
import { scrollIsAtBottom } from 'utils/scroll';
import notification from 'assets/sounds/760369__froey__message-receive.wav';
import {
  newLastRead,
  queryLastReadsByChat,
  updateLastRead,
} from 'services/graphql/LastRead';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import MessageComponent from './components/Message';
import styles from './Chat.module.css';

interface IChat {
  chat: ChatType;
  currentUser: string;
  habitat: Habitat;
}

const Chat = ({ habitat, chat, currentUser }: IChat) => {
  const [users, setUsers] = useState<User[]>([]);

  const [lastRead, setLastRead] = useState<LastRead>();

  const messagesContainer = useRef<HTMLDivElement>(null);

  const messagesSubContainer = useRef<HTMLDivElement>(null);

  const [scrolling, setScrolling] = useState(false);

  const [resetEditor, setResetEditor] = useState(0);

  const [editorState, setEditorState] = useState<EditorState>();

  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  const [messages, setMessages] = useState<ICustomMessage[]>([]);

  const isPhone = useBreakpointValue({
    base: true,
    medium: false,
  }) as boolean;

  const getUserName = (owner: string) => {
    const foundUser = users.find((user) => user.owner === owner);

    if (foundUser) {
      return `${foundUser.firstName} ${foundUser.lastName}`;
    }
  };

  const uploadChatFile = async (file: File) => {
    const result = await uploadData({
      path: `public/chats/${habitat?.urlName}/${chat.id}/${v4()}_${file.name}`,
      data: file,
    }).result;

    return result;
  };

  const handleSendMessage = async (content: EditorState) => {
    if (chat && currentUser) {
      const temporaryId = v4();
      try {
        const currentDateTime = new Date().toISOString();

        const messageToPersist = {
          content: JSON.stringify(content.toJSON()),
          chatID: chat.id,
          owner: currentUser,
        };

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...messageToPersist,
            id: temporaryId,
            sending: true,
            __typename: 'Message',
            createdAt: currentDateTime,
            updatedAt: currentDateTime,
          },
        ]);

        const editorStateWithFilesInS3 = await getEditorStateWithFilesInBucket(
          content.toJSON(),
          uploadChatFile
        );

        const persistedMessage = await newMessage({
          ...messageToPersist,
          content: JSON.stringify(editorStateWithFilesInS3),
        });

        setMessages((prevMessages) => [
          ...prevMessages.filter(
            (prevMessage) => prevMessage.id !== temporaryId
          ),
          persistedMessage,
        ]);
      } catch (error) {
        setMessages((prevMessages) => {
          const prevMessagesCopy = [...prevMessages];

          const messageIndex = prevMessagesCopy.findIndex(
            (prevMessage) => prevMessage.id === temporaryId
          );

          prevMessagesCopy[messageIndex] = {
            ...prevMessagesCopy[messageIndex],
            sending: false,
            error: true,
          };

          return prevMessagesCopy;
        });

        console.error('Error sending message.');
      }
    }
  };

  useEffect(() => {
    const getMessagesAndLastRead = async () => {
      if (chat) {
        const messages = await queryMessagesByChat(chat.id);

        const lastReads = await queryLastReadsByChat(chat.id);

        if (messages.length > 0) {
          const persistedLastRead = lastReads.find(
            (lastRead) => lastRead.owner === currentUser
          );

          setLastRead(persistedLastRead);
        }

        setMessages(messages);
      }
    };

    getMessagesAndLastRead();

    const client = generateClient();

    const onCreateMessageSubscription = client
      .graphql({
        query: customOnCreateMessage,
        variables: {
          filter: {
            chatID: { eq: chat.id },
            owner: { ne: currentUser },
          },
        },
      })
      .subscribe({
        next: ({ data }) => {
          const audio = new Audio(notification);
          audio.play();
          setMessages((prevMessages) => [
            ...prevMessages,
            data.onCreateMessage,
          ]);
        },
        error: (error) => console.warn(error),
      });

    return () => onCreateMessageSubscription.unsubscribe();
  }, [chat, currentUser]);

  useEffect(() => {
    const getUsersData = async () => {
      const messagesUsersSubs = [
        ...new Set(messages.map((message) => message.owner)),
      ];

      const usersSubs = users?.map((user) => user.owner);

      for (const messageUserSub of messagesUsersSubs) {
        if (!usersSubs.includes(messageUserSub)) {
          const userData = await queryAllUsers({
            filter: {
              owner: { eq: messageUserSub },
            },
          });

          if (userData.length > 0) {
            setUsers((prevUsers) => [...prevUsers, userData[0]]);
          }
        }
      }
    };

    getUsersData();
  }, [messages, users]);

  const scrollToBottom = useCallback(
    (ignoreScrolling = false) => {
      if (messagesContainer.current && (ignoreScrolling || !scrolling)) {
        messagesContainer.current.scrollTo(
          0,
          messagesContainer.current.scrollHeight +
            messagesContainer.current.offsetHeight
        );

        setScrolling(false);
      }
    },
    [scrolling]
  );

  useEffect(() => {
    if (messagesContainer.current) {
      const resizeObserver = new ResizeObserver(() => {
        scrollToBottom();
      });

      resizeObserver.observe(messagesContainer.current);

      return () => resizeObserver.disconnect();
    }
  }, [scrollToBottom]);

  useEffect(() => {
    if (messagesSubContainer.current) {
      const resizeObserver = new ResizeObserver(() => {
        scrollToBottom();
      });

      resizeObserver.observe(messagesSubContainer.current);

      return () => resizeObserver.disconnect();
    }
  }, [scrollToBottom]);

  useEffect(() => {
    setTimeout(scrollToBottom, 10);
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const updateMyLastRead = async () => {
      if (messages.length > 0) {
        const sortedMessages = messages.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return -1;
          }

          if (a.createdAt > b.createdAt) {
            return 1;
          }

          return 0;
        });

        const lastMessage = sortedMessages.at(-1);

        if (lastMessage && !lastMessage.sending) {
          if (lastRead) {
            if (lastMessage?.id !== lastRead.lastMessage) {
              await updateLastRead({
                id: lastRead.id,
                lastMessage: lastMessage?.id,
              });
            }
          } else {
            const newlyLastRead = await newLastRead({
              lastMessage: lastMessage.id,
              chatID: chat.id,
              owner: currentUser,
            });

            setLastRead(newlyLastRead);
          }
        }
      }
    };

    updateMyLastRead();
  }, [messages, lastRead, chat, currentUser]);

  return (
    <div className={styles.chat}>
      <div
        className={styles.messagesContainer}
        ref={messagesContainer}
        onScroll={(event) => {
          setScrolling(!scrollIsAtBottom(event.currentTarget));
        }}
      >
        <div className={styles.messagesSubContainer} ref={messagesSubContainer}>
          {messages
            .sort((a, b) => {
              if (a.createdAt < b.createdAt) {
                return -1;
              }

              if (a.createdAt > b.createdAt) {
                return 1;
              }

              return 0;
            })
            .map((message) => (
              <MessageComponent
                key={message.id}
                chat={chat}
                message={message}
                userName={getUserName(message.owner)}
                currentUser={currentUser}
              />
            ))}
        </div>
        {scrolling && (
          <IconButton
            onClick={() => scrollToBottom(true)}
            className={`${styles.scrollBottom}`}
          >
            <MdOutlineArrowDownward />
          </IconButton>
        )}
      </div>
      <div className={styles.editorAndSendButton}>
        <div className={styles.editor}>
          <LexicalEditor
            key={resetEditor}
            editable
            minimalistDesign
            onChange={(newEditorState) => {
              setEditorState(newEditorState);
            }}
            onEmptyChange={setIsEditorEmpty}
            compact
            disableTextAlignments={isPhone}
            disableTextDecorations={isPhone}
            disableUndoRedo={isPhone}
          />
        </div>
        <IconButton
          className={styles.sendButton}
          variation="not-outlined"
          onClick={() => {
            if (editorState) {
              handleSendMessage(editorState);
            }
            setEditorState(undefined);
            setResetEditor((prevResetEditor) => prevResetEditor + 1);
          }}
          disabled={isEditorEmpty}
        >
          <MdOutlineSend />
        </IconButton>
      </div>
    </div>
  );
};

export default Chat;
