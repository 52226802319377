import React, { Suspense } from 'react';
import { Form } from '@formio/react';
import { Await, useRouteLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHabitat from 'hooks/utils/useHabitat';
import { Options } from '@formio/react/lib/components/Form';
import { TCycleData } from 'router/loaders/cycle';
import { Loader } from '@aws-amplify/ui-react';
import { convertTranlationsToObject } from 'utils/translations';
import style from './AffiliateApplicationTest.module.css';

const AffiliateTestApplication = () => {
  const { cycleData } = useRouteLoaderData('cycle') as {
    cycleData: Promise<TCycleData>;
  };

  const { i18n } = useTranslation();

  const { language } = i18n;

  const { habitat } = useHabitat();

  return (
    <Suspense fallback={<Loader size="large" />}>
      <Await resolve={cycleData}>
        {({ cycle, formSchema, translations }: TCycleData) => {
          const options = {
            additional: {
              habitat,
              openCycle: cycle,
            },
            language: translations.length === 0 ? 'en' : language,
            i18n: convertTranlationsToObject(translations),
            flatten: true,
          } as Options;
          return (
            <div
              className={`${style.formContainer}`}
              style={{ padding: '2rem 1rem' }}
            >
              <Form
                key={`real-${language}`}
                form={formSchema}
                options={options}
              />
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default AffiliateTestApplication;
