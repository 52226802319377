import React, { useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import Modal from 'components/Modal';
import { TextField } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import styles from './EmailsField.module.css';
import { emailsSchema, TEmailsSchema } from './EmailsField.schemas';

interface IEmailField {
  emails: string[];
  onChange: (newEmails: string[]) => void;
}

const EmailsField = ({ emails, onChange }: IEmailField) => {
  const [newModal, setNewModal] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState<string>();

  const { t } = useTranslation();

  const {
    register: registerNew,
    handleSubmit: handleSubmitNew,
    formState: { errors: errorsNew },
    reset: resetNew,
  } = useForm({
    resolver: zodResolver(emailsSchema),
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      email: '',
    },
  });

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    formState: { errors: errorsEdit },
    reset: resetEdit,
  } = useForm<TEmailsSchema>({
    resolver: zodResolver(emailsSchema),
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      email: '',
    },
  });

  const handleSelectEmail = (emailSelected: string) => {
    setSelectedEmail(emailSelected);
  };

  const handleClickAddEmail = () => setNewModal(true);

  const handleValidAddEmail = (data: TEmailsSchema) => {
    onChange([...emails, data.email]);
    resetNew();
    setNewModal(false);
  };

  const handleValidEditEmail = (data: TEmailsSchema) => {
    onChange(
      emails.map((email) => (email === selectedEmail ? data.email : email))
    );
    resetEdit();
    setSelectedEmail(undefined);
  };

  const handleDeleteStatus = () => {
    onChange(emails.filter((email) => email !== selectedEmail));
    resetEdit();
    setSelectedEmail(undefined);
  };

  useEffect(() => {
    resetEdit({
      email: selectedEmail,
    });
  }, [selectedEmail, resetEdit]);

  const modalWidth = '512px';

  return (
    <div className={`${styles.container}`}>
      {emails.map((email) => (
        <button
          key={email}
          type="button"
          className={`${styles.chip}`}
          onClick={() => handleSelectEmail(email)}
        >
          {email}
        </button>
      ))}
      <Modal
        title={t(
          'pages.habitat.affiliate.settings.components.EmailsField.newTitle'
        )}
        open={newModal}
        onClickClose={() => setNewModal(false)}
        width={modalWidth}
      >
        <form onSubmit={handleSubmitNew(handleValidAddEmail)}>
          <div>
            <TextField
              label={t(
                'pages.habitat.affiliate.settings.components.EmailsField.label'
              )}
              {...registerNew('email')}
              errorMessage={t(
                'pages.habitat.affiliate.settings.components.EmailsField.errorMessage'
              )}
              hasError={Boolean(errorsNew.email)}
            />
          </div>
          <div className={`${styles.modalButtons}`}>
            <CustomButton variation="primary" type="submit">
              {t('pages.habitat.affiliate.settings.components.EmailsField.add')}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={() => setNewModal(false)}
            >
              {t(
                'pages.habitat.affiliate.settings.components.EmailsField.cancel'
              )}
            </CustomButton>
          </div>
        </form>
      </Modal>
      <Modal
        title={t(
          'pages.habitat.affiliate.settings.components.EmailsField.editTitle'
        )}
        open={Boolean(selectedEmail)}
        onClickClose={() => setSelectedEmail(undefined)}
        width={modalWidth}
      >
        <form onSubmit={handleSubmitEdit(handleValidEditEmail)}>
          <div>
            <TextField
              label={t(
                'pages.habitat.affiliate.settings.components.EmailsField.label'
              )}
              errorMessage={t(
                'pages.habitat.affiliate.settings.components.EmailsField.errorMessage'
              )}
              hasError={Boolean(errorsEdit.email)}
              defaultValue={selectedEmail}
              {...registerEdit('email')}
            />
          </div>
          <div className={`${styles.modalButtons}`}>
            <CustomButton variation="primary" type="submit">
              {t(
                'pages.habitat.affiliate.settings.components.EmailsField.update'
              )}
            </CustomButton>
            <CustomButton variation="secondary" onClick={handleDeleteStatus}>
              {t(
                'pages.habitat.affiliate.settings.components.EmailsField.remove'
              )}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={() => setSelectedEmail(undefined)}
            >
              {t(
                'pages.habitat.affiliate.settings.components.EmailsField.cancel'
              )}
            </CustomButton>
          </div>
        </form>
      </Modal>
      <button
        type="button"
        className={`${styles.chip} ${styles.newChip}`}
        onClick={handleClickAddEmail}
      >
        <MdOutlineAdd />
        {t('pages.habitat.affiliate.settings.components.EmailsField.add')}
      </button>
    </div>
  );
};

export default EmailsField;
