import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

const RestoreHtmlPlugin = ({ htmlString }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (htmlString) {
      queueMicrotask(() => {
        editor.update(() => {
          const parser = new DOMParser();

          const dom = parser.parseFromString(htmlString, 'text/html');

          const nodes = $generateNodesFromDOM(editor, dom);

          $getRoot().clear();

          $getRoot().select();

          $insertNodes(nodes);
        });
      });
    }
  }, [editor, htmlString]);
};

RestoreHtmlPlugin.propTypes = {
  htmlString: PropTypes.string,
};

export default RestoreHtmlPlugin;
