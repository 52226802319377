import { PhoneNumberUtil } from 'google-libphonenumber';
import { z } from 'zod';

const phoneUtil = PhoneNumberUtil.getInstance();

export const profileSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z
    .object({
      countryCode: z.string(),
      nationalNumber: z.string().min(1),
    })
    .refine(
      (value) => {
        try {
          const phoneNumber = phoneUtil.parse(
            `${value.countryCode}${value.nationalNumber}`
          );

          console.log('phoneNumber', phoneNumber);

          return phoneUtil.isValidNumber(phoneNumber);
        } catch (error) {
          return false;
        }
      },
      {
        message:
          'Invalid phone number. Phone number should only consist of numbers, no spaces, letters or special characters',
      }
    ),
});

export type TProfileSchema = z.infer<typeof profileSchema>;
