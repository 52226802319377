import {
  ChatsByHabitatIDQueryVariables,
  CreateChatInput,
  ListChatsQueryVariables,
  UpdateChatInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customCreateChat, customUpdateChat } from 'myGraphql/myMutations';
import {
  customChatsByHabitatID,
  customGetChat,
  customListChats,
} from 'myGraphql/myQueries';

/**
 * Query all the Chats.
 * @param variables The variables passed to the query.
 * @returns An array of Chats.
 */
export const queryAllChats = async (
  variables: ListChatsQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListChats,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying chats.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listChats;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query all the Chats of a Habitat.
 * @param variables The variables passed to the query.
 * @returns An array of Chats.
 */
export const queryChatsByHabitat = async (
  variables: ChatsByHabitatIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customChatsByHabitatID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying chats by habitat.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.chatsByHabitatID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a Chat.
 * @param id The Chat id.
 * @returns The Chat found or null or undefined.
 */
export const queryChat = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetChat,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError('Error while querying chat.', response.errors);
  }

  return response.data.getChat;
};

/**
 * Persists a new Chat.
 * @param chat The Chat to persist.
 * @returns The persisted Chat.
 */
export const newChat = async (chat: CreateChatInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateChat,
    variables: {
      input: chat,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new chat.',
      response.errors
    );
  }

  return response.data.createChat;
};

/**
 * Updates a Chat.
 * @param input The Chat with changes.
 * @returns The updated Chat.
 */
export const updateChat = async (input: UpdateChatInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateChat,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError('Error while updating chat.', response.errors);
  }

  return response.data.updateChat;
};
