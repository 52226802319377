/* eslint-disable react/style-prop-object */
import React, { FocusEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdAutoGraph,
  MdOutlineAssignmentTurnedIn,
  MdOutlineChat,
  MdOutlineClose,
  MdOutlineFeed,
  MdOutlineFolderCopy,
  MdOutlineHome,
  MdOutlinePeopleOutline,
  MdOutlineSettings,
} from 'react-icons/md';
import { Button, Flex } from '@aws-amplify/ui-react';
import useCloseContextMenu from 'hooks/utils/useCloseContextMenu';
import useRoutes, { isActive } from 'utils/routes';

import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import MenuItem from './components/MenuItem/MenuItem';
import style from './SideBar.module.css';
import HabitatHeader from './components/HabitatHeader';

interface IProperties {
  mobile: boolean;
  expanded: boolean;
  onExpand: () => void;
  variation: 'applicant' | 'affiliate' | 'admin';
}

const SideBar = ({
  mobile,
  expanded,
  onExpand,

  variation,
}: IProperties) => {
  const sideBarRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const routes = useRoutes(t);

  const [isHovered, setIsHovered] = useState(false);

  useCloseContextMenu(sideBarRef, onExpand);

  const isHoveredOrExpanded = isHovered || (mobile && expanded);

  const handleHover = (
    event: FocusEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();

    setIsHovered(true);
  };

  const handleLeave = (
    event: FocusEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();

    setIsHovered(false);
  };

  if (!mobile || (mobile && expanded)) {
    return (
      <div
        ref={sideBarRef}
        className={`${style.sideBar} ${
          isHoveredOrExpanded ? style.expanded : style.collapsed
        }`}
        style={{ position: mobile ? 'absolute' : undefined }}
        onFocus={handleHover}
        onBlur={handleLeave}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <Flex
          justifyContent="space-between"
          direction="column"
          minHeight="100%"
          position="relative"
        >
          {mobile && expanded && (
            <Button
              position="absolute"
              top="0"
              right="0"
              variation="destructive"
              padding="6px"
              onClick={onExpand}
            >
              <MdOutlineClose size="24px" />
            </Button>
          )}
          <Flex direction="column" gap="16px" justifyContent="space-between">
            <HabitatHeader />
            {variation === 'affiliate' && (
              <>
                <MenuItem
                  to="home"
                  icon={<MdOutlineHome />}
                  label={routes.affiliateHome.title}
                  active={isActive(pathname, routes.affiliateHome.route)}
                  expanded={isHoveredOrExpanded}
                />
                <MenuItem
                  to="forms"
                  icon={<MdOutlineFeed />}
                  label={routes.affiliateForms.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.affiliateForms.route)}
                />
                <MenuItem
                  to="reports"
                  icon={<MdAutoGraph />}
                  label={routes.affiliateReports.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.affiliateReports.route)}
                />
                <MenuItem
                  to="users"
                  icon={<MdOutlinePeopleOutline />}
                  label={routes.affiliateUsers.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.affiliateUsers.route)}
                />
                <MenuItem
                  to={ROUTES.HABITAT_AFFILIATE_CHATS}
                  icon={<MdOutlineChat />}
                  label={routes.affiliateChats.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.affiliateChats.route)}
                />
              </>
            )}
            {variation === 'applicant' && (
              <>
                <MenuItem
                  to="applications"
                  icon={<MdOutlineFolderCopy />}
                  label={routes.applicantApplications.title}
                  active={isActive(
                    pathname,
                    routes.applicantApplications.route
                  )}
                  expanded={isHoveredOrExpanded}
                />
                <MenuItem
                  to="decisions"
                  icon={<MdOutlineAssignmentTurnedIn />}
                  label={routes.applicantDecisions.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.applicantDecisions.route)}
                />
                <MenuItem
                  to={ROUTES.HABITAT_APPLICANT_CHAT}
                  icon={<MdOutlineChat />}
                  label={routes.applicantChat.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.applicantChat.route)}
                />
              </>
            )}
            {variation === 'admin' && (
              <>
                <MenuItem
                  to="home"
                  icon={<MdOutlineHome />}
                  label={routes.adminHome.title}
                  active={isActive(pathname, routes.adminHome.route)}
                  expanded={isHoveredOrExpanded}
                />
                <MenuItem
                  to="forms"
                  icon={<MdOutlineFeed />}
                  label={routes.adminForms.title}
                  expanded={isHoveredOrExpanded}
                  active={isActive(pathname, routes.adminForms.route)}
                />
              </>
            )}
          </Flex>
          {variation === 'affiliate' && (
            <Flex direction="column" gap="16px">
              <MenuItem
                to={ROUTES.HABITAT_AFFILIATE_SETTINGS}
                icon={<MdOutlineSettings />}
                label={routes.settings.title}
                expanded={isHoveredOrExpanded}
              />
            </Flex>
          )}
        </Flex>
      </div>
    );
  }
};

export default SideBar;
