import React, { useEffect, useState } from 'react';
import {
  Divider,
  Loader,
  TextField,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import LexicalEditor from 'components/LexicalEditor';
import { GalleryItem, HabitatProps } from 'API';
import useHabitat from 'hooks/utils/useHabitat';
import CustomButton from 'components/CustomButton';
import Modal from 'components/Modal';
import { updateHabitat } from 'services/graphql/Habitat';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { v4 } from 'uuid';
import { formatS3Url } from 'amazon-s3-url';
import styles from './AffiliateSettingsPage.module.css';
import StatusesField from './components/StatusesField';
import GalleryField from './components/GalleryField';
import EmailsField from './components/EmailsField';

export type TGalleryItemWithFiles =
  | Omit<GalleryItem, 'image'> & {
      image?: string | File | null;
    };

interface ISettings {
  sidebarText: string;
  sidebarTextSize: number;
  closedCycleMessage: string;
  customStatuses: HabitatProps['customStatus'];
  authenticationHeader: string;
  gallery: TGalleryItemWithFiles[];
  notifySubmissionsEmails: HabitatProps['notifySubmissionsEmails'];
}

const AffiliateSettingsPage = () => {
  const { habitat, setHabitat } = useHabitat();

  const [confirmUpdate, setConfirmUpdate] = useState(false);

  const [updating, setUpdating] = useState(false);

  const [settings, setSettings] = useState<ISettings>({
    sidebarText: '',
    sidebarTextSize: 11,
    closedCycleMessage: '',
    customStatuses: [],
    authenticationHeader: '',
    gallery: [],
    notifySubmissionsEmails: [],
  });

  const { t } = useTranslation();

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const subtitleStyle = useBreakpointValue({
    base: 'theme-subtitle-s2',
    large: 'theme-subtitle-s1',
  });

  const handleUpdateSettings = async () => {
    try {
      setUpdating(true);
      if (habitat) {
        const { __typename, ...habitatPropsWithoutType } = habitat.props;

        String(__typename);

        const region = process.env.REACT_APP_PUBLIC_S3_REGION;

        const s3client = new S3Client({
          region,
          credentials: {
            accessKeyId: process.env.REACT_APP_PUBLIC_S3_IDKEY || '',
            secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRETKEY || '',
          },
        });

        const newGallery = settings.gallery;

        let index = 0;

        for (const newSlide of newGallery) {
          if (newSlide.image instanceof File) {
            const key = `habitat/${habitat.urlName}/gallery/${v4()}-${
              newSlide.image.name
            }`;

            const bucket = process.env.REACT_APP_PUBLIC_S3_BUCKET || '';
            const command = new PutObjectCommand({
              Bucket: bucket,
              Key: key,
              Body: newSlide.image,
            });

            await s3client.send(command);

            const url = formatS3Url(
              {
                bucket,
                region,
                key,
              },
              'https-region-virtual-host'
            );

            newGallery[index].image = url;
          }

          index++;
        }

        const updatedHabitat = await updateHabitat({
          id: habitat?.id,
          props: {
            ...habitatPropsWithoutType,
            sidebarName: {
              name: settings.sidebarText,
              fontSize: `${settings.sidebarTextSize}px`,
            },
            closedCycleMessages: [
              ...habitatPropsWithoutType.closedCycleMessages,
              settings.closedCycleMessage,
            ],
            customStatus: settings.customStatuses,
            gallery: newGallery.map((slide) => {
              const { __typename, ...newSlide } = slide;
              String(__typename);
              return newSlide;
            }) as GalleryItem[],
            notifySubmissionsEmails: settings.notifySubmissionsEmails,
          },
          authenticationHeader: settings.authenticationHeader,
        });

        setHabitat(updatedHabitat);
      }
    } catch (error) {
      console.error('Error while updating habitat settings.', error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    setSettings({
      sidebarText: habitat?.props.sidebarName?.name || '',
      sidebarTextSize: Number(
        habitat?.props.sidebarName?.fontSize?.replace('px', '') || 11
      ),
      closedCycleMessage:
        (habitat?.props.closedCycleMessages || ['']).at(-1) || '',
      customStatuses: habitat?.props.customStatus,
      authenticationHeader: habitat?.authenticationHeader || '',
      gallery: habitat?.props.gallery || [],
      notifySubmissionsEmails: habitat?.props.notifySubmissionsEmails || [],
    });
  }, [habitat]);

  return (
    <div className={styles.page}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        {t('pages.habitat.affiliate.settings.title')}
      </h3>
      <span className={`${subtitleStyle} ${styles.subtitle}`}>
        {t('pages.habitat.affiliate.settings.general.subtitle')}
      </span>
      <div className={`${styles.sectionContainer}`}>
        <div className={`${styles.sideTextContainer}`}>
          <TextField
            label={t('pages.habitat.affiliate.settings.general.sidebarText')}
            width="512px"
            value={settings.sidebarText}
            onChange={(event) => {
              setSettings((prevSettings) => ({
                ...prevSettings,
                sidebarText: event.currentTarget.value,
              }));
            }}
          />
          <TextField
            label={t(
              'pages.habitat.affiliate.settings.general.sidebarTextSize'
            )}
            type="number"
            min={6}
            max={32}
            value={settings.sidebarTextSize}
            onChange={(event) => {
              setSettings((prevSettings) => ({
                ...prevSettings,
                sidebarTextSize: Number(event.currentTarget.value),
              }));
            }}
          />
        </div>
        <div>
          <span className={`${styles.label}`}>
            {t('pages.habitat.affiliate.settings.general.closedCycle')}
          </span>
          <LexicalEditor
            editable
            htmlString={settings.closedCycleMessage}
            onChangeHtml={(html) => {
              setSettings((prevSettings) => ({
                ...prevSettings,
                closedCycleMessage: html,
              }));
            }}
            disableFiles
            disableImages
          />
        </div>
        <div>
          <span className={`${styles.label}`}>
            {t('pages.habitat.affiliate.settings.general.customStatuses')}
          </span>
          <StatusesField
            statuses={settings.customStatuses || []}
            onChange={(newStatuses) =>
              setSettings((prevSettings) => ({
                ...prevSettings,
                customStatuses: newStatuses,
              }))
            }
          />
        </div>
        <div>
          <span className={`${styles.label}`}>
            {t(
              'pages.habitat.affiliate.settings.general.notifySubmissionsEmails'
            )}
          </span>
          <EmailsField
            emails={settings.notifySubmissionsEmails || []}
            onChange={(newEmails) =>
              setSettings((prevSettings) => ({
                ...prevSettings,
                notifySubmissionsEmails: newEmails,
              }))
            }
          />
        </div>
      </div>
      <div className={`${styles.dividerContainer}`}>
        <Divider />
      </div>
      <span className={`${subtitleStyle} ${styles.subtitle}`}>
        {t('pages.habitat.affiliate.settings.authentication.subtitle')}
      </span>
      <div className={`${styles.sectionContainer}`}>
        <div>
          <span className={`${styles.label}`}>
            {t(
              'pages.habitat.affiliate.settings.authentication.authenticationHeader'
            )}
          </span>
          <LexicalEditor
            editable
            htmlString={settings.authenticationHeader}
            onChangeHtml={(html) => {
              setSettings((prevSettings) => ({
                ...prevSettings,
                authenticationHeader: html,
              }));
            }}
            disableFiles
            disableImages
          />
        </div>
        <div>
          <span className={`${styles.label}`}>
            {t('pages.habitat.affiliate.settings.authentication.gallery')}
          </span>
          <GalleryField
            gallery={settings.gallery}
            onChange={(newGallery) => {
              setSettings((prevSettings) => ({
                ...prevSettings,
                gallery: newGallery,
              }));
            }}
          />
        </div>
        <Modal
          title={t('pages.habitat.affiliate.settings.confirmModal.title')}
          open={confirmUpdate}
          onClickClose={() => setConfirmUpdate(false)}
          width="448px"
        >
          <p>{t('pages.habitat.affiliate.settings.confirmModal.message')}</p>
          <div className={`${styles.modalButtons}`}>
            <CustomButton
              icon={updating && <Loader />}
              disabled={updating}
              onClick={handleUpdateSettings}
            >
              {t('pages.habitat.affiliate.settings.confirmModal.yes')}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={() => setConfirmUpdate(false)}
              disabled={updating}
            >
              {t('pages.habitat.affiliate.settings.confirmModal.cancel')}
            </CustomButton>
          </div>
        </Modal>
        <CustomButton
          className={`${styles.updateButton}`}
          onClick={() => {
            setConfirmUpdate(true);
          }}
        >
          {t('pages.habitat.affiliate.settings.update')}
        </CustomButton>
      </div>
    </div>
  );
};

export default AffiliateSettingsPage;
