import { View, ViewProps } from '@aws-amplify/ui-react';
import { forwardRef, LegacyRef } from 'react';
import styles from './CustomCard.module.css';

interface ICustomCardProps extends ViewProps {
  disableShadow?: boolean;
}

// eslint-disable-next-line react/display-name
const CustomCard = forwardRef(
  (
    { disableShadow, children, className, ...otherProps }: ICustomCardProps,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => (
    <View
      display="block"
      className={`${styles.card} ${
        !disableShadow ? styles.shadow : ''
      } ${className}`}
      {...otherProps}
      ref={ref}
    >
      {children}
    </View>
  )
);

export default CustomCard;
