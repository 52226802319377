import { Loader } from '@aws-amplify/ui-react';
import logo from 'assets/images/white-logo.jpg';
import styles from './LoadingOverlay.module.css';

const LoadingOverlay = () => (
  <div className={`${styles.overlay} hfh-loading-overlay`}>
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="Habitat app logo." />
      <Loader height={48} filledColor="black" />
    </div>
  </div>
);

export default LoadingOverlay;
