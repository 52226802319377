import React from 'react';
import { Decision, Habitat, ReviewStatus } from 'API';
import DecisionCard from 'components/DecisionCard';
import styles from './DecisionSection.module.css';

interface IDecisionSectionProps {
  decisions: Decision[];
  habitat: Habitat;
}

const DecisionSection = ({ decisions, habitat }: IDecisionSectionProps) => (
  <div className={styles.container}>
    {decisions.map((decision) => (
      <DecisionCard
        key={decision.id}
        date={decision.createdAt || ''}
        habitat={habitat?.longName || ''}
        status={decision.status as ReviewStatus}
        editorState={decision.serializedEditorState}
        shouldRenderStatusChip
        alwaysExpanded
        disableShadow
      />
    ))}
  </div>
);

export default DecisionSection;
