import React from 'react';
import { Note } from 'API';
import NotePreview from 'components/NotePreview';
import styles from './NotesSection.module.css';

interface INotesSectionProps {
  notes: Note[];
}

const NotesSection = ({ notes }: INotesSectionProps) => (
  <div className={styles.container}>
    {notes.map((note) => (
      <NotePreview
        ownerID={note.ownerID}
        createdAt={note.createdAt}
        serializedEditorState={note.serializedEditorState}
        alwaysExpanded
        disableShadow
      />
    ))}
  </div>
);

export default NotesSection;
