import {
  CreateEditCommentInput,
  EditCommentsByRootformIDQueryVariables,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customCreateEditComment } from 'myGraphql/myMutations';
import {
  customEditCommentsByRootformID,
  customGetEditComment,
} from 'myGraphql/myQueries';

/**
 * Query all the EditComments of a RootForm.
 * @param variables The variables passed to the query.
 * @returns An array of EditComments.
 */
export const queryEditCommentsByRootForm = async (
  variables: EditCommentsByRootformIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customEditCommentsByRootformID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying editComments by rootForm.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.editCommentsByRootformID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a EditComment.
 * @param id The EditComment id.
 * @returns The EditComment found or null or undefined.
 */
export const queryEditComment = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetEditComment,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying editComment.',
      response.errors
    );
  }

  return response.data.getEditComment;
};

/**
 * Persists a new EditComment.
 * @param editComment The EditComment to persist.
 * @returns The persisted EditComment.
 */
export const newEditComment = async (editComment: CreateEditCommentInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateEditComment,
    variables: {
      input: editComment,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new editComment.',
      response.errors
    );
  }

  return response.data.createEditComment;
};
