import { CreateLastReadInput, UpdateLastReadInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateLastRead,
  customUpdateLastRead,
} from 'myGraphql/myMutations';
import {
  customGetLastRead,
  customLastReadsByChatID,
} from 'myGraphql/myQueries';

/**
 * Query all the LastReads of a Chat.
 * @param chatId The Chat id.
 * @returns An array of LastReads.
 */
export const queryLastReadsByChat = async (chatId: string) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customLastReadsByChatID,
      variables: {
        chatID: chatId,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying lastReads by chat.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.lastReadsByChatID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a LastRead.
 * @param id The LastRead id.
 * @returns The LastRead found or null or undefined.
 */
export const queryLastRead = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetLastRead,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying message.',
      response.errors
    );
  }

  return response.data.getLastRead;
};

/**
 * Persists a new LastRead.
 * @param lastRead The LastRead to persist.
 * @returns The persisted LastRead.
 */
export const newLastRead = async (lastRead: CreateLastReadInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateLastRead,
    variables: {
      input: lastRead,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new lastRead.',
      response.errors
    );
  }

  return response.data.createLastRead;
};

/**
 * Updates a LastRead.
 * @param input The LastRead with changes.
 * @returns The updated LastRead.
 */
export const updateLastRead = async (input: UpdateLastReadInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateLastRead,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating lastRead.',
      response.errors
    );
  }

  return response.data.updateLastRead;
};
