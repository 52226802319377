import React, { useState } from 'react';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import CustomButton from 'components/CustomButton';
import { MdInsertLink } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import Modal from 'components/Modal';
import { TextField } from '@aws-amplify/ui-react';
import toolbarStyles from '../../ToolbarPlugin.module.css';
import styles from './InsertLinkButton.module.css';

interface IInsertLinkButtonProps {
  isLink: boolean;
}

const InsertLinkButton = ({ isLink }: IInsertLinkButtonProps) => {
  const [editor] = useLexicalComposerContext();

  const [url, setUrl] = useState<string>();

  const { t } = useTranslation();

  const handleClickLink = () => {
    if (isLink) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();

          let element =
            anchorNode.getKey() === 'root'
              ? anchorNode
              : $findMatchingParent(anchorNode, (e) => {
                  const parent = e.getParent();
                  return parent !== null && $isRootOrShadowRoot(parent);
                });

          if (element === null) {
            element = anchorNode.getTopLevelElementOrThrow();
          }

          const elementKey = element.getKey();

          const elementDOM = editor.getElementByKey(elementKey);

          if (elementDOM !== null) {
            const parentLink = $getNearestNodeOfType(anchorNode, LinkNode);

            if ($isLinkNode(element)) {
              setUrl(element.getURL());
              return;
            }

            if (parentLink) {
              setUrl(parentLink.getURL());
            }
          }
        }
      });
    } else {
      setUrl('');
    }
  };

  const handleCreateOrUpdateLink = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
      url: url || '',
      title: url,
      target: '_blank',
      rel: 'opener',
    });
    setUrl(undefined);
  };

  const handleRemoveLink = () => {
    setUrl(undefined);
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
  };

  const handleCloseModal = () => {
    setUrl(undefined);
  };

  return (
    <>
      <Modal
        title={
          isLink
            ? t(
                'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.updateTitle'
              )
            : t(
                'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.addTitle'
              )
        }
        open={url !== undefined}
        onClickClose={handleCloseModal}
        width="512px"
      >
        <TextField
          label="URL:"
          value={url}
          onChange={(event) => setUrl(event.currentTarget.value)}
        />
        <div className={`${styles.buttons}`}>
          <CustomButton onClick={handleCreateOrUpdateLink}>
            {isLink
              ? t(
                  'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.edit'
                )
              : t(
                  'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.add'
                )}
          </CustomButton>
          {isLink && (
            <CustomButton onClick={handleRemoveLink} variation="secondary">
              {t(
                'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.remove'
              )}
            </CustomButton>
          )}
          <CustomButton onClick={handleCloseModal} variation="secondary">
            {t(
              'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertLinkButton.cancel'
            )}
          </CustomButton>
        </div>
      </Modal>
      <CustomButton
        variation="text-only"
        onClick={handleClickLink}
        className={`${toolbarStyles.toolbarButton} ${
          isLink ? toolbarStyles.selected : ''
        }`}
        aria-label={t('components.lexicalEditor.plugins.ToolbarPlugin.link')}
        title={t('components.lexicalEditor.plugins.ToolbarPlugin.link')}
      >
        <MdInsertLink />
      </CustomButton>
    </>
  );
};

export default InsertLinkButton;
