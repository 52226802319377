/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const customOnCreateLastRead =
  /* GraphQL */ `subscription OnCreateLastRead($filter: ModelSubscriptionLastReadFilterInput) {
  onCreateLastRead(filter: $filter) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateLastReadSubscriptionVariables,
    APITypes.OnCreateLastReadSubscription
  >;
export const customOnUpdateLastRead =
  /* GraphQL */ `subscription OnUpdateLastRead($filter: ModelSubscriptionLastReadFilterInput) {
  onUpdateLastRead(filter: $filter) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateLastReadSubscriptionVariables,
    APITypes.OnUpdateLastReadSubscription
  >;
export const customOnDeleteLastRead =
  /* GraphQL */ `subscription OnDeleteLastRead($filter: ModelSubscriptionLastReadFilterInput) {
  onDeleteLastRead(filter: $filter) {
    id
    chatID
    lastMessage
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteLastReadSubscriptionVariables,
    APITypes.OnDeleteLastReadSubscription
  >;
export const customOnCreateMessage =
  /* GraphQL */ `subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
  onCreateMessage(filter: $filter) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateMessageSubscriptionVariables,
    APITypes.OnCreateMessageSubscription
  >;
export const customOnUpdateMessage =
  /* GraphQL */ `subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
  onUpdateMessage(filter: $filter) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateMessageSubscriptionVariables,
    APITypes.OnUpdateMessageSubscription
  >;
export const customOnDeleteMessage =
  /* GraphQL */ `subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
  onDeleteMessage(filter: $filter) {
    id
    chatID
    content
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteMessageSubscriptionVariables,
    APITypes.OnDeleteMessageSubscription
  >;
export const customOnCreateChat =
  /* GraphQL */ `subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
  onCreateChat(filter: $filter) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateChatSubscriptionVariables,
    APITypes.OnCreateChatSubscription
  >;
export const customOnUpdateChat =
  /* GraphQL */ `subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
  onUpdateChat(filter: $filter) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateChatSubscriptionVariables,
    APITypes.OnUpdateChatSubscription
  >;
export const customOnDeleteChat =
  /* GraphQL */ `subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
  onDeleteChat(filter: $filter) {
    id
    habitatID
    owner
    lastMessage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteChatSubscriptionVariables,
    APITypes.OnDeleteChatSubscription
  >;
export const customOnCreateFormTranslation =
  /* GraphQL */ `subscription OnCreateFormTranslation(
  $filter: ModelSubscriptionFormTranslationFilterInput
) {
  onCreateFormTranslation(filter: $filter) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateFormTranslationSubscriptionVariables,
    APITypes.OnCreateFormTranslationSubscription
  >;
export const customOnUpdateFormTranslation =
  /* GraphQL */ `subscription OnUpdateFormTranslation(
  $filter: ModelSubscriptionFormTranslationFilterInput
) {
  onUpdateFormTranslation(filter: $filter) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateFormTranslationSubscriptionVariables,
    APITypes.OnUpdateFormTranslationSubscription
  >;
export const customOnDeleteFormTranslation =
  /* GraphQL */ `subscription OnDeleteFormTranslation(
  $filter: ModelSubscriptionFormTranslationFilterInput
) {
  onDeleteFormTranslation(filter: $filter) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteFormTranslationSubscriptionVariables,
    APITypes.OnDeleteFormTranslationSubscription
  >;
export const customOnCreateComponentSchema =
  /* GraphQL */ `subscription OnCreateComponentSchema(
  $filter: ModelSubscriptionComponentSchemaFilterInput
) {
  onCreateComponentSchema(filter: $filter) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateComponentSchemaSubscriptionVariables,
    APITypes.OnCreateComponentSchemaSubscription
  >;
export const customOnUpdateComponentSchema =
  /* GraphQL */ `subscription OnUpdateComponentSchema(
  $filter: ModelSubscriptionComponentSchemaFilterInput
) {
  onUpdateComponentSchema(filter: $filter) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateComponentSchemaSubscriptionVariables,
    APITypes.OnUpdateComponentSchemaSubscription
  >;
export const customOnDeleteComponentSchema =
  /* GraphQL */ `subscription OnDeleteComponentSchema(
  $filter: ModelSubscriptionComponentSchemaFilterInput
) {
  onDeleteComponentSchema(filter: $filter) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteComponentSchemaSubscriptionVariables,
    APITypes.OnDeleteComponentSchemaSubscription
  >;
export const customOnCreateFormSchema =
  /* GraphQL */ `subscription OnCreateFormSchema(
  $filter: ModelSubscriptionFormSchemaFilterInput
) {
  onCreateFormSchema(filter: $filter) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateFormSchemaSubscriptionVariables,
    APITypes.OnCreateFormSchemaSubscription
  >;
export const customOnUpdateFormSchema =
  /* GraphQL */ `subscription OnUpdateFormSchema(
  $filter: ModelSubscriptionFormSchemaFilterInput
) {
  onUpdateFormSchema(filter: $filter) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateFormSchemaSubscriptionVariables,
    APITypes.OnUpdateFormSchemaSubscription
  >;
export const customOnDeleteFormSchema =
  /* GraphQL */ `subscription OnDeleteFormSchema(
  $filter: ModelSubscriptionFormSchemaFilterInput
) {
  onDeleteFormSchema(filter: $filter) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteFormSchemaSubscriptionVariables,
    APITypes.OnDeleteFormSchemaSubscription
  >;
export const customOnCreateReport =
  /* GraphQL */ `subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
  onCreateReport(filter: $filter) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateReportSubscriptionVariables,
    APITypes.OnCreateReportSubscription
  >;
export const customOnUpdateReport =
  /* GraphQL */ `subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
  onUpdateReport(filter: $filter) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateReportSubscriptionVariables,
    APITypes.OnUpdateReportSubscription
  >;
export const customOnDeleteReport =
  /* GraphQL */ `subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
  onDeleteReport(filter: $filter) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteReportSubscriptionVariables,
    APITypes.OnDeleteReportSubscription
  >;
export const customOnCreateMaintenance =
  /* GraphQL */ `subscription OnCreateMaintenance(
  $filter: ModelSubscriptionMaintenanceFilterInput
) {
  onCreateMaintenance(filter: $filter) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateMaintenanceSubscriptionVariables,
    APITypes.OnCreateMaintenanceSubscription
  >;
export const customOnUpdateMaintenance =
  /* GraphQL */ `subscription OnUpdateMaintenance(
  $filter: ModelSubscriptionMaintenanceFilterInput
) {
  onUpdateMaintenance(filter: $filter) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateMaintenanceSubscriptionVariables,
    APITypes.OnUpdateMaintenanceSubscription
  >;
export const customOnDeleteMaintenance =
  /* GraphQL */ `subscription OnDeleteMaintenance(
  $filter: ModelSubscriptionMaintenanceFilterInput
) {
  onDeleteMaintenance(filter: $filter) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteMaintenanceSubscriptionVariables,
    APITypes.OnDeleteMaintenanceSubscription
  >;
export const customOnCreateUser =
  /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateUserSubscriptionVariables,
    APITypes.OnCreateUserSubscription
  >;
export const customOnUpdateUser =
  /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateUserSubscriptionVariables,
    APITypes.OnUpdateUserSubscription
  >;
export const customOnDeleteUser =
  /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteUserSubscriptionVariables,
    APITypes.OnDeleteUserSubscription
  >;
export const customOnCreateRootForm =
  /* GraphQL */ `subscription OnCreateRootForm($filter: ModelSubscriptionRootFormFilterInput) {
  onCreateRootForm(filter: $filter) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateRootFormSubscriptionVariables,
    APITypes.OnCreateRootFormSubscription
  >;
export const customOnUpdateRootForm =
  /* GraphQL */ `subscription OnUpdateRootForm($filter: ModelSubscriptionRootFormFilterInput) {
  onUpdateRootForm(filter: $filter) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateRootFormSubscriptionVariables,
    APITypes.OnUpdateRootFormSubscription
  >;
export const customOnDeleteRootForm =
  /* GraphQL */ `subscription OnDeleteRootForm($filter: ModelSubscriptionRootFormFilterInput) {
  onDeleteRootForm(filter: $filter) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    decisionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteRootFormSubscriptionVariables,
    APITypes.OnDeleteRootFormSubscription
  >;
export const customOnCreateDecision =
  /* GraphQL */ `subscription OnCreateDecision($filter: ModelSubscriptionDecisionFilterInput) {
  onCreateDecision(filter: $filter) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateDecisionSubscriptionVariables,
    APITypes.OnCreateDecisionSubscription
  >;
export const customOnUpdateDecision =
  /* GraphQL */ `subscription OnUpdateDecision($filter: ModelSubscriptionDecisionFilterInput) {
  onUpdateDecision(filter: $filter) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateDecisionSubscriptionVariables,
    APITypes.OnUpdateDecisionSubscription
  >;
export const customOnDeleteDecision =
  /* GraphQL */ `subscription OnDeleteDecision($filter: ModelSubscriptionDecisionFilterInput) {
  onDeleteDecision(filter: $filter) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteDecisionSubscriptionVariables,
    APITypes.OnDeleteDecisionSubscription
  >;
export const customOnCreateFormAnswer =
  /* GraphQL */ `subscription OnCreateFormAnswer(
  $filter: ModelSubscriptionFormAnswerFilterInput
) {
  onCreateFormAnswer(filter: $filter) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateFormAnswerSubscriptionVariables,
    APITypes.OnCreateFormAnswerSubscription
  >;
export const customOnUpdateFormAnswer =
  /* GraphQL */ `subscription OnUpdateFormAnswer(
  $filter: ModelSubscriptionFormAnswerFilterInput
) {
  onUpdateFormAnswer(filter: $filter) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateFormAnswerSubscriptionVariables,
    APITypes.OnUpdateFormAnswerSubscription
  >;
export const customOnDeleteFormAnswer =
  /* GraphQL */ `subscription OnDeleteFormAnswer(
  $filter: ModelSubscriptionFormAnswerFilterInput
) {
  onDeleteFormAnswer(filter: $filter) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteFormAnswerSubscriptionVariables,
    APITypes.OnDeleteFormAnswerSubscription
  >;
export const customOnCreateNote =
  /* GraphQL */ `subscription OnCreateNote($filter: ModelSubscriptionNoteFilterInput) {
  onCreateNote(filter: $filter) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateNoteSubscriptionVariables,
    APITypes.OnCreateNoteSubscription
  >;
export const customOnUpdateNote =
  /* GraphQL */ `subscription OnUpdateNote($filter: ModelSubscriptionNoteFilterInput) {
  onUpdateNote(filter: $filter) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateNoteSubscriptionVariables,
    APITypes.OnUpdateNoteSubscription
  >;
export const customOnDeleteNote =
  /* GraphQL */ `subscription OnDeleteNote($filter: ModelSubscriptionNoteFilterInput) {
  onDeleteNote(filter: $filter) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteNoteSubscriptionVariables,
    APITypes.OnDeleteNoteSubscription
  >;
export const customOnCreateTestCycle =
  /* GraphQL */ `subscription OnCreateTestCycle($filter: ModelSubscriptionTestCycleFilterInput) {
  onCreateTestCycle(filter: $filter) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateTestCycleSubscriptionVariables,
    APITypes.OnCreateTestCycleSubscription
  >;
export const customOnUpdateTestCycle =
  /* GraphQL */ `subscription OnUpdateTestCycle($filter: ModelSubscriptionTestCycleFilterInput) {
  onUpdateTestCycle(filter: $filter) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateTestCycleSubscriptionVariables,
    APITypes.OnUpdateTestCycleSubscription
  >;
export const customOnDeleteTestCycle =
  /* GraphQL */ `subscription OnDeleteTestCycle($filter: ModelSubscriptionTestCycleFilterInput) {
  onDeleteTestCycle(filter: $filter) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteTestCycleSubscriptionVariables,
    APITypes.OnDeleteTestCycleSubscription
  >;
export const customOnCreateTestApplication =
  /* GraphQL */ `subscription OnCreateTestApplication(
  $filter: ModelSubscriptionTestApplicationFilterInput
) {
  onCreateTestApplication(filter: $filter) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateTestApplicationSubscriptionVariables,
    APITypes.OnCreateTestApplicationSubscription
  >;
export const customOnUpdateTestApplication =
  /* GraphQL */ `subscription OnUpdateTestApplication(
  $filter: ModelSubscriptionTestApplicationFilterInput
) {
  onUpdateTestApplication(filter: $filter) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateTestApplicationSubscriptionVariables,
    APITypes.OnUpdateTestApplicationSubscription
  >;
export const customOnDeleteTestApplication =
  /* GraphQL */ `subscription OnDeleteTestApplication(
  $filter: ModelSubscriptionTestApplicationFilterInput
) {
  onDeleteTestApplication(filter: $filter) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteTestApplicationSubscriptionVariables,
    APITypes.OnDeleteTestApplicationSubscription
  >;
export const customOnCreateHabitat =
  /* GraphQL */ `subscription OnCreateHabitat($filter: ModelSubscriptionHabitatFilterInput) {
  onCreateHabitat(filter: $filter) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateHabitatSubscriptionVariables,
    APITypes.OnCreateHabitatSubscription
  >;
export const customOnUpdateHabitat =
  /* GraphQL */ `subscription OnUpdateHabitat($filter: ModelSubscriptionHabitatFilterInput) {
  onUpdateHabitat(filter: $filter) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateHabitatSubscriptionVariables,
    APITypes.OnUpdateHabitatSubscription
  >;
export const customOnDeleteHabitat =
  /* GraphQL */ `subscription OnDeleteHabitat($filter: ModelSubscriptionHabitatFilterInput) {
  onDeleteHabitat(filter: $filter) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      notifySubmissionsEmails
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteHabitatSubscriptionVariables,
    APITypes.OnDeleteHabitatSubscription
  >;
