import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { Text, View } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import LexicalEditorTheme from './LexicalEditor.Theme';
import './LexicalEditor.style.css';
import RestoreStatePlugin from './plugins/RestoreStatePlugin';
import EditablePlugin from './plugins/EditablePlugin';
import { ImageNode } from './nodes/ImageNode';
import ImagePlugin from './plugins/ImagePlugin';
import FilePlugin from './plugins/FilePlugin';
import { FileNode } from './nodes/FileNode';
import ExportToHtmlPlugin from './plugins/ExportToHtmlPlugin';
import RestoreHtmlPlugin from './plugins/RestoreHtmlPlugin';
import IsEmptyPlugin from './plugins/IsEmptyPlugin';

// eslint-disable-next-line react/display-name
const Placeholder = ({ text, updateHeight }) => {
  const { t } = useTranslation();
  const textRef = useRef(null);
  useEffect(() => {
    if (!textRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (textRef.current) {
        requestAnimationFrame(() => {
          textRef.current && updateHeight(textRef.current.offsetHeight);
        });
      }
    });
    resizeObserver.observe(textRef.current);
    return () => {
      resizeObserver.disconnect();
      updateHeight(undefined);
    };
  }, [updateHeight]);

  return (
    <Text
      opacity="50"
      position="absolute"
      top="0"
      left="0"
      padding="1rem 0.5rem"
      overflow="hidden"
      style={{
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        pointerEvents: 'none',
      }}
      as="p"
      ref={textRef}
    >
      {text || t('components.lexicalEditor.placeholder')}
    </Text>
  );
};

Placeholder.propTypes = {
  text: PropTypes.string,
  updateHeight: PropTypes.func,
};

const LexicalEditor = ({
  onChange = (editorState) => undefined,
  onChangeHtml,
  editable,
  serializedEditorState,
  disableFiles,
  placeholder,
  htmlString,
  disableImages,
  minimalistDesign,
  onEmptyChange,
  compact,
  disableUndoRedo,
  disableTextDecorations,
  disableTextAlignments,
}) => {
  const [placeholderHeight, setPlaceholderHeight] = useState(undefined);
  return (
    <LexicalComposer
      initialConfig={{
        theme: LexicalEditorTheme,
        namespace: 'MyEditor',
        onError: (error) => console.log('Lexical error', error),
        nodes: [
          ...(disableImages ? [] : [ImageNode]),
          ...(disableFiles ? [] : [FileNode]),
          HeadingNode,
          ListNode,
          ListItemNode,
          LinkNode,
        ],
      }}
    >
      <View
        border="var(--amplify-colors-border-primary)"
        borderStyle={editable ? 'solid' : ''}
        borderRadius="small"
        borderWidth={minimalistDesign ? '0px' : '2px 2px 2px 2px'}
        height="fit-content"
        width="100%"
      >
        {editable && (
          <ToolbarPlugin
            disableFiles={disableFiles}
            disableImages={disableImages}
            disableUndoRedo={disableUndoRedo}
            disableTextDecorations={disableTextDecorations}
            disableTextAlignments={disableTextAlignments}
          />
        )}
        <View position="relative">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={`editor-input ${!editable ? 'editor-readonly' : ''}`}
                style={{
                  height: placeholderHeight,
                  minHeight: compact ? 'fit-content' : undefined,
                }}
                spellCheck={editable}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              editable && (
                <Placeholder
                  text={placeholder}
                  updateHeight={setPlaceholderHeight}
                />
              )
            }
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <OnChangePlugin onChange={onChange} />
          <ListPlugin />
          <LinkPlugin />
          {!disableFiles && <FilePlugin />}
          {!disableImages && <ImagePlugin />}
          <EditablePlugin editable={!!editable} />
          <RestoreStatePlugin serializedEditorState={serializedEditorState} />
          <RestoreHtmlPlugin htmlString={htmlString} />
          {onChangeHtml && <ExportToHtmlPlugin onChange={onChangeHtml} />}
          {onEmptyChange && <IsEmptyPlugin onEmptyChange={onEmptyChange} />}
        </View>
      </View>
    </LexicalComposer>
  );
};

LexicalEditor.propTypes = {
  serializedEditorState: PropTypes.string,
  htmlString: PropTypes.string,
  onChange: PropTypes.func,
  onChangeHtml: PropTypes.func,
  editable: PropTypes.bool,
  disableFiles: PropTypes.bool,
  placeholder: PropTypes.string,
  disableImages: PropTypes.bool,
  minimalistDesign: PropTypes.bool,
  onEmptyChange: PropTypes.func,
  compact: PropTypes.bool,
  disableUndoRedo: PropTypes.bool,
  disableTextDecorations: PropTypes.bool,
  disableTextAlignments: PropTypes.bool,
};

export default LexicalEditor;
