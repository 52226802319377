import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@formio/react';
import { Await, useLoaderData, useRevalidator } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHabitat from 'hooks/utils/useHabitat';
import { Options } from '@formio/react/lib/components/Form';
import { Loader } from '@aws-amplify/ui-react';
import { convertTranlationsToObject } from 'utils/translations';
import { TCommentsData } from 'router/loaders/comments';
import { domToPng } from 'modern-screenshot';
import CustomButton from 'components/CustomButton';
import {
  MdOutlineArrowLeft,
  MdOutlineArrowRight,
  MdOutlineClose,
  MdOutlineDeselect,
  MdOutlineDone,
  MdOutlineHighlightAlt,
} from 'react-icons/md';
import Modal from 'components/Modal';
import LexicalEditor from 'components/LexicalEditor';
import IconButton from 'components/IconButton';
import { EditorState } from 'lexical';
import { uploadData } from 'aws-amplify/storage';
import { v4 } from 'uuid';
import Skeleton from 'components/Skeleton';
import { fileFromObjectURL } from 'utils/files';
import { getEditorStateWithFilesInBucket } from 'utils/lexicalEditor';
import { newEditComment } from 'services/graphql/EditComment';
import { EditCommentStatus } from 'API';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import style from './AffiliateCommentsPage.module.css';

interface ISelectedElement {
  element: HTMLElement;
  screenshot: {
    url?: string;
    loading: boolean;
  };
}

const AffiliateCommentsPage = () => {
  const [sidebar, setSidebar] = useState(false);

  const [submission, setSubmission] = useState<object>();

  const [selecting, setSelecting] = useState(false);

  const [selectedElements, setSelectedElements] = useState<ISelectedElement[]>(
    []
  );

  const [comment, setComment] = useState<EditorState>();

  const [uploadingComment, setUploadingComment] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [commentModal, setCommentModal] = useState(false);

  const { revalidate, state: revalidateState } = useRevalidator();

  const { commentsData } = useLoaderData() as {
    commentsData: Promise<TCommentsData>;
  };

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const { habitat } = useHabitat();

  const uploadFile = async (file: File) => {
    const { rootForm } = await commentsData;

    const result = await uploadData({
      path: `public/comments/${habitat?.urlName}/${rootForm.id}/${v4()}-${
        file.name
      }`,
      data: file,
    }).result;

    return result;
  };

  const handleAddComment = async () => {
    try {
      setUploadingComment(true);
      const screenshots = [];

      for (const selectedElement of selectedElements) {
        if (selectedElement.screenshot.url) {
          const file = await fileFromObjectURL(
            selectedElement.screenshot.url,
            `screenshot-${new Date().toISOString()}.png`,
            'png'
          );

          const result = await uploadFile(file);

          screenshots.push(result.path);
        }
      }

      const jsonState = comment?.toJSON();

      const stateWithS3Keys = await getEditorStateWithFilesInBucket(
        jsonState || {},
        uploadFile
      );

      const { rootForm } = await commentsData;

      await newEditComment({
        content: JSON.stringify(stateWithS3Keys),
        screenshots,
        rootformID: rootForm.id,
        status: EditCommentStatus.PENDING,
      });

      setSelectedElements([]);
      setComment(undefined);
      setCommentModal(false);
      setSelecting(false);
      revalidate();
      setSidebar(true);
    } catch (error) {
      console.warn('Error adding comment');
    } finally {
      setUploadingComment(false);
    }
  };

  const checkIfTargetIsSelectable = useCallback(
    (event: MouseEvent) =>
      event.target instanceof HTMLElement &&
      !event.target.isSameNode(containerRef.current) &&
      event.target.getAttribute('role') !== 'radio' &&
      selecting,
    [selecting]
  );

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      const border = 1;

      const overlay = document.createElement('div');

      containerRef.current.prepend(overlay);

      overlay.style.position = 'fixed';

      const handleMouseEnter = (event: MouseEvent) => {
        const isNotHighlighted = !selectedElements.some(
          (screenshot) =>
            screenshot.element.isSameNode(event.target as HTMLElement) ||
            screenshot.element.contains(event.target as HTMLElement)
        );

        if (
          event.target instanceof HTMLElement &&
          checkIfTargetIsSelectable(event) &&
          isNotHighlighted
        ) {
          event.stopImmediatePropagation();

          const eventTarget = event.target;

          overlay.style.top = `${
            eventTarget.getBoundingClientRect().y - border
          }px`;

          overlay.style.left = `${
            eventTarget.getBoundingClientRect().x - border
          }px`;

          overlay.style.width = `${
            eventTarget.getBoundingClientRect().width + border * 2
          }px`;

          overlay.style.height = `${
            eventTarget.getBoundingClientRect().height + border * 2
          }px`;

          overlay.classList.add(style.hovered);

          const handleMouseOut = (event: MouseEvent) => {
            if (event.currentTarget instanceof HTMLElement) {
              event.stopImmediatePropagation();

              overlay.classList.remove(style.hovered);

              overlay.style.width = '0px';

              overlay.style.height = '0px';

              event.currentTarget.removeEventListener(
                'mouseout',
                handleMouseOut
              );
            }
          };

          eventTarget.addEventListener('mouseout', handleMouseOut);
        }
      };

      container.addEventListener('mouseover', handleMouseEnter);

      const handleMouseClick = (event: MouseEvent) => {
        if (!checkIfTargetIsSelectable(event)) {
          return;
        }

        event.preventDefault();

        event.stopImmediatePropagation();

        if (event.target instanceof HTMLElement) {
          const eventTarget = event.target as HTMLElement;

          const isTargetOrParentSelected = selectedElements.some(
            (selectedElement) =>
              selectedElement.element.isSameNode(eventTarget) ||
              selectedElement.element.contains(eventTarget)
          );
          if (isTargetOrParentSelected) {
            setSelectedElements((prevSelectedElements) =>
              prevSelectedElements.filter(
                (selectedElement) =>
                  !selectedElement.element.isSameNode(eventTarget) &&
                  !selectedElement.element.contains(eventTarget)
              )
            );
          } else {
            const div = document.createElement('div');

            div.style.display = 'flex';

            const targetClone = eventTarget.cloneNode(true) as HTMLElement;

            targetClone.style.flex = '1';

            div.append(targetClone);

            const originalDisplay = eventTarget.style.display;

            setSelectedElements((prevSelectedElements) => [
              ...prevSelectedElements.filter(
                (prevSelectedElement) =>
                  !eventTarget.contains(prevSelectedElement.element)
              ),
              {
                element: div,
                screenshot: {
                  loading: true,
                },
              },
            ]);

            eventTarget.style.display = 'none';

            eventTarget.insertAdjacentElement('afterend', div);

            requestAnimationFrame(() =>
              domToPng(div as HTMLElement, {
                backgroundColor: '#FFFFFF',
                quality: 1,
                scale: 1.5,
              }).then((dataUrl) => {
                setSelectedElements((prevSelectedElements) => {
                  const elementIndex = prevSelectedElements.findIndex(
                    (screenshot) => screenshot.element.isSameNode(div)
                  );

                  eventTarget.style.display = originalDisplay;

                  div.remove();

                  if (elementIndex !== -1) {
                    const copyOfSelected = [...prevSelectedElements];

                    copyOfSelected[elementIndex] = {
                      element: eventTarget,
                      screenshot: {
                        loading: false,
                        url: dataUrl,
                      },
                    };

                    return copyOfSelected;
                  }

                  return prevSelectedElements;
                });
              })
            );
          }
        }
      };

      container.addEventListener('click', handleMouseClick, true);

      return () => {
        container.removeEventListener('mouseover', handleMouseEnter);
        container.removeEventListener('click', handleMouseClick, true);
        overlay.remove();
      };
    }
  }, [checkIfTargetIsSelectable, selectedElements, selecting]);

  useEffect(() => {
    const overlays: HTMLElement[] = [];
    if (containerRef.current && !commentModal) {
      for (const element of selectedElements) {
        const border = 1;

        const overlay = document.createElement('div');

        containerRef.current.prepend(overlay);

        overlay.classList.add(style.hovered);

        overlay.style.position = 'absolute';

        overlay.style.top = `${
          element.element.getBoundingClientRect().top -
          containerRef.current.getBoundingClientRect().top -
          border
        }px`;

        overlay.style.left = `${
          element.element.getBoundingClientRect().left -
          containerRef.current.getBoundingClientRect().left -
          border
        }px`;

        overlay.style.width = `${
          element.element.getBoundingClientRect().width + border * 2
        }px`;

        overlay.style.height = `${
          element.element.getBoundingClientRect().height + border * 2
        }px`;

        overlays.push(overlay);
      }
    }

    return () => {
      for (const overlay of overlays) {
        overlay.remove();
      }
    };
  }, [commentModal, selectedElements]);

  useEffect(() => {
    const setSupportQuestion = async () => {
      const { rootForm } = await commentsData;

      if (rootForm.supportQuestion) {
        i18n.addResource(
          'en',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.en
        );

        i18n.addResource(
          'es',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.es || rootForm.supportQuestion?.en
        );
      }
    };

    setSupportQuestion();

    return () => {
      i18n.removeResourceBundle('en', 'rootForm');
      i18n.removeResourceBundle('es', 'rootForm');
    };
  }, [commentsData, i18n, t]);

  useEffect(() => {
    if (!commentModal) {
      setComment(undefined);
    }
  }, [commentModal]);

  useEffect(() => {
    setSelectedElements((prevSelectedElements) =>
      prevSelectedElements.filter((selectedElement) =>
        document.body.contains(selectedElement.element)
      )
    );
  }, [language]);

  return (
    <div>
      <Modal
        title={t('pages.habitat.affiliate.forms.form.comments.modal.title')}
        open={commentModal}
        onClickClose={() => setCommentModal(false)}
      >
        <div className={`${style.modalContent}`}>
          <span className={style.label}>
            {t('pages.habitat.affiliate.forms.form.comments.modal.screenshots')}
          </span>
          <div className={`${style.screenshotsContainer}`}>
            {selectedElements.map((element, index) => (
              <div className={`${style.screenshotContainer}`} key={index}>
                <IconButton
                  className={style.removeScreenshotButton}
                  onClick={() =>
                    setSelectedElements((prevSelectedElements) =>
                      prevSelectedElements.filter(
                        (selectedElement) =>
                          selectedElement.screenshot !== element.screenshot
                      )
                    )
                  }
                >
                  <MdOutlineClose />
                </IconButton>
                {element.screenshot.loading ? (
                  <Skeleton className={style.loadingImg} />
                ) : (
                  <img
                    src={element.screenshot.url}
                    alt={`screenshot-${index}`}
                  />
                )}
              </div>
            ))}
          </div>
          <span className={style.label}>
            {t('pages.habitat.affiliate.forms.form.comments.modal.changes')}
          </span>
          <LexicalEditor
            editable
            onChange={(editorState) => {
              setComment(editorState);
            }}
          />
          <div className={`${style.modalButtonsContainer}`}>
            <CustomButton
              disabled={uploadingComment}
              onClick={handleAddComment}
              icon={uploadingComment && <Loader />}
            >
              {t('pages.habitat.affiliate.forms.form.comments.modal.add')}
            </CustomButton>
            <CustomButton
              disabled={uploadingComment}
              variation="secondary"
              onClick={() => setCommentModal(false)}
            >
              {t('pages.habitat.affiliate.forms.form.comments.modal.close')}
            </CustomButton>
          </div>
        </div>
      </Modal>
      <div ref={containerRef} className={`${style.formContainer}`}>
        <Suspense fallback={<Loader size="large" />}>
          <Await resolve={commentsData}>
            {({ translations, formSchema }: TCommentsData) => {
              const options = {
                additional: {
                  habitat,
                },
                language: translations.length === 0 ? 'en' : language,
                i18n: convertTranlationsToObject(translations),
                flatten: true,
              } as Options;
              return (
                <Form
                  key={`review-${language}`}
                  form={formSchema}
                  options={options}
                  submission={submission}
                  onSubmit={(newSubmission: object) => {
                    setSubmission(newSubmission);
                  }}
                />
              );
            }}
          </Await>
        </Suspense>
      </div>
      <Suspense>
        <Await resolve={commentsData}>
          <div className={`${style.buttonContainer}`}>
            {selectedElements.length > 0 && (
              <CustomButton
                className={`${style.button}`}
                onClick={() => setCommentModal(true)}
                icon={<MdOutlineDone />}
              >
                {t('pages.habitat.affiliate.forms.form.comments.buttons.done')}
              </CustomButton>
            )}
            <CustomButton
              variation={selecting ? 'secondary' : 'primary'}
              onClick={() => setSelecting((prevSelecting) => !prevSelecting)}
              icon={
                selecting ? <MdOutlineDeselect /> : <MdOutlineHighlightAlt />
              }
            >
              {selecting
                ? t(
                    'pages.habitat.affiliate.forms.form.comments.buttons.stopSelecting'
                  )
                : t(
                    'pages.habitat.affiliate.forms.form.comments.buttons.startSelecting'
                  )}
            </CustomButton>
          </div>
        </Await>
      </Suspense>
      <Suspense>
        <Await resolve={commentsData}>
          {({ comments }: TCommentsData) => (
            <div
              className={`${style.sidebar} ${sidebar ? '' : style.collapsed}`}
            >
              <div
                className={`${style.tab}`}
                onClick={() => setSidebar((prevSidebar) => !prevSidebar)}
                aria-hidden="true"
              >
                {sidebar ? <MdOutlineArrowRight /> : <MdOutlineArrowLeft />}
              </div>
              <div className={`${style.commentsContainer}`}>
                <span className={`${style.pendingComments}`}>
                  {t(
                    'pages.habitat.affiliate.forms.form.comments.pendingComments'
                  )}
                </span>

                {comments
                  .sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                      return 1;
                    }

                    if (a.createdAt < b.createdAt) {
                      return -1;
                    }

                    return 0;
                  })
                  .map((comment) => (
                    <div
                      className={`${style.commentContainer}`}
                      key={comment.id}
                    >
                      <div className={`${style.screenshotsContainer}`}>
                        {comment.screenshots.map((screenshot, index) => (
                          <div
                            className={`${style.screenshotContainer}`}
                            key={index}
                          >
                            <StorageImage
                              path={screenshot}
                              alt={`screenshot-${index}`}
                            />
                          </div>
                        ))}
                      </div>
                      <LexicalEditor serializedEditorState={comment.content} />
                    </div>
                  ))}

                {revalidateState === 'loading' && (
                  <Skeleton className={style.commentSkeleton} />
                )}
              </div>
            </div>
          )}
        </Await>
      </Suspense>
    </div>
  );
};

export default AffiliateCommentsPage;
