import React, { Suspense } from 'react';
import {
  Loader,
  useAuthenticator,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import useHabitat from 'hooks/utils/useHabitat';
import ChatComponent from 'components/Chat';
import { useTranslation } from 'react-i18next';
import { Await, useLoaderData } from 'react-router-dom';
import { TChatData } from 'router/loaders/chat';
import Skeleton from 'components/Skeleton';
import styles from './AffiliateChatPage.module.css';

const AffiliateChatPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { habitat } = useHabitat();

  const { chatData } = useLoaderData() as {
    chatData: Promise<TChatData>;
  };

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        <Suspense fallback={<Skeleton variation="text" numOfCharacters={15} />}>
          <Await resolve={chatData}>
            {({ ownerUser }: TChatData) =>
              t('pages.habitat.affiliate.chats.chat.title', {
                applicantName: `${ownerUser.firstName} ${ownerUser.lastName}`,
              })
            }
          </Await>
        </Suspense>
      </h3>
      <div className={`${styles.chat}`}>
        <Suspense fallback={<Loader />}>
          <Await resolve={chatData}>
            {({ chat }: TChatData) =>
              habitat && (
                <ChatComponent
                  habitat={habitat}
                  chat={chat}
                  currentUser={user.username}
                />
              )
            }
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AffiliateChatPage;
