import React, { useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import Modal from 'components/Modal';
import { TextField } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import styles from './StatusesField.module.css';
import {
  customStatusSchema,
  TCustomStatusSchema,
} from './StatusesField.schemas';

interface IStatusesField {
  statuses: string[];
  onChange: (newStatuses: string[]) => void;
}

const StatusesField = ({ statuses, onChange }: IStatusesField) => {
  const [newModal, setNewModal] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState<string>();

  const { t } = useTranslation();

  const {
    register: registerNew,
    handleSubmit: handleSubmitNew,
    formState: { errors: errorsNew },
    reset: resetNew,
  } = useForm({
    resolver: zodResolver(customStatusSchema),
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      status: '',
    },
  });

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    formState: { errors: errorsEdit },
    reset: resetEdit,
  } = useForm({
    resolver: zodResolver(customStatusSchema),
    shouldUnregister: true,
    shouldFocusError: true,
    defaultValues: {
      status: '',
    },
  });

  const handleSelectStatus = (statusSelected: string) => {
    setSelectedStatus(statusSelected);
  };

  const handleClickAddStatus = () => setNewModal(true);

  const handleValidAddStatus = (data: TCustomStatusSchema) => {
    onChange([...statuses, data.status]);
    resetNew();
    setNewModal(false);
  };

  const handleValidEditStatus = (data: TCustomStatusSchema) => {
    onChange(
      statuses.map((status) =>
        status === selectedStatus ? data.status : status
      )
    );
    resetEdit();
    setSelectedStatus(undefined);
  };

  const handleDeleteStatus = () => {
    onChange(statuses.filter((status) => status !== selectedStatus));
    resetEdit();
    setSelectedStatus(undefined);
  };

  useEffect(() => {
    resetEdit({
      status: selectedStatus,
    });
  }, [selectedStatus, resetEdit]);

  const modalWidth = '512px';

  return (
    <div className={`${styles.container}`}>
      {statuses.map((status) => (
        <button
          key={status}
          type="button"
          className={`${styles.chip}`}
          onClick={() => handleSelectStatus(status)}
        >
          {status}
        </button>
      ))}
      <Modal
        title={t(
          'pages.habitat.affiliate.settings.components.StatusesField.newTitle'
        )}
        open={newModal}
        onClickClose={() => setNewModal(false)}
        width={modalWidth}
      >
        <form onSubmit={handleSubmitNew(handleValidAddStatus)}>
          <div>
            <TextField
              label={t(
                'pages.habitat.affiliate.settings.components.StatusesField.label'
              )}
              {...registerNew('status')}
              errorMessage={t(
                'pages.habitat.affiliate.settings.components.StatusesField.errorMessage'
              )}
              hasError={Boolean(errorsNew.status)}
            />
          </div>
          <div className={`${styles.modalButtons}`}>
            <CustomButton variation="primary" type="submit">
              {t(
                'pages.habitat.affiliate.settings.components.StatusesField.add'
              )}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={() => setNewModal(false)}
            >
              {t(
                'pages.habitat.affiliate.settings.components.StatusesField.cancel'
              )}
            </CustomButton>
          </div>
        </form>
      </Modal>
      <Modal
        title={t(
          'pages.habitat.affiliate.settings.components.StatusesField.editTitle'
        )}
        open={Boolean(selectedStatus)}
        onClickClose={() => setSelectedStatus(undefined)}
        width={modalWidth}
      >
        <form onSubmit={handleSubmitEdit(handleValidEditStatus)}>
          <div>
            <TextField
              label={t(
                'pages.habitat.affiliate.settings.components.StatusesField.label'
              )}
              errorMessage={t(
                'pages.habitat.affiliate.settings.components.StatusesField.errorMessage'
              )}
              hasError={Boolean(errorsEdit.status)}
              defaultValue={selectedStatus}
              {...registerEdit('status')}
            />
          </div>
          <div className={`${styles.modalButtons}`}>
            <CustomButton variation="primary" type="submit">
              {t(
                'pages.habitat.affiliate.settings.components.StatusesField.update'
              )}
            </CustomButton>
            <CustomButton variation="secondary" onClick={handleDeleteStatus}>
              {t(
                'pages.habitat.affiliate.settings.components.StatusesField.remove'
              )}
            </CustomButton>
            <CustomButton
              variation="secondary"
              onClick={() => setSelectedStatus(undefined)}
            >
              {t(
                'pages.habitat.affiliate.settings.components.StatusesField.cancel'
              )}
            </CustomButton>
          </div>
        </form>
      </Modal>
      <button
        type="button"
        className={`${styles.chip} ${styles.newChip}`}
        onClick={handleClickAddStatus}
      >
        <MdOutlineAdd />
        {t('pages.habitat.affiliate.settings.components.StatusesField.add')}
      </button>
    </div>
  );
};

export default StatusesField;
