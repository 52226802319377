import React from 'react';
import { Chat } from 'API';
import Skeleton from 'components/Skeleton';
import LexicalEditor from 'components/LexicalEditor';
import { ICustomMessage } from 'types';
import styles from './Message.module.css';

interface IMessage {
  chat: Chat;
  message: ICustomMessage;
  userName?: string;
  currentUser: string;
}

const Message = ({ chat, message, userName, currentUser }: IMessage) => (
  <div
    className={`${styles.message} ${
      message.owner === currentUser ? styles.currentUserMessage : ''
    } ${
      message.owner === chat.owner
        ? styles.applicantMessage
        : styles.habitatMessage
    } ${message.sending ? styles.sending : ''} ${
      message.error ? styles.error : ''
    }`}
  >
    {message.owner !== currentUser && (
      <span className={`theme-body-small ${styles.name}`}>
        {userName || <Skeleton variation="text" numOfCharacters={12} />}
      </span>
    )}
    <LexicalEditor serializedEditorState={message.content} />
  </div>
);

export default Message;
