import React from 'react';
import { TextField, TextFieldProps } from '@aws-amplify/ui-react';
import IconButton from 'components/IconButton';
import { MdOutlineClose, MdOutlineSearch } from 'react-icons/md';
import styles from './SearchField.module.css';

interface SearchFieldProps
  extends Omit<TextFieldProps, 'innerStartComponent' | 'innerEndComponent'> {
  onClickClear: () => void;
}

const SearchField = (props: SearchFieldProps) => {
  const { value, className, onClickClear } = props;

  return (
    <TextField
      {...props}
      innerStartComponent={
        <div className={styles.search}>
          {' '}
          <MdOutlineSearch size={32} />
        </div>
      }
      innerEndComponent={
        <div className={`${value ? styles.close : styles.hidden}`}>
          <IconButton
            variation="not-outlined"
            className={styles.closeButton}
            onClick={onClickClear}
          >
            <MdOutlineClose />
          </IconButton>
        </div>
      }
      className={`${styles.searchField} ${className}`}
    />
  );
};

export default SearchField;
