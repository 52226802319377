import { useTranslation } from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import TableWithPaginator from 'components/TableWithPaginator';
import { Suspense, useCallback, useEffect, useState } from 'react';
import {
  MdOutlineAdd,
  MdOutlineBuild,
  MdOutlineFilterList,
  MdOutlineLink,
  MdOutlineOpenInNew,
  MdOutlineSaveAlt,
  MdOutlineScience,
  MdOutlineSettings,
} from 'react-icons/md';
import {
  Await,
  Link,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from 'react-router-dom';
import { stringToHumanReadable } from 'utils/strings';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import DropdownMenu from 'components/DropdownMenu';
import GoBack from 'components/GoBack';
import { DEFAULT_REVIEW_STATUS } from 'utils/constants';
import {
  Flex,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { convertDateYYYYMMDDtoDDMMYYYY } from 'utils/dates';
import useHabitat from 'hooks/utils/useHabitat';
import ResultsCounter from 'components/ResultsCounter';
import { get } from 'aws-amplify/api';
import { generateCSVFromObjectArray } from 'utils/csv';
import { downloadWithUrl } from 'utils/files';
import { TCycleData } from 'router/loaders/cycle';
import { TRootFormData } from 'router/loaders/rootForm';
import Skeleton from 'components/Skeleton';
import { capitalize } from 'lodash';
import {
  queryTestApplication,
  queryTestApplicationsByTestCycle,
  updateTestApplication,
} from 'services/graphql/TestApplication';
import {
  ApplicationTypes,
  ModelTestApplicationFilterInput,
  ReviewStatus,
  SubmissionStatus,
  TestApplication,
} from 'API';
import useAsync from 'hooks/utils/useAsync';
import {
  deleteFormAnswer,
  queryFormAnswersByTestApplication,
} from 'services/graphql/FormAnswer';
import { queryAllUsers } from 'services/graphql/User';
import StatusChip from 'components/StatusChip';
import style from './AffiliateCycleApplications.module.css';
import NewApplicationModal from './components/NewApplicationModal';
import StatusModal from './components/StatusModal';
import { Inputs } from './types';
import Filters from './components/Filters';
import Username from './components/Username';
import { handleCopyToClipboard } from './utils';
import ApplicationLinkModal from './components/ApplicationLinkModal';
import SettingsModal from './components/SettingsModal/SettingsModal';

const AffiliateCycleApplications = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { cycleData } = useRouteLoaderData('cycle') as {
    cycleData: Promise<TCycleData>;
  };

  const { rootFormData } = useRouteLoaderData('rootForm') as {
    rootFormData: Promise<TRootFormData>;
  };

  const [cycle, setCycle] = useState<TCycleData['cycle']>();

  const { habitat } = useHabitat();

  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [newApplicationOpen, setNewApplicationOpen] = useState(false);

  const [trigger, setTrigger] = useState(0);

  const [filterModal, setFilterModal] = useState(false);

  const [settingsModal, setSettingsModal] = useState(false);

  const [filters, setFilters] = useState<Inputs>({
    startDateSubmitted: '',
    endDateSubmitted: '',
    type: null,
    reviewStatus: null,
    customStatus: '',
  });

  const [linkModal, setLinkModal] = useState(false);

  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const fetchApplications = useCallback(async () => {
    trigger.toString();

    const criteriaArr: ModelTestApplicationFilterInput[] = [];

    if (filters.reviewStatus) {
      criteriaArr.push({ reviewStatus: { eq: filters.reviewStatus } });
    }

    if (
      filters.startDateSubmitted &&
      filters.startDateSubmitted !== 'MM/DD/YYYY'
    ) {
      criteriaArr.push({ submittedDate: { ge: filters.startDateSubmitted } });
    }

    if (filters.endDateSubmitted && filters.endDateSubmitted !== 'MM/DD/YYYY') {
      criteriaArr.push({ submittedDate: { le: filters.endDateSubmitted } });
    }

    if (filters.type) {
      criteriaArr.push({ type: { eq: filters.type } });
    }

    if (filters.customStatus === DEFAULT_REVIEW_STATUS) {
      criteriaArr.push({
        or: [
          { customStatus: { eq: filters.customStatus } },
          { customStatus: { eq: null } },
        ],
      });
    } else if (filters.customStatus) {
      criteriaArr.push({ customStatus: { eq: filters.customStatus } });
    }
    const applications = await queryTestApplicationsByTestCycle({
      testcycleID: cycle?.id || '',
      filter: {
        and: criteriaArr,
      },
    });

    return applications.map((application) => ({
      ...application,
      Decisions: undefined,
      FormAnswers: undefined,
      Notes: undefined,
    }));
  }, [cycle?.id, filters, trigger]);

  const { value: applications } = useAsync({
    asyncFunction: fetchApplications,
  });

  let applicationsCompleted: TestApplication[] = [];
  let applicationsPending: TestApplication[] = [];
  let applicationsFiltered: TestApplication[] = [];

  if (applications) {
    applicationsCompleted = applications.filter(
      (application) =>
        application.submissionStatus === SubmissionStatus.COMPLETED ||
        application.reviewStatus === ReviewStatus.RETURNED
    );

    applicationsPending = applications.filter(
      (application) =>
        application.submissionStatus === SubmissionStatus.INCOMPLETE &&
        application.reviewStatus !== ReviewStatus.RETURNED
    );

    applicationsFiltered = applications.filter(
      (application) => application.filtered
    );
  }

  const handleUpdateApplicationStatus = async (
    applicationId: string,
    newStatusValue: string
  ) => {
    try {
      const original = await queryTestApplication(applicationId);
      if (!original) return;
      await updateTestApplication({
        id: original.id,
        customStatus: newStatusValue,
      });
      setTrigger((previousTrigger) => previousTrigger + 1);
    } catch (error) {
      console.log('Error while updating the status');
    }
  };

  const handleUpdateApplicationReviewStatus = async (
    applicationId: string,
    newReviewStatus: ReviewStatus
  ) => {
    try {
      const original = await queryTestApplication(applicationId);

      if (!original) return;

      await updateTestApplication({
        id: original.id,
        reviewStatus: newReviewStatus,
        ...(newReviewStatus === ReviewStatus.RETURNED && original.ownerID
          ? {
              submissionStatus: SubmissionStatus.INCOMPLETE,
              version: (original.version || 0) + 1,
            }
          : {}),
      });

      if (newReviewStatus === ReviewStatus.RETURNED) {
        const formAnswerToDelete = await queryFormAnswersByTestApplication({
          testapplicationID: original.id,
          filter: {
            and: [{ isCopy: { eq: true } }],
          },
        });
        await Promise.allSettled(
          formAnswerToDelete.map((formAnswer) =>
            deleteFormAnswer(formAnswer.id)
          )
        );
      }

      setTrigger((previousTrigger) => previousTrigger + 1);
    } catch (error) {
      console.log('Error while updating the review status', error);
    }
  };

  const handleStatusOnClick = () => setStatusModalOpen(true);
  const handleOnCloseStatusModal = () => setStatusModalOpen(false);

  const handleAddNewApplicationOnClick = () => setNewApplicationOpen(true);
  const handleOnCloseNewApplicationModal = () => setNewApplicationOpen(false);

  const handleSettingsOnClick = () => setSettingsModal(true);
  const handleCloseSettings = () => setSettingsModal(false);

  const breadCrumbsItems = [
    { label: t('pages.habitat.affiliate.forms.name'), to: '../../forms' },
    { label: t('pages.habitat.affiliate.cycles.name'), to: '..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.name') },
  ];

  const handleDownloadIncompleteCSV = async () => {
    try {
      setDownloadingCSV(true);

      const namesAndEmail: {
        name: string;
        email: string;
        phone: string;
        status: string;
      }[] = [];

      for (const application of applicationsPending) {
        const user = await queryAllUsers({
          filter: { owner: { eq: application.ownerID || '' } },
        });

        if (user[0]) {
          const response = await get({
            apiName: 'userAPI',
            path: `/`,
            options: {
              queryParams: {
                sub: user[0].owner,
              },
            },
          }).response;

          try {
            const attributes = await response.body.json();

            if (attributes) {
              const getStatus = (application: TestApplication) => {
                if (application.reviewStatus === ReviewStatus.RETURNED) {
                  return 'Returned';
                }
                if (application.filtered) {
                  return 'Filtered';
                }
                return 'Incomplete';
              };

              namesAndEmail.push({
                name: `${user[0].firstName} ${user[0].lastName}`,
                email:
                  (
                    attributes as {
                      attributes: { Name: string; Value: string }[];
                    }
                  ).attributes.find((attribute) => attribute.Name === 'email')
                    ?.Value || '',
                phone:
                  (
                    attributes as {
                      attributes: { Name: string; Value: string }[];
                    }
                  ).attributes.find(
                    (attribute) => attribute.Name === 'phone_number'
                  )?.Value || '',
                status: getStatus(application),
              });
            }
          } catch (error) {
            console.log('User attributes not found.');
          }
        }
      }

      const csvFile = generateCSVFromObjectArray(namesAndEmail);

      if (csvFile) {
        const csvUrl = URL.createObjectURL(csvFile);

        downloadWithUrl(
          csvUrl,
          `${
            habitat?.longName
          }-Incomplete Applications-${new Date().toUTCString()}.csv`
        );
      }
    } catch (error) {
      console.log('Error while downloading the CSV file.', error);
    } finally {
      setDownloadingCSV(false);
    }
  };

  const tableHeaders = [
    {
      id: 'name',
      value: t('pages.habitat.affiliate.cycles.cycle.table.name'),
      width: '100%',
    },
    {
      id: 'type',
      value: t('pages.habitat.affiliate.cycles.cycle.table.type'),
    },
    {
      id: 'dateSubmitted',
      value: t('pages.habitat.affiliate.cycles.cycle.table.dateSubmitted'),
    },
    {
      id: 'reviewStatus',
      value: t('pages.habitat.affiliate.cycles.cycle.table.reviewStatus'),
    },
    {
      id: 'customStatus',
      value: (
        <span
          className={`${style.reviewStatus}`}
          onClick={handleStatusOnClick}
          aria-hidden="true"
        >
          {t('pages.habitat.affiliate.cycles.cycle.table.customStatus')}
        </span>
      ),
    },
    {
      id: 'view',
      value: t('pages.habitat.affiliate.cycles.cycle.table.view'),
    },
  ];

  const loadingCells = [
    {
      id: 'name',
      value: <Skeleton variation="text" numOfCharacters={20} />,
    },
    {
      id: 'type',
      value: <Skeleton variation="text" numOfCharacters={6} />,
    },
    {
      id: 'dateSubmitted',
      value: <Skeleton variation="text" numOfCharacters={10} />,
    },
    {
      id: 'reviewStatus',
      value: <Skeleton className={style.chipSkeleton} />,
    },
    {
      id: 'customStatus',
      value: <Skeleton variation="text" numOfCharacters={10} />,
    },
    {
      id: 'view',
      value: <Skeleton className={style.buttonSkeleton} />,
    },
  ];

  useEffect(() => {
    const waitForCycleData = async () => {
      const { cycle: resolvedCycle } = await cycleData;
      setCycle(resolvedCycle);
    };
    waitForCycleData();
  }, [cycleData]);

  return (
    <div className={style.container}>
      <div className={style.firstRow}>
        <div>
          {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
          <div className={`${style.titleContainer}`}>
            <GoBack />
            <span className={`${titleStyle} ${style.title}`}>
              {t('pages.habitat.affiliate.cycles.cycle.title')}
            </span>
          </div>
        </div>

        <div className={style.nonCompleteApplicationsContainer}>
          <p className={`theme-body-medium ${style.incompleteApplications}`}>
            <Suspense
              fallback={<Skeleton variation="text" numOfCharacters={20} />}
            >
              <Await resolve={Promise.allSettled([cycleData, rootFormData])}>
                {`${t(
                  'pages.habitat.affiliate.cycles.cycle.incompleteApplications'
                )} ${applicationsPending.length}`}
              </Await>
            </Suspense>
          </p>
          <p className={`theme-body-medium ${style.filteredApplications}`}>
            <Suspense
              fallback={<Skeleton variation="text" numOfCharacters={16} />}
            >
              <Await resolve={Promise.allSettled([cycleData, rootFormData])}>
                {`${t(
                  'pages.habitat.affiliate.cycles.cycle.filteredApplications'
                )} ${applicationsFiltered.length}`}
              </Await>
            </Suspense>
          </p>
        </div>
      </div>

      <div className={`${style.tableOptions}`}>
        <div className={`${style.resultsContainer}`}>
          <span className="theme-subtitle-s2">
            {t('pages.habitat.affiliate.cycles.cycle.table.title')}
          </span>
          <Suspense
            fallback={
              <ResultsCounter number={applicationsCompleted.length} skeleton />
            }
          >
            <Await resolve={Promise.allSettled([cycleData, rootFormData])}>
              <ResultsCounter number={applicationsCompleted.length} />
            </Await>
          </Suspense>
        </div>
        <div className={`${style.options}`}>
          <Suspense>
            <Await resolve={Promise.allSettled([cycleData, rootFormData])}>
              {([
                {
                  value: { cycle: resolvedCycle },
                },
                {
                  value: { rootForm },
                },
              ]: [{ value: TCycleData }, { value: TRootFormData }]) => (
                <>
                  <div className={`${style.suboptions}`}>
                    <ApplicationLinkModal
                      open={linkModal}
                      onClickClose={() => setLinkModal(false)}
                      onClickCopyLink={() => {
                        handleCopyToClipboard({
                          cycleId: resolvedCycle.id,
                          pathname,
                        });
                      }}
                      cycleName={cycle?.name || 'cycle'}
                      rootFormName={rootForm.name || 'rootForm'}
                    />
                    <SettingsModal
                      cycle={cycle}
                      open={settingsModal}
                      onClose={handleCloseSettings}
                    />
                    <Menu
                      trigger={
                        <MenuButton
                          variation="primary"
                          disabled={downloadingCSV}
                        >
                          {downloadingCSV ? (
                            <Loader />
                          ) : (
                            <Flex alignItems="center">
                              {t(
                                'pages.habitat.affiliate.cycles.cycle.actions'
                              )}
                              <MdOutlineBuild size="20px" />
                            </Flex>
                          )}
                        </MenuButton>
                      }
                      menuAlign="end"
                      marginBlockStart="8px"
                    >
                      <MenuItem
                        justifyContent="space-between"
                        onClick={handleAddNewApplicationOnClick}
                        gap="8px"
                      >
                        {t(
                          'pages.habitat.affiliate.cycles.cycle.newApplication'
                        )}
                        <MdOutlineAdd />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={() => setFilterModal(true)}
                        gap="8px"
                      >
                        {t('pages.habitat.affiliate.cycles.cycle.filter')}
                        <MdOutlineFilterList />
                      </MenuItem>
                      {applicationsPending.length > 0 && (
                        <MenuItem
                          justifyContent="space-between"
                          onClick={handleDownloadIncompleteCSV}
                          gap="8px"
                        >
                          {t('pages.habitat.affiliate.cycles.cycle.report')}
                          <MdOutlineSaveAlt />
                        </MenuItem>
                      )}
                      <MenuItem
                        justifyContent="space-between"
                        onClick={() => {
                          setLinkModal(true);
                        }}
                        gap="8px"
                      >
                        {t(
                          'pages.habitat.affiliate.cycles.cycle.applicantLink'
                        )}
                        <MdOutlineLink size="24px" />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={() => navigate('test-application')}
                        gap="8px"
                      >
                        {t(
                          'pages.habitat.affiliate.cycles.cycle.testApplication'
                        )}
                        <MdOutlineScience />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={handleSettingsOnClick}
                        gap="8px"
                      >
                        {t('pages.habitat.affiliate.cycles.cycle.settings')}
                        <MdOutlineSettings />
                      </MenuItem>
                    </Menu>
                  </div>
                  {filterModal && (
                    <Filters
                      filters={filters}
                      setFilters={(data) => setFilters(data)}
                      close={() => setFilterModal(false)}
                      customStatuses={habitat?.props?.customStatus || []}
                    />
                  )}
                  <NewApplicationModal
                    open={newApplicationOpen}
                    onClose={handleOnCloseNewApplicationModal}
                    cycle={cycle}
                    setTrigger={setTrigger}
                  />
                </>
              )}
            </Await>
          </Suspense>
        </div>
      </div>
      <StatusModal
        open={statusModalOpen}
        onClose={handleOnCloseStatusModal}
        setTrigger={setTrigger}
      />
      <Suspense
        fallback={
          <TableWithPaginator
            headers={tableHeaders}
            data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
              id: number,
              cells: loadingCells,
            }))}
          />
        }
      >
        <Await resolve={Promise.allSettled([cycleData, rootFormData])}>
          <TableWithPaginator
            headers={tableHeaders}
            data={applicationsCompleted.map((application, index) => {
              const applicationProps = JSON.parse(
                application.props || '{}'
              ) as {
                name?: string;
              };
              return {
                id: index,
                cells: [
                  {
                    id: 'name',
                    value:
                      application.type === ApplicationTypes.ONLINE ? (
                        <Username application={application} />
                      ) : (
                        applicationProps?.name || ''
                      ),
                  },
                  {
                    id: 'type',
                    value: stringToHumanReadable(application.type),
                  },
                  {
                    id: 'dateSubmitted',
                    value: convertDateYYYYMMDDtoDDMMYYYY(
                      application.submittedDate
                    ),
                  },
                  {
                    id: 'reviewStatus',
                    value: application.reviewStatus && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Menu
                          trigger={
                            <div>
                              <StatusChip status={application.reviewStatus} />
                            </div>
                          }
                          size="small"
                          menuAlign="center"
                        >
                          <MenuItem
                            onClick={() =>
                              handleUpdateApplicationReviewStatus(
                                application.id,
                                ReviewStatus.PENDING
                              )
                            }
                          >
                            {capitalize(ReviewStatus.PENDING)}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleUpdateApplicationReviewStatus(
                                application.id,
                                ReviewStatus.ACCEPTED
                              )
                            }
                          >
                            {capitalize(ReviewStatus.ACCEPTED)}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleUpdateApplicationReviewStatus(
                                application.id,
                                ReviewStatus.RETURNED
                              )
                            }
                          >
                            {capitalize(ReviewStatus.RETURNED)}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleUpdateApplicationReviewStatus(
                                application.id,
                                ReviewStatus.DENIED
                              )
                            }
                          >
                            {capitalize(ReviewStatus.DENIED)}
                          </MenuItem>
                        </Menu>
                      </div>
                    ),
                  },
                  {
                    id: 'customStatus',
                    value: (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <DropdownMenu
                          className={`${style.customStatusSelect}`}
                          variation="small"
                          value={application.customStatus || ''}
                          onChange={(event) =>
                            handleUpdateApplicationStatus(
                              application.id,
                              event.currentTarget.value
                            )
                          }
                        >
                          {[
                            DEFAULT_REVIEW_STATUS,
                            ...(habitat
                              ? habitat.props.customStatus || []
                              : []),
                          ].map((selectedStatus) => (
                            <option key={selectedStatus} value={selectedStatus}>
                              {selectedStatus}
                            </option>
                          ))}
                        </DropdownMenu>
                      </div>
                    ),
                  },
                  {
                    id: 'view',
                    value: (
                      <div className={style.openButtonContainer}>
                        <Link to={application.id}>
                          <CustomButton
                            className={style.openButton}
                            variation="text-only"
                          >
                            <MdOutlineOpenInNew
                              size="24px"
                              color="var(--amplify-colors-neutral-90)"
                            />
                          </CustomButton>
                        </Link>
                      </div>
                    ),
                  },
                ],
              };
            })}
          />
        </Await>
      </Suspense>
    </div>
  );
};

export default AffiliateCycleApplications;
