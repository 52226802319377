import React, { Suspense, useState } from 'react';
import { useAuthenticator, useBreakpointValue } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { TChatsData } from 'router/loaders/chats';
import TableWithPaginator from 'components/TableWithPaginator';
import Skeleton from 'components/Skeleton';
import IconButton from 'components/IconButton';
import {
  MdOutlineMarkChatRead,
  MdOutlineMarkChatUnread,
  MdOutlineOpenInNew,
} from 'react-icons/md';
import ResultsCounter from 'components/ResultsCounter';
import SearchField from 'components/SearchField';
import styles from './AffiliateChatsPage.module.css';

const AffiliateChatsPage = () => {
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const { user } = useAuthenticator((context) => [context.user]);

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { chatsData } = useLoaderData() as {
    chatsData: Promise<TChatsData>;
  };

  const tableHeaders = [
    {
      id: 'name',
      value: t('pages.habitat.affiliate.chats.table.name'),
      width: '75%',
    },
    {
      id: 'status',
      value: t('pages.habitat.affiliate.chats.table.status'),
      width: '15%',
    },
    {
      id: 'view',
      value: t('pages.habitat.affiliate.chats.table.view'),
    },
  ];

  const loadingCell = [
    { value: <Skeleton variation="text" numOfCharacters={20} />, id: 'name' },
    {
      value: <Skeleton variation="text" numOfCharacters={2} />,
      id: 'status',
    },
    {
      id: 'view',
      value: <Skeleton className={styles.buttonSkeleton} />,
    },
  ];

  return (
    <div className={`${styles.page}`}>
      <h3 className={`${titleStyle} ${styles.title}`}>
        {t('pages.habitat.affiliate.chats.title')}
      </h3>
      <div className={`${styles.resultsAndSearchContainer}`}>
        <div className={`${styles.resultsContainer}`}>
          <div className="theme-subtitle-s2">
            <span>{t('pages.habitat.affiliate.chats.chats')}</span>
          </div>
          <Suspense fallback={<ResultsCounter number={2} skeleton />}>
            <Await resolve={chatsData}>
              {({ chats }: TChatsData) => (
                <ResultsCounter number={chats.length} />
              )}
            </Await>
          </Suspense>
        </div>
        <SearchField
          label
          labelHidden
          placeholder={t('pages.habitat.affiliate.chats.searchPlaceholder')}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          onClickClear={() => setSearch('')}
        />
      </div>
      <div>
        <Suspense
          fallback={
            <TableWithPaginator
              headers={tableHeaders}
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
                id: number,
                cells: loadingCell,
              }))}
            />
          }
        >
          <Await resolve={chatsData}>
            {({ chats, users, lastMessages, lastReads }: TChatsData) => (
              <TableWithPaginator
                headers={tableHeaders}
                data={chats
                  .sort((a, b) => {
                    if (a.updatedAt > b.updatedAt) {
                      return -1;
                    }

                    if (a.updatedAt < b.updatedAt) {
                      return 1;
                    }

                    return 0;
                  })
                  .filter((chat) => {
                    if (search) {
                      const filteredUsers = users.filter(
                        (user) =>
                          `${user.firstName} ${user.lastName}`
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          user.firstName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          user.lastName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                      );

                      return filteredUsers.some(
                        (user) => user.owner === chat.owner
                      );
                    }

                    return true;
                  })
                  .map((chat) => {
                    const ownerUser = users.find(
                      (user) => user.owner === chat.owner
                    );

                    const lastMessage = lastMessages.find(
                      (message) => message.id === chat.lastMessage
                    );

                    const chatLastReads = lastReads.find(
                      (lastRead) => lastRead.chatId === chat.id
                    );

                    const isReadByAnAffiliate = chatLastReads?.lastReads.some(
                      (lastRead) =>
                        (lastRead.owner !== chat.owner &&
                          lastRead.lastMessage === chat.lastMessage) ||
                        lastMessage?.owner === user.username
                    );

                    return {
                      id: chat.id,
                      cells: [
                        {
                          value: `${ownerUser?.firstName} ${ownerUser?.lastName}`,
                          id: 'name',
                        },
                        {
                          value: isReadByAnAffiliate ? (
                            <MdOutlineMarkChatRead
                              className={styles.read}
                              title={t(
                                'pages.habitat.affiliate.chats.table.read'
                              )}
                            />
                          ) : (
                            <MdOutlineMarkChatUnread
                              className={styles.unread}
                              title={t(
                                'pages.habitat.affiliate.chats.table.unread'
                              )}
                            />
                          ),
                          id: 'status',
                        },
                        {
                          id: 'view',
                          value: (
                            <div className={styles.openButtonContainer}>
                              <Link to={`${chat.id}`}>
                                <IconButton
                                  className={styles.openButton}
                                  variation="not-outlined"
                                >
                                  <MdOutlineOpenInNew
                                    size="24px"
                                    color="var(--amplify-colors-neutral-90)"
                                  />
                                </IconButton>
                              </Link>
                            </div>
                          ),
                        },
                      ],
                    };
                  })}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AffiliateChatsPage;
