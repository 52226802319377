import { LastRead, Message, User } from 'API';
import { defer, LoaderFunction } from 'react-router-dom';
import { queryChatsByHabitat } from 'services/graphql/Chat';
import { queryAllHabitats } from 'services/graphql/Habitat';
import { queryLastReadsByChat } from 'services/graphql/LastRead';
import { queryMessage } from 'services/graphql/Message';
import { queryAllUsers } from 'services/graphql/User';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getChatsData = async (habitat: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const habitats = await queryAllHabitats({
    filter: { urlName: { eq: habitat } },
  });

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const chats = await queryChatsByHabitat({
    habitatID: habitats[0].id,
    filter: {
      lastMessage: { attributeExists: true },
    },
  });

  const lastMessagesIds = chats.map((chat) => chat.lastMessage);

  const lastMessagesResponseArray = await Promise.allSettled(
    lastMessagesIds.map((messageId) => queryMessage(messageId as string))
  );

  const lastMessages = lastMessagesResponseArray.reduce(
    (messagesArray, currentResponse) =>
      currentResponse.status === 'fulfilled' && currentResponse.value
        ? [...messagesArray, currentResponse.value]
        : messagesArray,
    [] as Message[]
  );

  const ownerIds = [
    ...new Set([
      ...chats.map((chat) => chat.owner),
      ...lastMessages.map((message) => message.owner),
    ]),
  ];

  const usersResponseArray = await Promise.allSettled(
    ownerIds.map((ownerId) =>
      queryAllUsers({ filter: { owner: { eq: ownerId } } })
    )
  );

  const users = usersResponseArray.reduce(
    (usersArray, currentResponse) =>
      currentResponse.status === 'fulfilled'
        ? usersArray.concat(currentResponse.value)
        : usersArray,
    [] as User[]
  );

  const lastReads: { chatId: string; lastReads: LastRead[] }[] = [];

  await Promise.allSettled(
    chats.map((chat) =>
      (async () => {
        const chatLastReads = await queryLastReadsByChat(chat.id);
        lastReads.push({ chatId: chat.id, lastReads: chatLastReads });
      })()
    )
  );

  return {
    chats,
    users,
    lastMessages,
    lastReads,
  };
};

export type TChatsData = Awaited<Promise<ReturnType<typeof getChatsData>>>;

const chatsLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  return defer({ chatsData: getChatsData(habitat) });
};

export default chatsLoader;
